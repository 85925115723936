import { ref, Ref, watch } from 'vue';
import api from '@services/api';
import dynamicTableModel from '@/models/component/table/dynamicTable';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

interface Props {
  columns: Array<BaseTable.TableColumn>;
  data?: Array<Record<string, any>>;
  hover?: boolean;
  checkbox?: boolean;
  responsive?: boolean;
  stickyHeader?: boolean;
  sortableRow?: boolean;
  sortableCol?: boolean;
  currentPage?: number;
  resizeCol?: boolean;
  perPages?: number;
  rowSelectVariant?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark';
  busy?: boolean;
  selectAll?: boolean;
  tableId?: number;
}

export default function useResizeColumn(props: Props) {
  const { openDefaultErrorModal } = useValidationModal();
  const { updateDisplayColumnModel } = dynamicTableModel();

  const currentColumnId: Ref<number> = ref(0);
  const currentColumnWidth: Ref<number> = ref(0);
  const currentKeyColumn: Ref<string> = ref('');
  const isUpdate: Ref<boolean> = ref(false);

  const resizeTable = () => {
    const tables = document.getElementsByTagName('table');
    for (let i = 0; i < tables.length; i++) {
      resizableGrid(tables[i]);
    }
  };

  const resizableGrid = (table: any) => {
    const row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
    if (!cols) return;
    for (let i = 1; i < cols.length; i++) {
      const div = createDiv();
      cols[i].appendChild(div);
      cols[i].children[0].style.position = 'relative';
      setListeners(div);
    }
  };

  const createDiv = () => {
    const div = document.createElement('div');
    div.classList.add('resize-handle');
    div.setAttribute('tooltip', 'Resize Column');
    div.setAttribute('flow', 'right');
    return div;
  };

  const updateResizeColumn = () => {
    if (currentColumnId.value != 0) {
      const disableColumnList: DynamicTable.UpdateDisplay[] = [];

      disableColumnList.push({
        ColumnId: currentColumnId.value,
        Key_Column: currentKeyColumn.value,
        ColumnWidth: currentColumnWidth.value,
        Column_Dimensions_Unit: 'px',
      });

      // updateDisplayColumnModel.payload.TableId = props.tableId;
      // updateDisplayColumnModel.payload.displayTables = disableColumnList;

      // api.apiRequest(updateDisplayColumnModel).catch((err) => {
      //   openDefaultErrorModal(err);
      // });
    }
  };

  const setListeners = (div: any) => {
    let pageX: any = null!;
    let currentCol: any = null!;
    let nextCol: any = null!;
    let currentColWidth: any = null!;
    let nextColWidth: any = null!;
    div.addEventListener('mousedown', function (e: any) {
      isUpdate.value = false;
      currentCol = e.target.parentElement;
      currentColumnId.value = currentCol.id;
      currentKeyColumn.value = currentCol.classList[1];
      nextCol = currentCol.nextElementSibling;
      pageX = e.pageX;
      currentColWidth = currentCol.offsetWidth;
      if (nextCol) nextColWidth = nextCol.offsetWidth;
    });

    document.addEventListener('mousemove', function (e: any) {
      if (currentCol) {
        const diffX = e.pageX - pageX;

        if (nextCol) {
          if (diffX > 0) {
            nextCol.style.width = nextColWidth - diffX + 'px';
          }
        }
        currentCol.style.width = currentColWidth + diffX + 'px';
        currentColumnWidth.value = currentColWidth + diffX;
      }
    });

    document.addEventListener('mouseup', function (e: any) {
      currentCol = undefined;
      nextCol = undefined;
      pageX = undefined;
      nextColWidth = undefined;
      currentColWidth = undefined;
      isUpdate.value = true;
    });
  };

  watch(
    () => props.busy,
    () => {
      if (props.columns.length > 0 && props.busy == false) {
        setTimeout(() => {
          if (props.resizeCol) {
            resizeTable();
          }
        }, 10);
      }
    },
  );

  watch(
    () => isUpdate.value,
    () => {
      if (isUpdate.value == true) {
        setTimeout(() => {
          updateResizeColumn();
        }, 10);
      }
    },
  );

  return {};
}
