import { CustomRouteRecord } from '@/types/router/router';

import SegmentListV2 from '@views/modules/segment-v2/segment-list/SegmentListV2.vue';
import SegmentDetailV2 from '@views/modules/segment-v2/segment-detail/SegmentDetailV2.vue';
import FolderInside2 from '@/views/modules/segment-v2/segment-list/components/FolderInside.vue';

import SegmentPreviewV2 from '@views/modules/segment-v2/segment-result/SegmentPreviewV2.vue';
import SegmentResultV2 from '@views/modules/segment-v2/segment-result/SegmentResultV2.vue';

const dataConnectRoutes: Array<CustomRouteRecord> = [
  {
    path: '/segmentv2/detail/:segmentRef',
    name: 'segmentDetailV2',
    component: SegmentDetailV2,
    breadcrumb: [
      {
        text: 'segment',
        to: '/segmentv2',
      },
      {
        text: 'detail',
        active: true,
      },
    ],
    meta: {
      requiresConfirmation: true,
      bypassConfirmations: ['segmentPreviewV2'],
    },
  },
  {
    path: '/segmentv2',
    name: 'segmentV2',
    component: SegmentListV2,
    breadcrumb: [
      {
        text: 'segment',
        to: '/segmentv2',
      },
    ],
  },
  {
    path: '/folder-insidev2',
    name: 'folderInsidev2',
    component: FolderInside2,
    breadcrumb: [
      {
        text: 'segment',
        to: '/segmentv2',
      },
    ],
  },
  {
    path: '/segmentv2/preview/:segmentId',
    name: 'segmentPreviewV2',
    component: SegmentPreviewV2,
    breadcrumb: [
      {
        text: 'segment.breadcrumb',
        to: '/segmentv2',
      },
    ],
    meta: {
      requiresConfirmation: true,
      bypassConfirmations: ['segmentDetailV2', 'segmentResultV2'],
    },
  },
  {
    path: '/segmentv2/result/:segmentId',
    name: 'segmentResultV2',
    component: SegmentResultV2,
    breadcrumb: [
      {
        text: 'segment.breadcrumb',
        to: '/segmentv2',
      },
    ],
  },
];

export default dataConnectRoutes;
