<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.397 19.3711L9.98144 20.6856C10.1552 21.0768 10.4387 21.4093 10.7977 21.6426C11.1566 21.8759 11.5755 22.0001 12.0037 22C12.4318 22.0001 12.8507 21.8759 13.2097 21.6426C13.5686 21.4093 13.8521 21.0768 14.0259 20.6856L14.6103 19.3711C14.8184 18.9047 15.1683 18.5159 15.6103 18.26C16.0551 18.0034 16.5697 17.8941 17.0803 17.9478L18.5103 18.1C18.936 18.145 19.3656 18.0656 19.747 17.8713C20.1284 17.6771 20.4453 17.3763 20.6592 17.0056C20.8734 16.635 20.9754 16.2103 20.9529 15.7829C20.9305 15.3555 20.7844 14.9438 20.5326 14.5978L19.6859 13.4344C19.3844 13.0171 19.2233 12.5148 19.2259 12C19.2258 11.4866 19.3884 10.9864 19.6903 10.5711L20.537 9.40778C20.7889 9.06175 20.9349 8.65007 20.9574 8.22267C20.9799 7.79528 20.8778 7.37054 20.6637 7C20.4498 6.62923 20.1329 6.32849 19.7515 6.13423C19.37 5.93997 18.9404 5.86053 18.5148 5.90556L17.0848 6.05778C16.5741 6.11141 16.0596 6.00212 15.6148 5.74556C15.1719 5.48825 14.8218 5.09736 14.6148 4.62889L14.0259 3.31444C13.8521 2.92317 13.5686 2.59072 13.2097 2.3574C12.8507 2.12408 12.4318 1.99993 12.0037 2C11.5755 1.99993 11.1566 2.12408 10.7977 2.3574C10.4387 2.59072 10.1552 2.92317 9.98144 3.31444L9.397 4.62889C9.18992 5.09736 8.83987 5.48825 8.397 5.74556C7.95221 6.00212 7.43766 6.11141 6.927 6.05778L5.49255 5.90556C5.06688 5.86053 4.63729 5.93997 4.25587 6.13423C3.87444 6.32849 3.55757 6.62923 3.34366 7C3.12948 7.37054 3.02744 7.79528 3.04993 8.22267C3.07241 8.65007 3.21845 9.06175 3.47033 9.40778L4.31699 10.5711C4.61894 10.9864 4.78153 11.4866 4.78144 12C4.78153 12.5134 4.61894 13.0137 4.31699 13.4289L3.47033 14.5922C3.21845 14.9382 3.07241 15.3499 3.04993 15.7773C3.02744 16.2047 3.12948 16.6295 3.34366 17C3.55778 17.3706 3.87469 17.6712 4.25606 17.8654C4.63743 18.0596 5.06691 18.1392 5.49255 18.0944L6.92255 17.9422C7.43322 17.8886 7.94777 17.9979 8.39255 18.2544C8.83708 18.511 9.18876 18.902 9.397 19.3711Z"
      stroke="#2D2D2D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0019 15C13.6587 15 15.0019 13.6569 15.0019 12C15.0019 10.3431 13.6587 9 12.0019 9C10.345 9 9.00187 10.3431 9.00187 12C9.00187 13.6569 10.345 15 12.0019 15Z"
      stroke="#2D2D2D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
