// import { ChurnRate } from '@/types/modules/analytics/churn-rate-chart';

export const CHURNRATE_STAGE: { id: number; label: string }[] = [
  { id: 1, label: 'Normal' },
  { id: 2, label: 'Risk Churn' },
  { id: 3, label: 'Churned' },
];

export const CHURNRATE_STAGE_ALL: { id: number; label: string }[] = [
  { id: 0, label: 'All Stage' },
  { id: 1, label: 'Normal' },
  { id: 2, label: 'Risk Churn' },
  { id: 3, label: 'Churned' },
];

// export const CHURNRATE_STAGE_STATUS: ChurnRate.Chart.Stage = {
//   All: 0,
//   Normal: 1,
//   RiskChurn: 2,
//   Churn: 3,
// };
