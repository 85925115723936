import { ref, watch, reactive, Ref, onMounted } from 'vue';
//Lib
import ability from '@/libs/acl/ability';

import router from '@/router';
import api from '@services/api';
import { useI18n } from 'vue-i18n';

//component
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

//Api model
import dataRelationModel from '@models/dataConnect/dataRelation';

//constants
import { Status } from '@/constants/modules/data-connector/dataRelation';
//Icon
import RelationOverallIcon from '@assets/icons/modules/data-connect/data-relation/relationOverall.vue';
import Edit from '@/assets/icons/modules/mka/Edit.vue';
import Delete from '@/assets/icons/modules/mka/Delete.vue';

export default function useDataRalation() {
  const { t } = useI18n();
  const { openErrorModal, openWarningModal } = useValidationModal();
  const { deleteRelationModel, fetchRelationListModel } = dataRelationModel();

  const fields: Ref<BaseTable.TableColumn[]> = ref([
    { key: 'left_source_name', label: t('dataRelation.left_source_name') },
    { key: 'left_table_name', label: t('dataRelation.left_table_name') },
    { key: 'left_column_concat', label: t('dataRelation.left_column_concat') },
    { key: 'right_source_name', label: t('dataRelation.right_source_name') },
    { key: 'right_table_name', label: t('dataRelation.right_table_name') },
    { key: 'right_column_concat', label: t('dataRelation.right_column_concat') },
    { key: 'status_id', label: t('dataRelation.status') },
    { key: 'action', label: '' },
  ]);
  const dataRelation: Ref<DataRelation.RecordsResponse[]> = ref([]);
  const relationId: Ref<number | null> = ref(null);
  const isOpenModalDiagram: Ref<boolean> = ref(false);
  const isOpenModalCreate: Ref<boolean> = ref(false);
  const isOpenModalEdit: Ref<boolean> = ref(false);
  const textSearch: Ref<string> = ref('');
  const isLoadingTableList: Ref<boolean> = ref(false);
  const isLoadingDelete: Ref<boolean> = ref(false);
  const paginationKey: Ref<number> = ref(0);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const actionList: ActionList.Action[] = [
    {
      key: 'diagram',
      tooltip: t('dataRelation.diagram_relation'),
      icon: RelationOverallIcon,
      tooltipColor: '#007FFF',
      tooltipBackgroundColor: '#BBDBFF',
    },
    { key: 'edit', tooltip: t('mka.edit'), icon: Edit, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' },
    { key: 'delete', tooltip: t('mka.delete'), icon: Delete, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' },
  ];

  const fetchTableRelationList = async () => {
    isLoadingTableList.value = true;
    fetchRelationListModel.payload.Search = textSearch.value;
    fetchRelationListModel.payload.Filters = [];
    fetchRelationListModel.payload.Limit = pagination.perPage;
    fetchRelationListModel.payload.Ordering = [
      {
        Seq: 1,
        Key: 'DR_Id',
        Direction: 'ASC',
      },
    ];
    fetchRelationListModel.payload.Page = pagination.currentPage;

    await api
      .apiRequest(fetchRelationListModel)
      .then((response) => {
        const res: Ref<DataRelation.RelationListResponse> = ref(response.data.result);

        // Add isMainTable property to each object in dataRelation.value
        dataRelation.value = res.value.records.map((record) => ({
          ...record,
          isMainTable: record.left_source_id === 0 || record.right_source_id === 0,
        }));

        pagination.currentPage = res.value.current_page;
        pagination.totalRows = res.value.filtered_record;
        paginationKey.value++;
      })
      .catch((err) => {
        if (err.status == 404) {
          openErrorModal('404 Not Found', '');
        } else {
          openErrorModal(err.data.error.locale['th-th'].title as string, err.data.error.locale['th-th'].message as string);
        }
      })
      .finally(() => {
        isLoadingTableList.value = false;
      });
  };

  const deleteRelation = (relation_id: number) => {
    isLoadingDelete.value = true;
    deleteRelationModel.payload.relation_id = relation_id;
    api
      .apiRequest(deleteRelationModel)
      .then(() => {
        fetchTableRelationList();
      })
      .catch((err) => {
        if ((err.status = 404)) {
          openErrorModal('404 Not Found', '');
        } else {
          openErrorModal(err.data.error.locale['th-th'].title as string, err.data.error.locale['th-th'].message as string);
        }
      })
      .finally(() => {
        isLoadingDelete.value = false;
      });
  };

  const timeoutSearch: Ref<NodeJS.Timeout | null> = ref(null!);
  const onChangeSearch = (value: any) => {
    textSearch.value = value;
    if (timeoutSearch.value != null) {
      clearTimeout(timeoutSearch.value);
      timeoutSearch.value = null;
    }
    timeoutSearch.value = setTimeout(() => {
      fetchTableRelationList();
    }, 300);
  };

  // const onSort = (sort: { key: string; direction: string }) => {
  //   const column = fields.value.filter((el) => {
  //     return el.key == sort.key;
  //   });
  //   if (column[0].sortable) {
  //     sortingResult.value = [
  //       {
  //         Seq: 1,
  //         Key: sort.key,
  //         Direction: sort.direction,
  //       },
  //     ];
  //     fetchCouponSet();
  //   }
  // };

  const onOverallDiagram = () => {
    router.push({
      path: '/dataConnector/dataRelationship/overallDiagram',
      query: {
        ...router.currentRoute.value.query,
      },
    });
  };
  const onSelectAction = (action: ActionList.Action, dataRow: DataRelation.RecordsResponse, isMainTable: boolean) => {
    switch (action.key) {
      case 'diagram':
        onDiagramRelation(dataRow.relation_id);
        break;
      case 'edit':
        onEditRelation(dataRow.relation_id);
        break;
      case 'delete':
        if (isMainTable) {
          openWarningModal('ไม่สามารถลบความสัมพันธ์ได้', 'เนื่องจากเป็น Table หลัก', () => {});
        } else {
          openWarningModal('ลบความสัมพันธ์', 'ต้องการลบความสัมพันธ์นี้หรือไม่?', () => {
            deleteRelation(dataRow.relation_id);
          });
        }

        break;
      default:
        break;
    }
  };

  function getClassStatus(status_id: number) {
    switch (status_id) {
      case Status.Active:
        return 'active';
      case Status.InActive:
        return 'in-active';
      default:
        return '';
    }
  }
  function getTextStatus(status_id: number) {
    switch (status_id) {
      case Status.Active:
        return t('dataRelation.active');
      case Status.InActive:
        return t('dataRelation.inactive');
      default:
        return `${status_id}`;
    }
  }

  const onDiagramRelation = (relation_id: number) => {
    relationId.value = relation_id;
    isOpenModalDiagram.value = true;
  };

  const onEditRelation = (relation_id: number) => {
    relationId.value = relation_id;
    isOpenModalEdit.value = true;
  };
  const onCreateRelation = () => {
    isOpenModalCreate.value = true;
  };
  const onCloseDiagramRelation = () => {
    isOpenModalDiagram.value = false;
  };

  const onUseDiagramRelation = (relation_id: number) => {
    relationId.value = relation_id;
    isOpenModalDiagram.value = false;
    isOpenModalEdit.value = true;
  };

  const onCloseCreateRelation = () => {
    isOpenModalCreate.value = false;
  };
  const onCloseEditRelation = () => {
    isOpenModalEdit.value = false;
  };
  const onSuccessCreateRelation = async () => {
    isOpenModalCreate.value = false;
    await fetchTableRelationList();
  };
  const onSuccessEditRelation = async () => {
    isOpenModalEdit.value = false;
    await fetchTableRelationList();
  };

  function onSelectPagination(data: any) {
    pagination.perPage = data.valueStatus;
  }
  function handleActionByMainTable(relationMainTable: boolean) {
    const actionList = [];
    actionList.push({
      key: 'diagram',
      tooltip: t('dataRelation.diagram_relation'),
      icon: RelationOverallIcon,
      tooltipColor: '#007FFF',
      tooltipBackgroundColor: '#BBDBFF',
    });
    actionList.push({ key: 'edit', tooltip: t('mka.edit'), icon: Edit, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
    actionList.push({
      key: 'delete',
      tooltip: t('mka.delete'),
      icon: Delete,
      tooltipColor: '#007FFF',
      tooltipBackgroundColor: '#BBDBFF',
      disabled: relationMainTable,
    });
    return actionList;
  }
  watch(
    () => pagination.currentPage,
    () => {
      if (textSearch.value.length > 0) {
        onChangeSearch(textSearch.value);
      } else {
        fetchTableRelationList();
      }
    },
  );

  watch(
    () => textSearch.value,
    () => {
      pagination.totalRows = 0;
      pagination.currentPage = 1;
      onChangeSearch(textSearch.value);
    },
  );

  onMounted(() => {
    fetchTableRelationList();
  });

  return {
    fields,
    dataRelation,
    actionList,
    pagination,
    textSearch,
    isOpenModalDiagram,
    isOpenModalEdit,
    isOpenModalCreate,
    isLoadingTableList,
    isLoadingDelete,
    paginationKey,
    relationId,
    onChangeSearch,
    onSelectAction,
    // onSort,
    onSelectPagination,
    onOverallDiagram,
    onDiagramRelation,
    onEditRelation,
    onCreateRelation,
    onCloseDiagramRelation,
    onUseDiagramRelation,
    onCloseCreateRelation,
    onCloseEditRelation,
    onSuccessCreateRelation,
    onSuccessEditRelation,
    getClassStatus,
    getTextStatus,
    handleActionByMainTable,
  };
}
