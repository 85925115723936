import { CustomRouteRecord } from '@/types/router/router';
import MainChurnRate from '@/views/modules/analytics/pages/anal-churnrate/MainPage.vue';
import InsightsAnal from '@/views/modules/analytics/pages/anal-churnrate/InsightsAnal.vue';
import CalAnalPage from '@/views/modules/analytics/pages/anal-churnrate/CalAnalPage.vue';
import CalInsidePage from '@views/modules/analytics/pages/anal-churnrate/CalInsidePage.vue';

const analyticsRoute: Array<CustomRouteRecord> = [
  {
    path: '/analytics/dashboard',
    name: 'analytics-dashboard',
    component: () => import('@views/modules/analytics/pages/bi-dashboard/BusinessIntelligence.vue'),
    breadcrumb: [
      {
        text: 'bi.dashboard',
      },
    ],
  },
  {
    path: '/analytics/export-preview',
    component: () => import('@views/modules/analytics/pages/bi-export-preview/ExportPreview.vue'),
    meta: {
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'bi.dashboard',
      },
    ],
  },
  {
    path: '/analytics/churnRate/main',
    name: 'churn-rate-main',
    component: MainChurnRate,
    breadcrumb: [
      {
        text: 'analytics.churn_rate',
      },
    ],
  },
  {
    path: '/analytics/churnRate/insight',
    name: 'churn-rate-insight',
    component: InsightsAnal,
    breadcrumb: [
      {
        text: 'analytics.churn_rate',
        to: '/analytics/churnRate/main',
      },
      {
        text: 'เฉพาะกลุ่มเป้าหมาย',
        to: '/analytics/churnRate/main',
      },
      {
        text: 'ข้อมูลเชิงลึก',
        active: true,
      },
    ],
  },
  {
    path: '/analytics/churnRate/calculate',
    name: 'churn-rate-calculate',
    component: CalAnalPage,
    breadcrumb: [
      {
        text: 'analytics.churn_rate',
        to: '/analytics/churnRate/main',
      },
      {
        text: 'คำนวณอัตราการเลิกเป็นลูกค้า',
        active: true,
      },
    ],
  },
  {
    path: '/analytics/churnRate/calculateinside',
    name: 'churn-rate-calculate-inside',
    component: CalInsidePage,
    breadcrumb: [
      {
        text: 'analytics.churn_rate',
        to: '/analytics/churnRate/main',
      },
      {
        text: 'คำนวณอัตราการเลิกเป็นลูกค้า',
        active: true,
      },
    ],
  },
];

export default analyticsRoute;
