export default function () {
  const fetchAnalyticsChartLayoutModel: API.RequestModel<Bi.BiLayout.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Layout/List`,
    method: 'GET',
    payload: {
      Page: 1,
      Limit: 1000,
      PageId: null!,
    },
  };

  const fetchAnalyticsAdjustChartLayoutModel: API.RequestModel<Bi.BiLayout.Adjust.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Layout/Edit`,
    method: 'POST',
    payload: {
      PageId: null!,
      Layout: [],
    },
  };

  const fetchAnalyticsDeleteChartLayoutModel: API.RequestModel<Bi.BiLayout.Delete.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Layout/Remove`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
    },
  };

  return {
    fetchAnalyticsChartLayoutModel,
    fetchAnalyticsAdjustChartLayoutModel,
    fetchAnalyticsDeleteChartLayoutModel,
  };
}
