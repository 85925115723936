<template>
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.2 11.4C0.88174 11.4 0.576516 11.2736 0.351472 11.0485C0.126428 10.8235 0 10.5183 0 10.2V6.2C0 5.88174 0.126428 5.57651 0.351472 5.35147C0.576516 5.12643 0.88174 5 1.2 5C1.51826 5 1.82348 5.12643 2.04853 5.35147C2.27357 5.57651 2.4 5.88174 2.4 6.2V10.2C2.4 10.5183 2.27357 10.8235 2.04853 11.0485C1.82348 11.2736 1.51826 11.4 1.2 11.4Z"
    />
    <path
      d="M6.2 11.4C5.88174 11.4 5.57652 11.2736 5.35147 11.0485C5.12643 10.8235 5 10.5183 5 10.2V3.2C5 2.88174 5.12643 2.57651 5.35147 2.35147C5.57652 2.12643 5.88174 2 6.2 2C6.51826 2 6.82348 2.12643 7.04853 2.35147C7.27357 2.57651 7.4 2.88174 7.4 3.2V10.2C7.4 10.5183 7.27357 10.8235 7.04853 11.0485C6.82348 11.2736 6.51826 11.4 6.2 11.4Z"
    />
    <path
      d="M11.2 11.4C10.8817 11.4 10.5765 11.2736 10.3515 11.0485C10.1264 10.8235 10 10.5183 10 10.2V1.2C10 0.881741 10.1264 0.576515 10.3515 0.351471C10.5765 0.126427 10.8817 0 11.2 0C11.5183 0 11.8235 0.126427 12.0485 0.351471C12.2736 0.576515 12.4 0.881741 12.4 1.2V10.2C12.4 10.5183 12.2736 10.8235 12.0485 11.0485C11.8235 11.2736 11.5183 11.4 11.2 11.4Z"
    />
  </svg>
</template>
