export default {
  churn_rate: 'อัตราการสูญเสียลูกค้า',
  churn_rate_detail_1: 'การคำณวนอัตราการสูญเสียลูกค้า',
  churn_rate_detail_2:
    'คือ การคำนวณเปอร์เซ็นต์การสูญเสียลูกค้าประจำ หรือ ลูกค้าที่เลิกใช้สินค้า หรือ บริการ เมื่อเทียบกับจำนวนลูกค้าทั้งหมดในช่วงเวลานั้น',
  churn_rate_detail_3: 'ผลลัพธ์ที่ได้จะแสดงเป็นเปอร์เซ็นต์ ซึ่งเรียกว่า "อัตราการสูญเสียลูกค้า" (Customer Churn Rate)',
  churn_rate_detail_4: 'โดยสามารถเลือกใช้สูตรการคำณวน ได้ 2 รูปแบบ :',
  churn_rate_detail_5: 'ศึกษาเพิ่มเติมให้เหมาะสมกับธุรกิจ',

  standard_formula_detail: 'สำหรับการคำณวนโดยใช้ค่าเพียง 2 ค่า ง่ายต่อการคำณวน เหมาะสำหรับธุรกิจที่เพิ่งเริ่มต้นและมีข้อมูลที่ไม่ซับซ้อน สามารถใช้สำหรับการคำณวนแบบ รายวัน / รายเดือน / รายไตรมาส / รายปี',
  adjusted_formula_detail: 'สูตรการคำณวนที่นำจำนวนลูกค้าใหม่เข้ามาคำณวนเพิ่มเติม เพื่อให้ได้เปอร์เซ็นต์ที่มี ความแม่นยำมากยิ่งขึ้น เหมาะสำหรับธุรกิจที่มีข้อมูลซับซ้อนมากขึ้น สามารถใช้สำหรับการคำณวนแบบ รายวัน / รายเดือน / รายไตรมาส / รายปี',
};
