<template>
  <svg width="95" height="99" viewBox="0 0 95 99" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M62.8361 58.198C60.218 61.8573 56.7648 64.8394 52.7632 66.8967C48.7617 68.954 44.3271 70.0272 39.8276 70.0272C35.3281 70.0272 30.8936 68.954 26.892 66.8967C22.8904 64.8394 19.4372 61.8573 16.8191 58.198C13.4651 61.2576 10.7852 64.9825 8.95037 69.1351C7.11553 73.2877 6.16594 77.7771 6.16211 82.317C6.17319 86.0364 7.65564 89.6004 10.2857 92.2304C12.9157 94.8605 16.4797 96.3429 20.1991 96.354H59.4551C63.1745 96.3429 66.7383 94.8604 69.3682 92.2304C71.9981 89.6003 73.4803 86.0363 73.4911 82.317C73.4875 77.7772 72.5383 73.288 70.7038 69.1353C68.8693 64.9827 66.1898 61.2578 62.8361 58.198Z"
      fill="#007FFF"
    />
    <path
      d="M62.3118 41.693C62.3118 46.1401 60.9931 50.4874 58.5224 54.185C56.0517 57.8826 52.54 60.7646 48.4314 62.4664C44.3228 64.1683 39.8019 64.6136 35.4402 63.746C31.0785 62.8784 27.0721 60.7369 23.9275 57.5923C20.7829 54.4477 18.6414 50.4413 17.7738 46.0796C16.9063 41.7179 17.3515 37.197 19.0534 33.0884C20.7552 28.9798 23.6372 25.4681 27.3348 22.9974C31.0324 20.5267 35.3797 19.208 39.8268 19.208C45.7902 19.208 51.5093 21.577 55.7261 25.7937C59.9429 30.0105 62.3118 35.7296 62.3118 41.693Z"
      fill="#007FFF"
    />
    <path
      d="M47.377 0.346924C41.2871 0.345935 35.3337 2.1509 30.2697 5.53354C25.2056 8.91619 21.2584 13.7246 18.9272 19.3506C16.596 24.9767 15.9856 31.1677 17.1731 37.1407C18.3605 43.1137 21.2926 48.6004 25.5985 52.907C29.9043 57.2135 35.3906 60.1465 41.3634 61.3349C47.3362 62.5234 53.5273 61.9139 59.1537 59.5837C64.7801 57.2534 69.5892 53.3069 72.9726 48.2435C76.3561 43.18 78.162 37.2268 78.162 31.1369C78.162 22.9709 74.9181 15.1394 69.1439 9.36511C63.3696 3.59086 55.5381 0.346924 47.372 0.346924M47.372 53.6959C42.9105 53.6949 38.5494 52.371 34.8403 49.8916C31.1311 47.4122 28.2405 43.8886 26.5338 39.7664C24.8271 35.6442 24.3811 31.1085 25.2521 26.7328C26.1231 22.3571 28.2721 18.338 31.4272 15.1835C34.5823 12.0291 38.602 9.88106 42.9778 9.01102C47.3537 8.14099 51.8893 8.58802 56.0112 10.2956C60.133 12.0032 63.6559 14.8946 66.1345 18.6043C68.6131 22.314 69.936 26.6754 69.936 31.1369C69.9302 37.1185 67.5517 42.8535 63.3225 47.0835C59.0932 51.3135 53.3586 53.693 47.377 53.6999"
      fill="black"
    />
    <path
      d="M83.2294 51.4819C83.2294 52.3593 82.9693 53.2169 82.4818 53.9464C81.9944 54.6759 81.3016 55.2445 80.491 55.5802C79.6804 55.916 78.7885 56.0038 77.928 55.8327C77.0675 55.6615 76.2771 55.239 75.6567 54.6186C75.0363 53.9982 74.6138 53.2078 74.4427 52.3473C74.2715 51.4868 74.3593 50.5949 74.6951 49.7843C75.0308 48.9737 75.5994 48.2809 76.3289 47.7935C77.0584 47.3061 77.9161 47.0459 78.7934 47.0459C79.9699 47.0459 81.0982 47.5133 81.9301 48.3452C82.7621 49.1771 83.2294 50.3054 83.2294 51.4819Z"
      fill="black"
    />
    <path
      d="M30.415 94.489C30.4152 95.3663 30.1552 96.224 29.6679 96.9536C29.1807 97.6832 28.488 98.252 27.6775 98.5879C26.867 98.9238 25.9751 99.0119 25.1145 98.8409C24.254 98.6699 23.4635 98.2476 22.843 97.6274C22.2224 97.0071 21.7998 96.2168 21.6284 95.3564C21.457 94.4959 21.5447 93.6039 21.8803 92.7933C22.2158 91.9826 22.7842 91.2897 23.5136 90.8021C24.243 90.3145 25.1006 90.0542 25.978 90.054C26.5605 90.054 27.1374 90.1687 27.6756 90.3916C28.2138 90.6146 28.7028 90.9413 29.1147 91.3532C29.5266 91.7651 29.8534 92.2542 30.0763 92.7924C30.2992 93.3306 30.414 93.9074 30.414 94.49"
      fill="black"
    />
    <path
      d="M89.708 58.333C89.4161 57.7687 88.9904 57.2846 88.468 56.9231C87.9456 56.5615 87.3425 56.3336 86.7116 56.2592C86.0807 56.1848 85.4412 56.2662 84.849 56.4963C84.2569 56.7263 83.7302 57.0981 83.315 57.579L83.115 57.811C82.582 58.4184 82.2421 59.1709 82.1386 59.9724C82.0352 60.7739 82.1728 61.588 82.534 62.311C85.171 67.5702 86.535 73.3757 86.516 79.259C86.5129 82.2852 85.3121 85.1871 83.176 87.3307C81.0399 89.4743 78.1422 90.6852 75.116 90.699H39.281C38.1896 90.699 37.143 91.1325 36.3713 91.9042C35.5996 92.6759 35.166 93.7226 35.166 94.814C35.166 95.9053 35.5996 96.952 36.3713 97.7237C37.143 98.4954 38.1896 98.929 39.281 98.929H72.395C85.858 98.929 94.717 90.159 94.745 79.301C94.7688 72.0105 93.0413 64.8209 89.708 58.337"
      fill="black"
    />
    <path
      d="M49.187 56.2769C48.473 56.3259 47.457 56.5949 46.731 56.5949C36.091 56.5949 27.355 48.2509 21.824 40.0459C15.1054 44.2139 9.57014 50.038 5.74885 56.9597C1.92755 63.8813 -0.0516388 71.6686 0.00102413 79.5749C0.056795 83.5939 1.35054 87.4979 3.70591 90.7549C6.06129 94.0118 9.3637 96.4632 13.163 97.7749C13.6735 97.9502 14.2136 98.0233 14.7523 97.9899C15.2911 97.9565 15.818 97.8173 16.3029 97.5803C16.7879 97.3432 17.2214 97.013 17.5787 96.6085C17.9361 96.2039 18.2102 95.7329 18.3855 95.2224C18.5608 94.7119 18.6339 94.1718 18.6005 93.6331C18.5671 93.0943 18.4279 92.5675 18.1909 92.0825C17.9539 91.5976 17.6236 91.164 17.2191 90.8067C16.8145 90.4494 16.3435 90.1752 15.833 89.9999C13.5855 89.1682 11.6494 87.6631 10.2892 85.6901C8.92899 83.7171 8.21091 81.3723 8.23302 78.9759C8.25191 73.7476 9.36168 68.5809 11.4913 63.806C13.621 59.0311 16.7237 54.7532 20.601 51.2459C27.8398 58.0788 37.4187 61.8828 47.373 61.8779C50.234 61.8779 50.652 59.2049 49.187 56.2769Z"
      fill="black"
    />
  </svg>
</template>
