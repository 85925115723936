interface ChartType {
  INFOGRAPHIC_GENDER: string;
  INFOGRAPHIC_AGE: string;
  HEATMAP: string;
  BOX: string;
  RADAR: string;
  HORIZONTAL_BAR: string;
  LINE: string;
  STRAIGHT_LINE: string;
  PIE: string;
  BAR: string;
  VERTICAL_BAR: string;
  VERTICAL_STACK_BAR: string;
  PROFILE: string;
  TABLE: string;
  NONE: string;
  OVERVIEW: string;
  GAUGE: string;
  GEOMAP: string;
  TREEMAPP: string;
  STACK_BAR_PAGINATION: string;
  SCAATTER: string;
  BAR_WITH_LINE: string;
}

export const chartTypes: ChartType = {
  INFOGRAPHIC_GENDER: 'infographic-gender',
  INFOGRAPHIC_AGE: 'infographic-age',
  HEATMAP: 'heatmap',
  BOX: 'box',
  RADAR: 'radar',
  HORIZONTAL_BAR: 'horizontal-bar',
  LINE: 'line',
  STRAIGHT_LINE: 'straight-line',
  PIE: 'pie',
  BAR: 'bar',
  VERTICAL_BAR: 'vertical-bar',
  VERTICAL_STACK_BAR: 'vertical-stack-bar',
  PROFILE: 'profile',
  TABLE: 'table',
  NONE: 'none',
  OVERVIEW: 'overview',
  GAUGE: 'gauge',
  GEOMAP: 'geo-map',
  TREEMAPP: 'treemap',
  STACK_BAR_PAGINATION: 'stack-bar-pagination',
  SCAATTER: 'scatter-plot',
  BAR_WITH_LINE: 'bar-with-line',
};
