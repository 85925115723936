import { ref, Ref, markRaw } from 'vue';

import ViewCustomerIcon from '@/assets/icons/modules/segmentv2/ViewCustomerIcon.vue';
import ViewTransactionIcon from '@/assets/icons/modules/segmentv2/ViewTransactionIcon.vue';
import ViewProductIcon from '@/assets/icons/modules/segmentv2/ViewProductIcon.vue';
import ViewAdvanceIcon from '@/assets/icons/modules/segmentv2/ViewAdvanceIcon.vue';

import { isEqual } from 'lodash-es';

export const viewOptions: Ref<SegmentView.ViewOption[]> = ref<SegmentView.ViewOption[]>([
  {
    id: 100,
    name: 'ลูกค้า',
    desc: 'แสดงผลลัพธ์ในมุมมองรายการลูกค้าด้วยคอลัมน์ข้อมูลต่างๆในตารางข้อมูลประเภทลูกค้าเช่น ชื่อ,วัดเกิด,ระดับสมาชิก,ช่องทางติดต่อ',
    icon: markRaw(ViewCustomerIcon),
    table_list: [],
    column_list: [],
    active: false,
    disabled: false,
  },
  {
    id: 300,
    name: 'ธุรกรรม',
    desc: 'แสดงผลลัพธ์ในมุมมองรายการธุรกรรม ด้วยคอลัมน์ข้อมูลต่างๆในตารางข้อมูลประเภท ธุรกรรม เช่น เลขที่บิล,ยอดรวมบิล,โปรโมชั่น',
    icon: markRaw(ViewTransactionIcon),
    table_list: [],
    column_list: [],
    active: false,
    disabled: false,
  },
  {
    id: 200,
    name: 'สินค้า',
    desc: 'แสดงผลลัพธ์ในมุมมองรายการสินค้า ด้วยคอลัมน์ข้อมูลต่างๆในตารางข้อมูลประเภท สินค้า เช่น รหัสสินค้า,ชื่อสินค้า,หมวดหมู่สินค้า',
    icon: markRaw(ViewProductIcon),
    table_list: [],
    column_list: [],
    active: false,
    disabled: false,
  },
  {
    id: 0,
    name: 'ขั้นสูง',
    desc: 'แสดงผลลัพธ์ในมุมมองรายการแบบมากกว่าหนึ่ง ประเภทตารางข้อมูลกรณีเงื่อนไขการตั้งกลุ่ม เป้าหมายมีข้อมูลที่เชื่อมต่อกับตารางที่ต้อง เช่น ต้องการดูมุมมองลูกค้าร่วมกับข้อมูลธุรกรรม',
    icon: markRaw(ViewAdvanceIcon),
    table_list: [],
    column_list: [],
    active: false,
    disabled: true,
  },
]);

export function getSelectedView(ruleSetRoot: any) {
  if (!ruleSetRoot) return null;

  return viewOptions.value.find(
    (option: SegmentView.ViewOption) =>
      !option.disabled && isEqual(option.table_list, ruleSetRoot.view_table_list) && isEqual(option.column_list, ruleSetRoot.view_column_list),
  );
}
