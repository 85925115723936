import { reactive, computed, ref, watch, Ref, onMounted, getCurrentInstance } from 'vue';
import router from '@/router';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { mockCategoriesCustomer } from '../../../mock/mockSelectCustomerDrawer';

interface Props {
  readOnly: boolean;
  isLoading: boolean;
  creditDisplay?: number;
  listCurrent: Segment.Provider.ListData[];
  selectedRecipient: Segment.Provider.SelectedRecipient;
  campaign: Campaign.ResponseDetail;
  isDrawerOpen?: boolean;
}
interface legendSelectChanged {
  name: string;
  selected: {
    consentPDPA?: boolean;
    readyToSend?: boolean;
    subscribe?: boolean;
    unConsent?: boolean;
  };
  type: string;
}
export default function useSelectCustomerDrawer(props: Props) {
  const dataFilter = reactive({
    recipients: 0,
    recpPDPA: 0,
    recpCredential: 0,
    recpDispatchable: 0,
    recpAllowUnconsent: 0,
  });
  const customerFilterSeries: any = ref([]);
  const filteredCustomerList: Ref<EChartsOption> = ref({});

  const customChartRef: Ref<any> = ref();
  const selectedSeries: legendSelectChanged = reactive({
    name: '',
    selected: {
      consentPDPA: false,
      readyToSend: false,
      Subscribe: false,
    },
    type: '',
  });

  const computedSumRecipient = computed(() => {
    try {
      let sum = 0;
      props.selectedRecipient.list_source.forEach((item) => {
        sum += item.total_selected;
      });
      return sum;
    } catch (error) {
      return 0;
    }
  });

  const resolveAllCreditUsed = computed(() => {
    let allCreditUsed = 0;
    if (props.selectedRecipient && props.creditDisplay) {
      allCreditUsed =
        (props.selectedRecipient.recp_dispatchable + (props.selectedRecipient.recp_pdpa_allowunconsent_cred || 0)) * props.creditDisplay;
    }
    return allCreditUsed;
  });

  const resolveRecpDispatchable = computed(() => {
    let RecpDispatchable = 0;
    if (props.selectedRecipient) {
      RecpDispatchable = props.selectedRecipient.recp_dispatchable + (props.selectedRecipient.recp_pdpa_allowunconsent_cred || 0);
    }
    return RecpDispatchable;
  });

  const resolveRecpDispatchableCredit = computed(() => {
    let RecpDispatchableCredit = 0;

    const creditBalance = props.campaign.creditBalance || 0;
    const creditDisplay = props.creditDisplay || 0;
    const resolvedCredit = creditBalance > resolveAllCreditUsed.value ? resolveAllCreditUsed.value : creditBalance;
    RecpDispatchableCredit = Math.floor(resolvedCredit / creditDisplay);

    return RecpDispatchableCredit;
  });

  const resolveRecpDispatchableOverCredit = computed(() => {
    let RecpDispatchableOverCredit = 0;

    const campaignCreditBalance = props.campaign.creditBalance ? props.campaign.creditBalance : 0;
    const creditToUse = resolveAllCreditUsed.value > campaignCreditBalance ? campaignCreditBalance : resolveAllCreditUsed.value;
    const creditDisplay = props.creditDisplay ? props.creditDisplay : 0;

    RecpDispatchableOverCredit = resolveRecpDispatchable.value - Math.floor(creditToUse / creditDisplay);

    return RecpDispatchableOverCredit;
  });

  watch(
    () => props.selectedRecipient,
    () => {
      if (props.selectedRecipient) {
        setDataECharts();
      }
    },
    { deep: true },
  );

  function setDataECharts(selectedUnConsent?: any) {
    dataFilter.recipients = props.selectedRecipient.recipients || 0;
    dataFilter.recpPDPA = props.selectedRecipient.recp_pdpa || 0;
    dataFilter.recpCredential = props.selectedRecipient.recp_credential || 0;
    dataFilter.recpDispatchable = props.selectedRecipient.recp_dispatchable || 0;
    dataFilter.recpAllowUnconsent = props.selectedRecipient.recp_pdpa_allowunconsent_cred || 0;
    const typeChart: Ref<string> = ref('bar');
    const sizeWidth: Ref<number> = ref(40);
    customerFilterSeries.value = [
      {
        name: mockCategoriesCustomer[0],
        type: typeChart.value,
        stack: 'stack',
        barWidth: sizeWidth.value,
        data: [dataFilter.recpPDPA],
      },
      {
        name: mockCategoriesCustomer[1],
        type: typeChart.value,
        stack: 'stack',
        barWidth: sizeWidth.value,
        data: [, dataFilter.recpCredential],
      },
      {
        name: mockCategoriesCustomer[2],
        type: typeChart.value,
        stack: 'stack',
        barWidth: sizeWidth.value,
        data: [, , dataFilter.recpDispatchable + dataFilter.recpAllowUnconsent],
      },
      {
        name: mockCategoriesCustomer[3],
        type: typeChart.value,
        stack: 'stack',
        barWidth: sizeWidth.value,
        data: [, , , dataFilter.recipients - dataFilter.recpPDPA - dataFilter.recpAllowUnconsent],
      },
    ];
    filteredCustomerList.value = {
      tooltip: { show: true },
      color: ['#002D61', '#0066DB', '#00ADFF', '#FF576A'],
      grid: {
        bottom: '40%',
      },
      legend:
        selectedUnConsent !== undefined
          ? {
              orient: 'vertical',
              itemWidth: 15,
              bottom: 0,
              left: 10,
              padding: 15,
              textStyle: { color: '#007fff', fontSize: 15 },
              data: mockCategoriesCustomer,
              selected: {
                [mockCategoriesCustomer[0]]: true,
                [mockCategoriesCustomer[1]]: true,
                [mockCategoriesCustomer[2]]: true,
                [mockCategoriesCustomer[3]]: true,
              },
            }
          : {
              orient: 'vertical',
              itemWidth: 15,
              bottom: 0,
              left: 10,
              padding: 15,
              textStyle: { color: '#007fff', fontSize: 15 },
              data: mockCategoriesCustomer,
            },
      xAxis: {
        type: 'category',
        data: mockCategoriesCustomer,
        axisLabel: {
          fontSize: 10,
          interval: 0,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        axisLabel: {
          formatter: function (params: any) {
            return params >= 1000 ? `${params / 1000}K` : params;
          },
        },
      },
      series: customerFilterSeries.value as unknown as Array<any>,
    };
  }

  const vm = getCurrentInstance()?.proxy;
  // เซ็ต Event ของ lib ECharts เมื่อ click legend Unconsent  และแสดง Modal password
  function setEChartLegendSelectChanged() {
    if (customChartRef.value) {
      const customChartElement = customChartRef.value.$el;
      const customChartId: Ref<string> = ref(customChartElement.id);
      const chartElement: HTMLElement = document.getElementById(customChartId.value) as HTMLElement;
      const myChart = ref<echarts.ECharts | null>(null);
      if (chartElement) {
        myChart.value = echarts.init(chartElement);
        myChart.value.setOption(filteredCustomerList.value);
        //legendselectchanged คือ event อันหนึ่งของ lib echart เมื่อ click legend Unconsent
        myChart.value.on('legendselectchanged', function (params: any) {
          selectedSeries.name = params.name;
          selectedSeries.selected.consentPDPA = params.selected[mockCategoriesCustomer[0]];
          selectedSeries.selected.readyToSend = params.selected[mockCategoriesCustomer[1]];
          selectedSeries.selected.subscribe = params.selected[mockCategoriesCustomer[2]];
          selectedSeries.type = params.type;
          setDataECharts(selectedSeries.name);

          // if (process.env.NODE_ENV == 'development') {
          if (process.env.BASE_ENV_URL == 'development') {
            if (selectedSeries.name == mockCategoriesCustomer[3]) {
              vm?.$emit('isModalPasswordOpens', true);
            }
          } else {
            //01.11 fix brand B2R5T0D501H3 can unconsent
            //BRDD4W3GA1IM - Wild Clinic and Natural Treat
            // BTSIT17XC9ZM - Brand Demo
            // B2R5T0D501H3 - BUN
            if (
              selectedSeries.name == mockCategoriesCustomer[3] &&
              (router.currentRoute.value.query.brandRef == 'BTSIT17XC9ZM' ||
                router.currentRoute.value.query.brandRef == 'B2R5T0D501H3' ||
                router.currentRoute.value.query.brandRef == 'BRDD4W3GA1IM')
            ) {
              vm?.$emit('isModalPasswordOpens', true);
            }
          }
          //
        });
      }
    }
  }

  const isOpenWarning: Ref<boolean> = ref(false);
  function onShowWarning() {
    const creditBalance = props.campaign.creditBalance || 0;
    if (creditBalance <= 0 || !props.campaign.isCreditEnough) {
      isOpenWarning.value = false;
    } else if (props.campaign.info.provider) {
      if (creditBalance < resolveAllCreditUsed.value) {
        isOpenWarning.value = true;
      } else {
        isOpenWarning.value = false;
      }
    } else {
      isOpenWarning.value = false;
    }
  }

  watch(
    () => resolveAllCreditUsed.value,
    () => {
      if (resolveAllCreditUsed.value) {
        onShowWarning();
      }
    },
  );

  onMounted(() => {
    setEChartLegendSelectChanged();
    onShowWarning();
  });
  return {
    computedSumRecipient,
    filteredCustomerList,
    resolveAllCreditUsed,
    customChartRef,
    resolveRecpDispatchable,
    resolveRecpDispatchableCredit,
    resolveRecpDispatchableOverCredit,
    isOpenWarning,
  };
}
