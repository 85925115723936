//Lib
import { ref, Ref, onMounted } from 'vue';
import api from '@services/api';
import { useI18n } from 'vue-i18n';

//component
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

//constants
import { TableColumnMapping } from '@/constants/modules/data-connector/dataRelation';

//Api model
import dataRelationModel from '@models/dataConnect/dataRelation';

export default function useCreateRelationModal() {
  const { openErrorModal } = useValidationModal();
  const { getSourceListModel, getTableListModel, getColumnListModel, validateExistRelationModel } = dataRelationModel();
  const { t } = useI18n();

  const isLoadingSource: Ref<boolean> = ref(false);
  const isLoadingColumnLeft: Ref<boolean> = ref(false);
  const isLoadingColumnRight: Ref<boolean> = ref(false);
  const isLoadingTableLeft: Ref<boolean> = ref(false);
  const isLoadingTableRight: Ref<boolean> = ref(false);
  const isLoadingValidate: Ref<boolean> = ref(false);

  const isRelationship: Ref<boolean> = ref(false);

  const textRelationship: Ref<string> = ref(t('dataRelation.validate_already_relationship'));

  const dropDownValueSourceLeft: Ref<DataRelation.DropDown | null> = ref(null);
  const dropDownValueSourceRight: Ref<DataRelation.DropDown | null> = ref(null);
  const dropDownSource: Ref<DataRelation.DropDown[]> = ref([
    // { id: 1, key: 'Source', label: 'ChocoCrmPos', Value: 'ChocoCrmPos' },
  ]);
  const dropDownValueTableLeft: Ref<DataRelation.DropDown | null> = ref(null);
  const dropDownTableLeft: Ref<DataRelation.DropDown[]> = ref([
    // { id: 1, key: 'Source', label: 'ChocoCrmPos', Value: 'ChocoCrmPos' },
  ]);
  const dropDownValueTableRight: Ref<DataRelation.DropDown | null> = ref(null);
  const dropDownTableRight: Ref<DataRelation.DropDown[]> = ref([
    // { id: 1, key: 'Source', label: 'ChocoCrmPos', Value: 'ChocoCrmPos' },
  ]);

  const tablesLeft: Ref<DataRelation.TableData> = ref({
    columns: [
      // { key: 'name', label: 'Name' },
    ],
    rows: [
      // {
      //   name: 'KJ',
      //   customer_id: 'XXXX',
      //   mobile_number: '09X-XXX-XXXX',
      //   address: 'XXXXXXXXX',
      //   email: 'xxxxx@gmail.com',
      //   gender: 'F',
      //   visit: '-',
      //   spending: '-',
      // },
    ],
  });

  const tablesRight: Ref<DataRelation.TableData> = ref({
    columns: [
      // { key: 'name', label: 'Name' },
    ],
    rows: [
      // { name: 'KJ', tel: '09X-XXX-XXXX', email: 'xxxxx@gmail.com', gender: 'F', birthday: '8 กันยายน 2538' },
    ],
  });

  //onChange dropDown Source get value dropDown Table api
  function onChangeSourceLeft() {
    if (dropDownValueSourceLeft.value) {
      dropDownValueTableLeft.value = null;
      getTableListLeft(dropDownValueSourceLeft.value?.id);
    }
  }
  function onChangeSourceRight() {
    if (dropDownValueSourceRight.value) {
      dropDownValueTableRight.value = null;
      getTableListRight(dropDownValueSourceRight.value?.id);
    }
  }

  //onChange dropDown Table get value Column Table api
  async function onChangeTableLeft() {
    // validate Relation
    if (dropDownValueTableLeft.value && dropDownValueTableRight.value) {
      if (dropDownValueTableLeft.value.id == dropDownValueTableRight.value.id) {
        textRelationship.value = t('dataRelation.validate_table_different');
        isRelationship.value = true;
      } else {
        await validateExistRelation();
      }
    }
    //getColumnList api
    if (dropDownValueTableLeft.value) {
      tablesLeft.value = { columns: [], rows: [] };
      getColumnList(dropDownValueTableLeft.value?.id, TableColumnMapping.LeftTable);
    }
  }
  async function onChangeTableRight() {
    if (dropDownValueTableLeft.value && dropDownValueTableRight.value) {
      // validate Relation
      if (dropDownValueTableLeft.value.id == dropDownValueTableRight.value.id) {
        textRelationship.value = t('dataRelation.validate_table_different');
        isRelationship.value = true;
      } else {
        await validateExistRelation();
      }
    }
    //getColumnList api
    if (dropDownValueTableRight.value) {
      tablesRight.value = { columns: [], rows: [] };
      getColumnList(dropDownValueTableRight.value?.id, TableColumnMapping.RightTable);
    }
  }

  //Api
  const getSourceList = () => {
    isLoadingSource.value = true;
    api
      .apiRequest(getSourceListModel)
      .then((response) => {
        const res: Ref<DataRelation.ResSourceDropDown[]> = ref(response.data.sources);
        dropDownSource.value = [];

        // Filter out the item with source_id of 0
        res.value
          .filter((item) => item.source_id !== 0)
          .forEach((item: DataRelation.ResSourceDropDown) => {
            dropDownSource.value.push({
              id: item.source_id,
              key: item.source_name,
              label: item.source_name,
              Value: item.source_name,
            });
          });
      })
      .catch((err) => {
        if (err.data.error.http_status == 404) {
          openErrorModal('404 Not Found', '');
        } else {
          openErrorModal(err.data.error.locale['th-th'].title as string, err.data.error.locale['th-th'].message as string);
        }
      })
      .finally(() => {
        isLoadingSource.value = false;
      });
  };
  const getTableListLeft = (source_id: number) => {
    isLoadingTableLeft.value = true;
    getTableListModel.payload.SourceId = source_id;
    api
      .apiRequest(getTableListModel)
      .then((response) => {
        const res: Ref<DataRelation.ResTableDropDown[]> = ref(response.data.tables);
        dropDownTableLeft.value = [];
        res.value.forEach((item: DataRelation.ResTableDropDown) => {
          dropDownTableLeft.value.push({ id: item.table_id, key: item.table_name, label: item.table_name, Value: item.table_name });
        });
      })
      .catch((err) => {
        if (err.data.error.http_status == 404) {
          openErrorModal('404 Not Found', '');
        } else {
          openErrorModal(err.data.error.locale['th-th'].title as string, err.data.error.locale['th-th'].message as string);
        }
      })
      .finally(() => {
        isLoadingTableLeft.value = false;
      });
  };
  const getTableListRight = (source_id: number) => {
    isLoadingTableRight.value = true;
    getTableListModel.payload.SourceId = source_id;
    api
      .apiRequest(getTableListModel)
      .then((response) => {
        const res: Ref<DataRelation.ResTableDropDown[]> = ref(response.data.tables);
        dropDownTableRight.value = [];
        res.value.forEach((item: DataRelation.ResTableDropDown) => {
          dropDownTableRight.value.push({ id: item.table_id, key: item.table_name, label: item.table_name, Value: item.table_name });
        });
      })
      .catch((err) => {
        if (err.data.error.http_status == 404) {
          openErrorModal('404 Not Found', '');
        } else {
          openErrorModal(err.data.error.locale['th-th'].title as string, err.data.error.locale['th-th'].message as string);
        }
      })
      .finally(() => {
        isLoadingTableRight.value = false;
      });
  };
  const getColumnList = (table_id: number, typeTable: number) => {
    if (typeTable == TableColumnMapping.LeftTable) {
      isLoadingColumnLeft.value = true;
    } else {
      isLoadingColumnRight.value = true;
    }
    getColumnListModel.payload.table_id = table_id;
    api
      .apiRequest(getColumnListModel)
      .then((response) => {
        const res: Ref<DataRelation.ResTableMapping> = ref(response.data);

        const columns: Ref<DataRelation.ColumnTable[]> = ref(
          res.value.columns.map((item: DataRelation.ResColumn) => ({
            key: item.column_name.toLocaleLowerCase(),
            label: item.column_name,
            column_id: item.column_id,
          })),
        );

        if (typeTable == TableColumnMapping.LeftTable) {
          tablesLeft.value = { columns: columns.value, rows: res.value.sample };
        } else {
          tablesRight.value = { columns: columns.value, rows: res.value.sample };
        }
      })
      .catch((err) => {
        if (err.data.error.http_status == 404) {
          openErrorModal('404 Not Found', '');
        } else {
          openErrorModal(err.data.error.locale['th-th'].title as string, err.data.error.locale['th-th'].message as string);
        }
      })
      .finally(() => {
        if (typeTable == TableColumnMapping.LeftTable) {
          isLoadingColumnLeft.value = false;
        } else {
          isLoadingColumnRight.value = false;
        }
      });
  };
  const validateExistRelation = async () => {
    isLoadingValidate.value = true;
    validateExistRelationModel.payload.Left_Table_Id = dropDownValueTableLeft.value?.id || null;
    validateExistRelationModel.payload.Right_Table_Id = dropDownValueTableRight.value?.id || null;
    await api
      .apiRequest(validateExistRelationModel)
      .then(() => {
        isRelationship.value = false;
      })
      .catch((err) => {
        if (!err.data) {
          openErrorModal('404 Not Found', '');
        } else {
          // validate-exist-relation/table-id-must-not-same --> จะต้องคนละตารางกัน
          // validate-exist-relation/table-id-is-not-valid --> ไม่พบตาราง
          // validate-exist-relation/the-relation-is-already-exist --> มีความสัมพันธ์อยู่แล้ว
          if (err.data.error.message == 'validate-exist-relation/table-id-must-not-same') {
            openErrorModal(t('dataRelation.validate_exist_relation'), t('dataRelation.validate_table_different'));
            textRelationship.value = t('dataRelation.validate_table_different');
            isRelationship.value = true;
          } else if (err.data.error.message == 'validate-exist-relation/table-id-is-not-valid') {
            openErrorModal(t('dataRelation.validate_exist_relation'), t('dataRelation.validate_table_found'));
            textRelationship.value = t('dataRelation.validate_table_found');
            isRelationship.value = true;
          } else if (err.data.error.message == 'validate-exist-relation/the-relation-is-already-exist') {
            openErrorModal(t('dataRelation.validate_exist_relation'), t('dataRelation.validate_already_relationship'));
            textRelationship.value = t('dataRelation.validate_already_relationship');
            isRelationship.value = true;
          } else {
            openErrorModal(err.data.error.locale['th-th'].title as string, err.data.error.locale['th-th'].message as string);
          }
        }
      })
      .finally(() => {
        isLoadingValidate.value = false;
      });
  };

  //
  onMounted(() => {
    getSourceList();
  });
  function onOpenModal() {
    getSourceList();
  }

  return {
    TableColumnMapping,
    tablesLeft,
    tablesRight,
    textRelationship,
    isRelationship,
    isLoadingSource,
    isLoadingTableLeft,
    isLoadingTableRight,
    isLoadingColumnLeft,
    isLoadingColumnRight,
    isLoadingValidate,
    dropDownValueSourceLeft,
    dropDownValueSourceRight,
    dropDownSource,
    dropDownValueTableLeft,
    dropDownTableLeft,
    dropDownValueTableRight,
    dropDownTableRight,
    onOpenModal,
    onChangeSourceLeft,
    onChangeSourceRight,
    onChangeTableLeft,
    onChangeTableRight,
  };
}
