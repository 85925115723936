import { ref, Ref, provide, reactive } from 'vue';

//constants
import { TableColumnMapping } from '@/constants/modules/data-connector/dataRelation';

interface DragDropData {
  start: {
    tableIndex: 'leftTable' | 'rightTable';
    colIndex: number;
    indexPairFromTable: number;
  };
  end: {
    tableIndex: 'leftTable' | 'rightTable';
    colIndex: number;
  };
}

export default function useMappingColumn() {
  const selectedColumns: Ref<DataRelation.SelectedColumns[]> = ref([
    // {
    //   indexTable: 1, //table left
    //   indexColumn: 2, //column index 2
    //   indexColor: 1, //color 'paired-1'
    // },
  ]);
  const columnPairs: Ref<DataRelation.ColumnPairs[]> = ref([
    // {
    //   leftTable: 2, //table left column index 2
    //   rightTable: 3,//table right column index 3
    //   color: 1,//color 'paired-1'
    // },
  ]);
  const selectedColumnRelationState = reactive({ columnRelationState: null });

  provide('globalselectedColumnRelationState', selectedColumnRelationState);

  function isColumnPaired(tableIndex: number, colIndex: number) {
    return columnPairs.value.some((pair: DataRelation.ColumnPairs) => {
      if (tableIndex === TableColumnMapping.LeftTable) {
        return pair.leftTable === colIndex;
      }
      if (tableIndex === TableColumnMapping.RightTable) {
        return pair.rightTable === colIndex;
      }
    });
  }

  function onSelectColumn(tableIndex: number, colIndex: number) {
    // If the column is already paired, do nothing
    if (isColumnPaired(tableIndex, colIndex)) {
      if (selectedColumns.value.length == 0) {
        if (tableIndex == TableColumnMapping.LeftTable) {
          const index = columnPairs.value.findIndex((pair: DataRelation.ColumnPairs) => pair.leftTable == colIndex);
          const found = columnPairs.value.find((pair: DataRelation.ColumnPairs) => pair.leftTable == colIndex);
          if (found) {
          }

          columnPairs.value.splice(index, 1);
        } else {
          const index = columnPairs.value.findIndex((pair: DataRelation.ColumnPairs) => pair.rightTable == colIndex);
          const found = columnPairs.value.find((pair: DataRelation.ColumnPairs) => pair.rightTable == colIndex);
          if (found) {
          }

          columnPairs.value.splice(index, 1);
        }
      }
      return;
    }

    // If the column is already selected, unselect it
    const selectedPairIndex = selectedColumns.value.findIndex(
      (pair: DataRelation.SelectedColumns) => pair.indexTable === tableIndex && pair.indexColumn === colIndex,
    );
    if (selectedPairIndex !== -1) {
      selectedColumns.value.splice(selectedPairIndex, 1);
    } else {
      // If less than 2 columns are selected, less than 3 columns are pairs
      if (selectedColumns.value.length < 2 && columnPairs.value.length < 3) {
        //
        selectedColumns.value.push({ indexTable: tableIndex, indexColumn: colIndex, indexColor: columnPairs.value.length + 1 });
      }
    }

    // If two columns are selected, pair them
    if (selectedColumns.value.length === 2) {
      const [first, second] = selectedColumns.value;
      if (first.indexTable !== second.indexTable) {
        if (columnPairs.value.length < 3) {
          if (first.indexTable == TableColumnMapping.LeftTable) {
            columnPairs.value.push({ leftTable: first.indexColumn, rightTable: second.indexColumn, color: first.indexColor });
          } else {
            columnPairs.value.push({ leftTable: second.indexColumn, rightTable: first.indexColumn, color: first.indexColor });
          }
        }
      }
      selectedColumns.value = [];
    }
  }

  function onDragDropColumnPair(data: DragDropData) {
    if (
      data.start.colIndex >= 0 &&
      data.start.colIndex < columnPairs.value.length &&
      data.end.colIndex >= 0 &&
      data.end.colIndex < columnPairs.value.length
    ) {
      const temp = columnPairs.value[data.start.colIndex][data.start.tableIndex];
      columnPairs.value[data.start.colIndex][data.start.tableIndex] = columnPairs.value[data.end.colIndex][data.end.tableIndex];
      columnPairs.value[data.end.colIndex][data.end.tableIndex] = temp;
    }
  }

  function onDropDragTable(data: DragDropData) {
    if (data.start.colIndex >= 0 && data.end.colIndex >= 0 && data.end.colIndex < columnPairs.value.length) {
      if (!isColumnPaired(data.start.tableIndex == 'leftTable' ? 0 : 1, data.start.colIndex)) {
        columnPairs.value[data.end.colIndex][data.end.tableIndex] = data.start.colIndex;
      } else {
        const temp = columnPairs.value[data.start.indexPairFromTable][data.start.tableIndex];
        columnPairs.value[data.start.indexPairFromTable][data.start.tableIndex] = columnPairs.value[data.end.colIndex][data.end.tableIndex];
        columnPairs.value[data.end.colIndex][data.end.tableIndex] = temp;
      }
    }
  }

  function removePair(index: number) {
    columnPairs.value.splice(index, 1);
  }
  function resetColumn() {
    selectedColumns.value = [];
    columnPairs.value = [];
  }
  return { selectedColumns, columnPairs, onSelectColumn, onDropDragTable, resetColumn, removePair, onDragDropColumnPair };
}
