<template>
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_167_255090" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="13">
      <path d="M7.85314 0.919922H0.369141V12.9209H7.85314V0.919922Z" fill="white" />
    </mask>
    <g mask="url(#mask0_167_255090)">
      <path
        d="M5.63991 0.919922C5.69909 0.954757 5.75325 0.997482 5.80091 1.04692C5.82713 1.08345 5.8435 1.12612 5.84845 1.17081C5.85341 1.2155 5.84677 1.26072 5.82919 1.3021C5.81161 1.34349 5.78368 1.37965 5.74808 1.40712C5.71248 1.43458 5.6704 1.45242 5.62591 1.45892C5.57954 1.46534 5.53272 1.46801 5.48591 1.46692H2.73991C2.69311 1.4679 2.6463 1.46523 2.59991 1.45892C2.54743 1.45258 2.4983 1.42982 2.45951 1.39391C2.42073 1.358 2.39427 1.31076 2.38391 1.25892C2.3692 1.20796 2.37094 1.15365 2.3889 1.10373C2.40685 1.05381 2.4401 1.01084 2.48391 0.980922C2.51601 0.959534 2.54941 0.940163 2.58391 0.922922L5.63991 0.919922Z"
        fill="#07964B"
      />
      <path
        d="M5.75616 8.96557H5.89916C6.43016 8.96557 6.96216 8.96557 7.49316 8.96557C7.56325 8.9628 7.63321 8.97368 7.69916 8.99757C7.74077 9.01715 7.77675 9.04696 7.80371 9.08422C7.83067 9.12148 7.84775 9.16496 7.85334 9.21061C7.85894 9.25626 7.85287 9.30258 7.8357 9.34524C7.81852 9.38791 7.79082 9.42553 7.75516 9.45457C7.56916 9.64457 7.37716 9.82757 7.18716 10.0136L4.35616 12.7836C4.17216 12.9636 4.05616 12.9636 3.86816 12.7836L0.51716 9.50657C0.470919 9.47399 0.433287 9.43068 0.407505 9.38033C0.381723 9.32999 0.368564 9.27413 0.36916 9.21757C0.38716 9.05457 0.50316 8.96757 0.70616 8.96757H2.28216V8.82557C2.28216 7.4389 2.28216 6.05224 2.28216 4.66557C2.2765 4.60484 2.28426 4.54361 2.30489 4.48621C2.32552 4.42881 2.35852 4.37666 2.40156 4.33343C2.44459 4.29021 2.4966 4.25698 2.5539 4.23609C2.61121 4.2152 2.67241 4.20717 2.73316 4.21257H5.48716C5.66816 4.21257 5.72416 4.25857 5.74816 4.43857C5.75543 4.51234 5.75777 4.58649 5.75516 4.66057C5.75516 6.04323 5.75516 7.4259 5.75516 8.80857L5.75616 8.96557Z"
        fill="#07964B"
      />
      <path
        d="M4.11206 2.56472H2.74206C2.69122 2.56656 2.64032 2.56354 2.59006 2.55572C2.53069 2.54276 2.47746 2.51009 2.43904 2.46302C2.40061 2.41595 2.37925 2.35726 2.37844 2.2965C2.37763 2.23574 2.39741 2.1765 2.43457 2.12842C2.47172 2.08034 2.52406 2.04626 2.58306 2.03172C2.62517 2.02324 2.66811 2.01955 2.71106 2.02072H5.51106C5.54616 2.01991 5.58128 2.02192 5.61606 2.02672C5.68028 2.03672 5.73883 2.0693 5.78117 2.11861C5.82352 2.16792 5.84688 2.23072 5.84706 2.29572C5.84658 2.36074 5.82248 2.42338 5.77924 2.47195C5.73601 2.52052 5.67659 2.55171 5.61206 2.55972C5.53425 2.56634 5.45611 2.56834 5.37806 2.56572L4.11206 2.56472Z"
        fill="#07964B"
      />
      <path
        d="M4.11016 3.66247H2.68016C2.62522 3.66693 2.57017 3.6552 2.52183 3.62871C2.47349 3.60223 2.43397 3.56217 2.40815 3.51347C2.38138 3.47173 2.36895 3.42239 2.37274 3.37295C2.37653 3.3235 2.39633 3.27664 2.42915 3.23947C2.48374 3.18317 2.55304 3.14333 2.62915 3.12447C2.72594 3.11163 2.82381 3.10894 2.92115 3.11647H5.44015C5.47915 3.11647 5.51816 3.11647 5.55716 3.11647C5.59485 3.114 5.63264 3.11939 5.66814 3.13228C5.70365 3.14518 5.73609 3.1653 5.76342 3.19137C5.79075 3.21745 5.81236 3.24892 5.82691 3.28378C5.84145 3.31864 5.8486 3.35614 5.84791 3.3939C5.84721 3.43167 5.83869 3.46888 5.82288 3.50318C5.80706 3.53749 5.7843 3.56813 5.75603 3.59319C5.72776 3.61824 5.69461 3.63716 5.65865 3.64874C5.6227 3.66032 5.58473 3.66431 5.54715 3.66047C5.06715 3.66047 4.58615 3.66047 4.10615 3.66047"
        fill="#07964B"
      />
    </g>
  </svg>
</template>
