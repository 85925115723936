<template>
  <svg width="106" height="94" viewBox="0 0 106 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48.5 93C64.7924 93 78 79.7924 78 63.5C78 47.2076 64.7924 34 48.5 34C32.2076 34 19 47.2076 19 63.5C19 79.7924 32.2076 93 48.5 93Z"
      fill="#027FFF"
    />
    <path
      d="M12.3871 21.825C11.5349 21.825 10.7018 22.0777 9.99317 22.5511C9.28456 23.0246 8.73227 23.6976 8.40613 24.485C8.07999 25.2723 7.99466 26.1387 8.16092 26.9746C8.32719 27.8105 8.73758 28.5782 9.3402 29.1809C9.94283 29.7835 10.7106 30.1939 11.5465 30.3602C12.3823 30.5264 13.2487 30.4411 14.0361 30.1149C14.8235 29.7888 15.4965 29.2365 15.9699 28.5279C16.4434 27.8193 16.6961 26.9862 16.6961 26.134C16.6961 25.5681 16.5847 25.0078 16.3681 24.485C16.1516 23.9622 15.8342 23.4872 15.434 23.087C15.0339 22.6869 14.5589 22.3695 14.0361 22.153C13.5133 21.9364 12.953 21.825 12.3871 21.825Z"
      fill="black"
    />
    <path
      d="M103.291 37.3126L99.16 38.0866C97.2865 29.6786 93.1719 21.9345 87.2527 15.6762C81.3335 9.4178 73.8305 4.87853 65.5398 2.54C57.2491 0.201461 48.48 0.150894 40.1629 2.39366C31.8458 4.63642 24.291 9.08886 18.3 15.2786C17.9199 15.6816 17.6273 16.1589 17.4409 16.6806C17.2545 17.2023 17.1782 17.757 17.2168 18.3096C17.2554 18.8622 17.4081 19.4009 17.6653 19.8916C17.9224 20.3823 18.2785 20.8143 18.711 21.1606C19.5045 21.7949 20.5072 22.1085 21.5208 22.0392C22.5344 21.97 23.4851 21.523 24.185 20.7866C29.1505 15.6614 35.4079 11.9724 42.2964 10.1091C49.1849 8.24577 56.4488 8.27737 63.3208 10.2005C70.1928 12.1237 76.4179 15.8671 81.3386 21.0352C86.2594 26.2034 89.693 32.6045 91.277 39.5626L85.958 40.5626C85.6188 40.6257 85.3035 40.7809 85.0465 41.0111C84.7895 41.2413 84.6007 41.5378 84.5007 41.868C84.4007 42.1982 84.3933 42.5495 84.4795 42.8837C84.5656 43.2178 84.7418 43.5218 84.989 43.7626L95.569 54.0926C95.7871 54.3058 96.0537 54.4629 96.346 54.5503C96.6383 54.6376 96.9474 54.6527 97.2467 54.5942C97.5461 54.5356 97.8268 54.4052 98.0646 54.2142C98.3024 54.0232 98.4903 53.7773 98.612 53.4976L105.457 40.2256C105.63 39.8898 105.705 39.5122 105.674 39.1357C105.643 38.7592 105.507 38.399 105.282 38.096C105.056 37.793 104.75 37.5594 104.398 37.4218C104.047 37.2842 103.663 37.2481 103.292 37.3176"
      fill="black"
    />
    <path
      d="M93.6449 72.1756C94.4972 72.1756 95.3303 71.9229 96.0389 71.4494C96.7475 70.9759 97.2998 70.303 97.6259 69.5156C97.9521 68.7282 98.0374 67.8618 97.8711 67.026C97.7049 66.1901 97.2945 65.4223 96.6919 64.8197C96.0892 64.2171 95.3214 63.8067 94.4856 63.6404C93.6497 63.4742 92.7833 63.5595 91.996 63.8856C91.2086 64.2118 90.5356 64.7641 90.0621 65.4727C89.5887 66.1813 89.3359 67.0144 89.3359 67.8666C89.3359 69.0094 89.7899 70.1054 90.598 70.9135C91.4061 71.7216 92.5021 72.1756 93.6449 72.1756Z"
      fill="black"
    />
    <path
      d="M2.36579 56.7566L6.87279 55.9126C9.12623 65.8431 14.4813 74.799 22.163 81.4837C29.8446 88.1685 39.4542 92.2353 49.6008 93.0956C56.6249 93.6716 63.6892 92.6807 70.2837 90.1945C76.8782 87.7082 82.8384 83.7886 87.7338 78.7186C88.1139 78.3156 88.4063 77.8381 88.5927 77.3164C88.779 76.7947 88.8552 76.24 88.8165 75.6873C88.7778 75.1347 88.625 74.5961 88.3677 74.1054C88.1105 73.6148 87.7543 73.1828 87.3218 72.8366C86.5278 72.2029 85.5251 71.89 84.5116 71.9596C83.4982 72.0292 82.5477 72.4763 81.8478 73.2126C76.8821 78.3377 70.6245 82.0267 63.7359 83.8899C56.8473 85.7531 49.5834 85.7214 42.7113 83.7981C35.8392 81.8748 29.614 78.1314 24.6933 72.9632C19.7725 67.7949 16.3388 61.3937 14.7548 54.4356L20.0738 53.4356C20.413 53.3724 20.7283 53.2172 20.9853 52.987C21.2423 52.7568 21.4311 52.4604 21.5311 52.1302C21.6311 51.8 21.6384 51.4486 21.5523 51.1145C21.4662 50.7804 21.2899 50.4764 21.0428 50.2356L10.4648 39.9116C10.2467 39.6984 9.98003 39.5413 9.68777 39.4539C9.39552 39.3665 9.08641 39.3515 8.78704 39.41C8.48767 39.4685 8.207 39.5989 7.96918 39.7899C7.73136 39.981 7.54351 40.2269 7.42179 40.5066L0.243792 53.8536C0.0665154 54.1847 -0.0135915 54.5592 0.0127039 54.9339C0.0389992 55.3085 0.170626 55.6681 0.392415 55.9712C0.614203 56.2743 0.917125 56.5086 1.26627 56.647C1.61541 56.7854 1.99656 56.8224 2.36579 56.7536"
      fill="black"
    />
    <path
      d="M53.0271 70.1739C50.0891 70.1896 47.1788 69.6054 44.4745 68.4569C41.7702 67.3084 39.3289 65.62 37.3001 63.4949C36.9836 63.1638 36.8022 62.7265 36.7915 62.2686C36.7808 61.8108 36.9415 61.3654 37.2421 61.0199L40.3771 57.4199C40.5439 57.2285 40.7488 57.0738 40.9786 56.9657C41.2084 56.8576 41.4582 56.7984 41.7121 56.7919H41.7581C42.0046 56.7918 42.2486 56.8413 42.4755 56.9375C42.7025 57.0337 42.9077 57.1747 43.0791 57.3519C44.3651 58.741 45.9159 59.8589 47.6403 60.64C49.3646 61.421 51.2277 61.8494 53.1201 61.8999C57.3601 61.8999 59.8931 60.0839 59.8931 57.0409C59.8931 54.2479 58.3981 53.3409 54.1241 51.4789L48.2021 48.8679C41.9521 46.1979 38.7721 42.0679 38.7721 36.5869C38.7721 29.3119 45.1341 23.8269 53.5721 23.8269C56.0768 23.8264 58.5564 24.3268 60.8649 25.2988C63.1734 26.2709 65.2641 27.6948 67.0141 29.4869C67.3281 29.8102 67.5119 30.238 67.5304 30.6883C67.5488 31.1386 67.4006 31.58 67.1141 31.9279L64.3271 35.2949C64.1674 35.4887 63.9696 35.6477 63.746 35.7619C63.5224 35.8761 63.2777 35.9432 63.0271 35.9589C62.9891 35.9589 62.9501 35.9589 62.9131 35.9589C62.4448 35.9585 61.9942 35.7797 61.6531 35.4589C60.5948 34.3915 59.3353 33.5445 57.9475 32.9671C56.5597 32.3897 55.0712 32.0932 53.5681 32.0949C50.7441 32.0949 47.7261 33.1949 47.7261 36.2739C47.7261 38.8069 49.8461 39.8859 53.3381 41.3619L59.3031 43.9429C63.7671 45.8619 68.8931 49.0429 68.8931 56.6349C68.8931 64.4789 62.2191 70.1709 53.0281 70.1709"
      fill="black"
    />
    <path
      d="M52.3059 76.1484H55.2139C55.6804 76.1484 56.1278 75.9631 56.4577 75.6332C56.7876 75.3033 56.9729 74.8559 56.9729 74.3894V19.6114C56.9713 19.1459 56.7853 18.6999 56.4556 18.3711C56.1259 18.0424 55.6794 17.8577 55.2139 17.8574H52.3059C51.8394 17.8577 51.3922 18.0431 51.0624 18.3729C50.7325 18.7027 50.5471 19.15 50.5469 19.6164V74.3894C50.5469 74.6204 50.5924 74.8492 50.6808 75.0626C50.7692 75.276 50.8987 75.4699 51.0621 75.6332C51.2254 75.7966 51.4193 75.9261 51.6327 76.0145C51.8461 76.1029 52.0749 76.1484 52.3059 76.1484Z"
      fill="black"
    />
  </svg>
</template>
