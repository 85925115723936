export enum ResultListType {
  Result = 1,
  Preview = 2,
}

// TODO clean code
export enum OldSegmentType {
  Batch = 1,
  Realtime = 2,
  Custom = 3,
}

export enum SegmentType {
  Custom = 1,
  Realtime = 2,
  Batch = 3,
}

export enum SaveType {
  Save = 1,
  DeleteData = 2,
  CopyTo = 3,
}
