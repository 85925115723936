import { Ref } from 'vue';

import useLoading from '@/views/components/loading/hooks/useLoading';

import segmentResultModel from '@/models/segment/result';
import api from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import { OldSegmentType, ResultListType, SaveType } from '@/constants/modules/segment-v2/segment-result';

export default function (
  resultListType: ResultListType,
  segment: SegmentResult.OldSegment,
  dataSourceDisplay: Ref<PreviewSegment.DatasourceDisplay>,
  includeOrExcludeList: Ref<Record<string, any>[]>,
  isSelectedAll: Ref<boolean>,
  saveSegmentState: SegmentResult.OldSaveSegmentState,
  onSaveSegmentSuccess: (response: any) => void,
  onDuplicateName: () => void,
) {
  const { isLoading } = useLoading();

  const { openDefaultErrorModal, openSuccessModal } = useValidationModal();

  const { fetchSegmentSaveModel } = segmentResultModel();

  function mapColumn(): PreviewSegment.SaveColumns[] {
    return dataSourceDisplay.value.columns.map((element) => ({
      ColumnId: element.column_id,
      Ref_columnId: element.ref_columnid,
      Col_Type: element.col_type,
    }));
  }
  function mapData(): PreviewSegment.Datas[] {
    return includeOrExcludeList.value.map((element) => {
      const dataMap = dataSourceDisplay.value.columns.map((el) => ({
        ColumnId: el.column_id,
        Value: element[el.key],
      }));
      return { Data_Map: dataMap };
    });
  }
  function buildPayload(): SegmentResult.SaveSegmentPayload {
    const payload: SegmentResult.SaveSegmentPayload = {
      Save_Type: Number(saveSegmentState.saveType),
      Type: Number(saveSegmentState.segmentType),
      Name: saveSegmentState.name,
      Dest_Root_TableId: null!,
      Dest_FolderId: null!,
      Dest_SegmentId: null!,
      Root_TableId: 20,
      Root_FolderId: null!,
      Apply_SegmentId: Number(segment.id),
      SegmentId: null!,
      saveColumns: saveSegmentState.segmentType == OldSegmentType.Custom ? mapColumn() : [],
      Datas: mapData(),
      select_flag: isSelectedAll.value ? 2 : 1,
      ResultType: null!,
    };

    if (resultListType == ResultListType.Result) {
      if (saveSegmentState.saveType == SaveType.Save) {
        payload.Dest_FolderId = saveSegmentState.saveData.folderSelect.folderId;
        payload.Dest_SegmentId = null!;
        payload.Root_FolderId = null!;
      } else if (saveSegmentState.saveType == SaveType.DeleteData) {
        payload.Dest_FolderId = Number(segment.folderId);
        payload.Dest_SegmentId = Number(segment.id);
        payload.Root_FolderId = Number(segment.folderId);
      } else if (saveSegmentState.saveType == SaveType.CopyTo) {
        payload.Dest_FolderId = saveSegmentState.saveData.folderSelect.folderId;
        payload.Dest_SegmentId = saveSegmentState.saveData.segmentSelect.segmentId;
        payload.Root_FolderId = Number(segment.folderId);
      }
      payload.SegmentId = null!;
    } else if (resultListType == ResultListType.Preview) {
      payload.Dest_FolderId = saveSegmentState.saveData.folderSelect.folderId;
      payload.Dest_SegmentId = Number(segment.id);
      payload.Root_FolderId = saveSegmentState.saveData.folderSelect.folderId;
      payload.SegmentId = Number(segment.id);
    }

    return payload;
  }
  function saveSegment() {
    isLoading(true);
    fetchSegmentSaveModel.payload = buildPayload();
    api
      .apiRequest(fetchSegmentSaveModel)
      .then((response) => {
        if (response.status === 'success')
          openSuccessModal(
            'บันทึกรายการสำเร็จ',
            '',
            '',
            () => onSaveSegmentSuccess(response),
            () => onSaveSegmentSuccess(response),
          );
      })
      .catch((error) => {
        if (error.data.error.message == 'Name of Segment is duplicate') onDuplicateName();
        else openDefaultErrorModal(error);
      })
      .finally(() => {
        isLoading(false);
      });
  }

  return {
    saveSegment,
  };
}
