<template>
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0008 14.3369C18.4567 15.0683 19.7049 16.242 20.6161 17.7096C20.7965 18.0003 20.8867 18.1456 20.9179 18.3468C20.9813 18.7558 20.7016 19.2585 20.3207 19.4204C20.1333 19.5 19.9225 19.5 19.5008 19.5M15.0008 10.0322C16.4825 9.29589 17.5008 7.76686 17.5008 6C17.5008 4.23314 16.4825 2.70411 15.0008 1.96776M13.0008 6C13.0008 8.48528 10.9861 10.5 8.50081 10.5C6.01553 10.5 4.00081 8.48528 4.00081 6C4.00081 3.51472 6.01553 1.5 8.50081 1.5C10.9861 1.5 13.0008 3.51472 13.0008 6ZM1.56004 17.4383C3.15435 15.0446 5.67019 13.5 8.50081 13.5C11.3314 13.5 13.8473 15.0446 15.4416 17.4383C15.7909 17.9628 15.9655 18.225 15.9454 18.5599C15.9297 18.8207 15.7588 19.14 15.5504 19.2976C15.2827 19.5 14.9146 19.5 14.1785 19.5H2.82317C2.08698 19.5 1.71889 19.5 1.45125 19.2976C1.24286 19.14 1.0719 18.8207 1.05625 18.5599C1.03614 18.225 1.21078 17.9628 1.56004 17.4383Z"
      stroke="#007FFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
