<template>
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.29707 8.43078C2.59757 8.4321 1.9271 8.71056 1.43248 9.20518C0.937853 9.6998 0.659392 10.3703 0.65807 11.0698V18.3398C0.657008 19.0408 0.934417 19.7136 1.4293 20.2102C1.92418 20.7068 2.59601 20.9865 3.29707 20.9878H10.5671C11.2683 20.9878 11.9408 20.7092 12.4367 20.2134C12.9325 19.7175 13.2111 19.045 13.2111 18.3438V11.0698C13.2097 10.3701 12.9311 9.69948 12.4363 9.20483C11.9415 8.71018 11.2707 8.43183 10.5711 8.43078H3.29707ZM3.29707 7.76978H10.5671C11.4423 7.76978 12.2817 8.11745 12.9005 8.73632C13.5194 9.35519 13.8671 10.1946 13.8671 11.0698V18.3398C13.8671 19.215 13.5194 20.0544 12.9005 20.6732C12.2817 21.2921 11.4423 21.6398 10.5671 21.6398H3.29707C2.42186 21.6398 1.58249 21.2921 0.963618 20.6732C0.344748 20.0544 -0.00292969 19.215 -0.00292969 18.3398L-0.00292969 11.0698C-0.00292969 10.1946 0.344748 9.35519 0.963618 8.73632C1.58249 8.11745 2.42186 7.76978 3.29707 7.76978Z"
      fill="currentColor"
    />
    <path
      opacity="0.398"
      d="M10.4033 20.6584H3.46583C3.14022 20.6592 2.81735 20.5957 2.51661 20.4709C2.21587 20.3462 1.94329 20.1629 1.71387 19.9319C1.48283 19.7022 1.2996 19.4291 1.17481 19.1282C1.05002 18.8273 0.986598 18.5047 0.987311 18.179V11.2395C0.987587 10.609 1.22747 10.0018 1.65919 9.54224C2.0909 9.08266 2.68223 8.80553 3.31153 8.76587C2.97459 9.63371 2.80311 10.5566 2.80567 11.4875C2.80307 12.4927 3.00361 13.4877 3.39551 14.4133C3.77339 15.3067 4.32075 16.1192 5.00684 16.8049C5.69206 17.4904 6.50292 18.0365 7.39551 18.4143C8.32127 18.8057 9.31622 19.0062 10.3213 19.0042C11.1838 19.0054 12.0402 18.8584 12.8525 18.5686C12.7601 19.1477 12.4652 19.6756 12.0205 20.0579C11.5709 20.4454 10.9969 20.6582 10.4033 20.6584Z"
      fill="currentColor"
    />
    <path
      d="M8.80193 2.20257C8.87859 2.24522 8.96904 2.2557 9.05342 2.2317C9.1378 2.20769 9.2092 2.15118 9.25193 2.07457C9.37114 1.86039 9.51956 1.66384 9.69293 1.49057C9.74801 1.42734 9.77704 1.34557 9.77416 1.26177C9.77128 1.17797 9.7367 1.09838 9.67741 1.03909C9.61812 0.979794 9.53853 0.945215 9.45473 0.942334C9.37093 0.939454 9.28915 0.968487 9.22593 1.02357C9.00927 1.24015 8.82382 1.48584 8.67493 1.75357C8.65368 1.79149 8.64013 1.83325 8.63506 1.87642C8.62999 1.9196 8.63351 1.96336 8.6454 2.00517C8.6573 2.04699 8.67734 2.08604 8.70437 2.12009C8.7314 2.15414 8.7649 2.18251 8.80293 2.20357"
      fill="currentColor"
    />
    <path
      d="M10.8837 0.794665C10.9089 0.794554 10.9341 0.791535 10.9587 0.785665C11.1554 0.739952 11.3567 0.716803 11.5587 0.716665H12.3847C12.4723 0.716665 12.5564 0.681845 12.6184 0.619864C12.6804 0.557883 12.7152 0.473819 12.7152 0.386165C12.7152 0.298511 12.6804 0.214447 12.6184 0.152466C12.5564 0.0904854 12.4723 0.055665 12.3847 0.055665H11.5587C11.3052 0.0554768 11.0526 0.0843317 10.8057 0.141665C10.7262 0.159484 10.6561 0.206087 10.6089 0.2725C10.5617 0.338914 10.5407 0.420443 10.55 0.501389C10.5593 0.582334 10.5983 0.656974 10.6593 0.710935C10.7204 0.764896 10.7992 0.794364 10.8807 0.793665"
      fill="currentColor"
    />
    <path
      d="M8.58784 4.6574C8.67528 4.65713 8.75906 4.62228 8.82089 4.56045C8.88272 4.49862 8.91757 4.41484 8.91784 4.3274V3.5014C8.91784 3.41374 8.88302 3.32968 8.82103 3.2677C8.75905 3.20572 8.67499 3.1709 8.58734 3.1709C8.49968 3.1709 8.41562 3.20572 8.35364 3.2677C8.29166 3.32968 8.25684 3.41374 8.25684 3.5014V4.3274C8.2571 4.41484 8.29195 4.49862 8.35378 4.56045C8.41561 4.62228 8.4994 4.65713 8.58684 4.6574"
      fill="currentColor"
    />
    <path
      d="M8.58784 7.0717C8.67528 7.07144 8.75906 7.03659 8.82089 6.97476C8.88272 6.91293 8.91757 6.82915 8.91784 6.7417V5.9157C8.91784 5.82805 8.88302 5.74399 8.82103 5.682C8.75905 5.62002 8.67499 5.58521 8.58734 5.58521C8.49968 5.58521 8.41562 5.62002 8.35364 5.682C8.29166 5.74399 8.25684 5.82805 8.25684 5.9157V6.7417C8.2571 6.82915 8.29195 6.91293 8.35378 6.97476C8.41561 7.03659 8.4994 7.07144 8.58684 7.0717"
      fill="currentColor"
    />
    <path
      d="M16.519 0.716664H17.345C17.4326 0.716664 17.5167 0.681844 17.5787 0.619863C17.6407 0.557882 17.6755 0.473818 17.6755 0.386164C17.6755 0.29851 17.6407 0.214446 17.5787 0.152465C17.5167 0.0904845 17.4326 0.0556641 17.345 0.0556641L16.519 0.0556641C16.4313 0.0556641 16.3473 0.0904845 16.2853 0.152465C16.2233 0.214446 16.1885 0.29851 16.1885 0.386164C16.1885 0.473818 16.2233 0.557882 16.2853 0.619863C16.3473 0.681844 16.4313 0.716664 16.519 0.716664Z"
      fill="currentColor"
    />
    <path
      d="M14.0395 0.716664H14.8655C14.9531 0.716664 15.0372 0.681844 15.0992 0.619863C15.1612 0.557882 15.196 0.473818 15.196 0.386164C15.196 0.29851 15.1612 0.214446 15.0992 0.152465C15.0372 0.0904845 14.9531 0.0556641 14.8655 0.0556641L14.0395 0.0556641C13.9518 0.0556641 13.8678 0.0904845 13.8058 0.152465C13.7438 0.214446 13.709 0.29851 13.709 0.386164C13.709 0.473818 13.7438 0.557882 13.8058 0.619863C13.8678 0.681844 13.9518 0.716664 14.0395 0.716664Z"
      fill="currentColor"
    />
    <path
      d="M19.8053 0.879617C19.8847 0.881186 19.9621 0.854131 20.0232 0.803394C20.0843 0.752657 20.1252 0.681622 20.1383 0.603262C20.1514 0.524901 20.1359 0.444443 20.0946 0.376578C20.0533 0.308714 19.9889 0.257972 19.9133 0.233617C19.6229 0.133349 19.32 0.0741108 19.0133 0.0576172C18.9287 0.0577016 18.8473 0.090183 18.7859 0.148389C18.7245 0.206595 18.6877 0.286106 18.6831 0.370589C18.6785 0.455072 18.7064 0.538113 18.7611 0.602653C18.8158 0.667192 18.8932 0.708331 18.9773 0.717617C19.222 0.730905 19.4637 0.778365 19.6953 0.858617C19.7301 0.870517 19.7665 0.876599 19.8033 0.876617"
      fill="currentColor"
    />
    <path
      d="M21.8056 5.95703C21.7181 5.9573 21.6344 5.99215 21.5725 6.05398C21.5107 6.11581 21.4758 6.19959 21.4756 6.28703V7.11303C21.4756 7.20068 21.5104 7.28475 21.5724 7.34673C21.6344 7.40871 21.7184 7.44353 21.8061 7.44353C21.8937 7.44353 21.9778 7.40871 22.0398 7.34673C22.1018 7.28475 22.1366 7.20068 22.1366 7.11303V6.28703C22.1363 6.19959 22.1015 6.11581 22.0396 6.05398C21.9778 5.99215 21.893 5.9573 21.8056 5.95703Z"
      fill="currentColor"
    />
    <path
      d="M21.8056 3.47778C21.7181 3.47805 21.6344 3.5129 21.5725 3.57473C21.5107 3.63656 21.4758 3.72034 21.4756 3.80778V4.63378C21.4756 4.72144 21.5104 4.8055 21.5724 4.86748C21.6344 4.92946 21.7184 4.96428 21.8061 4.96428C21.8937 4.96428 21.9778 4.92946 22.0398 4.86748C22.1018 4.8055 22.1366 4.72144 22.1366 4.63378V3.80778C22.1363 3.72034 22.1015 3.63656 22.0396 3.57473C21.9778 3.5129 21.893 3.47805 21.8056 3.47778Z"
      fill="currentColor"
    />
    <path
      d="M21.8056 8.43457C21.7181 8.43483 21.6344 8.46969 21.5725 8.53152C21.5107 8.59335 21.4758 8.67713 21.4756 8.76457V9.59057C21.4756 9.67822 21.5104 9.76229 21.5724 9.82427C21.6344 9.88625 21.7184 9.92107 21.8061 9.92107C21.8937 9.92107 21.9778 9.88625 22.0398 9.82427C22.1018 9.76229 22.1366 9.67822 22.1366 9.59057V8.76457C22.1363 8.67713 22.1015 8.59335 22.0396 8.53152C21.9778 8.46969 21.893 8.43483 21.8056 8.43457Z"
      fill="currentColor"
    />
    <path
      d="M18.4189 13.6816C18.4187 13.5941 18.3838 13.5103 18.322 13.4485C18.2602 13.3867 18.1764 13.3518 18.0889 13.3516H17.2629C17.1753 13.3516 17.0912 13.3864 17.0292 13.4484C16.9673 13.5103 16.9324 13.5944 16.9324 13.6821C16.9324 13.7697 16.9673 13.8538 17.0292 13.9158C17.0912 13.9777 17.1753 14.0126 17.2629 14.0126H18.0889C18.1766 14.0123 18.2605 13.9773 18.3224 13.9153C18.3842 13.8532 18.4189 13.7692 18.4189 13.6816Z"
      fill="currentColor"
    />
    <path
      d="M16.1025 13.6816C16.1023 13.5941 16.0674 13.5103 16.0056 13.4485C15.9438 13.3867 15.86 13.3518 15.7725 13.3516H14.9465C14.8589 13.3516 14.7748 13.3864 14.7128 13.4484C14.6509 13.5103 14.616 13.5944 14.616 13.6821C14.616 13.7697 14.6509 13.8538 14.7128 13.9158C14.7748 13.9777 14.8589 14.0126 14.9465 14.0126H15.7725C15.8602 14.0123 15.9441 13.9773 16.0059 13.9153C16.0678 13.8532 16.1025 13.7692 16.1025 13.6816Z"
      fill="currentColor"
    />
    <path
      d="M21.5667 2.51937C21.6211 2.51865 21.6744 2.50455 21.7221 2.47831C21.7697 2.45208 21.8101 2.41451 21.8398 2.36894C21.8694 2.32337 21.8874 2.2712 21.8921 2.21703C21.8968 2.16286 21.8881 2.10837 21.8667 2.05837C21.7459 1.77662 21.5863 1.51309 21.3927 1.27537C21.3374 1.20735 21.2574 1.16407 21.1702 1.15507C21.083 1.14607 20.9957 1.17208 20.9277 1.22737C20.8597 1.28267 20.8164 1.36273 20.8074 1.44993C20.7984 1.53714 20.8244 1.62435 20.8797 1.69237C21.0345 1.88246 21.162 2.09315 21.2587 2.31837C21.2839 2.37722 21.3257 2.42746 21.379 2.46297C21.4323 2.49848 21.4947 2.51773 21.5587 2.51837"
      fill="currentColor"
    />
    <path
      d="M20.1717 12.9092C19.9609 13.0336 19.7337 13.1278 19.4967 13.1892C19.42 13.2104 19.3534 13.2586 19.3093 13.3249C19.2652 13.3912 19.2465 13.4712 19.2566 13.5501C19.2667 13.6291 19.3049 13.7019 19.3642 13.7549C19.4236 13.808 19.5001 13.8379 19.5797 13.8392C19.6078 13.8391 19.6357 13.8354 19.6627 13.8282C19.9593 13.7515 20.2438 13.6337 20.5077 13.4782C20.5451 13.4561 20.5777 13.4269 20.6038 13.3922C20.6299 13.3576 20.6489 13.3181 20.6597 13.2761C20.6705 13.234 20.673 13.1903 20.6669 13.1473C20.6608 13.1044 20.6463 13.063 20.6242 13.0257C20.6022 12.9883 20.573 12.9557 20.5383 12.9296C20.5036 12.9035 20.4641 12.8845 20.4221 12.8737C20.3801 12.8629 20.3364 12.8605 20.2934 12.8665C20.2504 12.8726 20.2091 12.8871 20.1717 12.9092Z"
      fill="currentColor"
    />
    <path
      d="M21.8118 10.9153C21.7693 10.9063 21.7255 10.9059 21.6828 10.9139C21.6402 10.922 21.5995 10.9383 21.5632 10.9621C21.5269 10.9859 21.4956 11.0166 21.4712 11.0525C21.4467 11.0884 21.4296 11.1287 21.4208 11.1713C21.371 11.411 21.2879 11.6426 21.1738 11.8593C21.1536 11.8977 21.1411 11.9397 21.1371 11.9829C21.1332 12.0261 21.1377 12.0697 21.1506 12.1112C21.1634 12.1526 21.1843 12.1912 21.2121 12.2246C21.2398 12.2579 21.2739 12.2855 21.3123 12.3058C21.3507 12.326 21.3927 12.3384 21.436 12.3424C21.4792 12.3464 21.5228 12.3418 21.5642 12.329C21.6057 12.3161 21.6442 12.2952 21.6776 12.2675C21.711 12.2397 21.7386 12.2057 21.7588 12.1673C21.9019 11.8961 22.0062 11.6063 22.0688 11.3063C22.0777 11.2638 22.0782 11.2199 22.0702 11.1773C22.0621 11.1346 22.0457 11.094 22.0219 11.0576C21.9982 11.0213 21.9675 10.99 21.9316 10.9656C21.8957 10.9412 21.8543 10.9241 21.8118 10.9153Z"
      fill="currentColor"
    />
    <path
      d="M14.8942 7.91371V7.00371H13.9812C13.9007 7.00371 13.8235 6.97174 13.7666 6.91482C13.7097 6.8579 13.6777 6.7807 13.6777 6.70021C13.6777 6.61972 13.7097 6.54252 13.7666 6.4856C13.8235 6.42869 13.9007 6.39671 13.9812 6.39671H14.8912V5.48371C14.8912 5.40282 14.9234 5.32524 14.9806 5.26804C15.0378 5.21084 15.1153 5.17871 15.1962 5.17871C15.2771 5.17871 15.3547 5.21084 15.4119 5.26804C15.4691 5.32524 15.5012 5.40282 15.5012 5.48371V6.39371H16.4112C16.4917 6.39371 16.5689 6.42569 16.6258 6.4826C16.6828 6.53952 16.7147 6.61672 16.7147 6.69721C16.7147 6.7777 16.6828 6.8549 16.6258 6.91182C16.5689 6.96874 16.4917 7.00071 16.4112 7.00071H15.5012V7.91071C15.5012 7.9912 15.4693 8.0684 15.4123 8.12532C15.3554 8.18224 15.2782 8.21421 15.1977 8.21421C15.1172 8.21421 15.04 8.18224 14.9831 8.12532C14.9262 8.0684 14.8942 7.9912 14.8942 7.91071V7.91371Z"
      fill="currentColor"
    />
  </svg>
</template>
