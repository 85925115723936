<template>
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_167_35436" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="3" width="19" height="12">
      <path d="M18.4273 3H0.570312V14.437H18.4273V3Z" fill="white" />
    </mask>
    <g mask="url(#mask0_167_35436)">
      <path
        d="M15.6597 6.26338C15.0237 5.62338 14.4407 5.03238 13.8537 4.44638C13.7509 4.35609 13.672 4.2419 13.6239 4.11388C13.5757 3.98587 13.5599 3.84798 13.5777 3.71238C13.5923 3.56306 13.65 3.42122 13.7438 3.30406C13.8375 3.18691 13.9632 3.09947 14.1057 3.05238C14.2525 2.99441 14.4135 2.9825 14.5673 3.01822C14.7211 3.05395 14.8604 3.13561 14.9667 3.25238C15.614 3.90104 16.2597 4.55104 16.9037 5.20238C17.3157 5.61838 17.7277 6.03404 18.1397 6.44938C18.2731 6.56198 18.3673 6.71405 18.4087 6.88365C18.4501 7.05326 18.4365 7.23163 18.37 7.39304C18.3035 7.55445 18.1874 7.69057 18.0385 7.78175C17.8897 7.87293 17.7157 7.91447 17.5417 7.90038C13.2583 7.90038 8.97465 7.90038 4.69065 7.90038C4.57856 7.90822 4.46604 7.89292 4.36012 7.85542C4.25419 7.81791 4.15711 7.75901 4.07492 7.68238C3.99274 7.60574 3.9272 7.51302 3.88239 7.40997C3.83758 7.30692 3.81445 7.19575 3.81445 7.08338C3.81445 6.971 3.83758 6.85983 3.88239 6.75678C3.9272 6.65373 3.99274 6.56101 4.07492 6.48437C4.15711 6.40774 4.25419 6.34884 4.36012 6.31133C4.46604 6.27383 4.57856 6.25853 4.69065 6.26638C8.23065 6.26638 11.7707 6.26638 15.3107 6.26638H15.6587"
        fill="currentColor"
      />
      <path
        d="M3.3602 11.171C3.4602 11.271 3.5102 11.336 3.5682 11.394C4.1112 11.943 4.6602 12.486 5.1962 13.041C5.2717 13.1164 5.33159 13.206 5.37246 13.3045C5.41332 13.4031 5.43436 13.5088 5.43436 13.6155C5.43436 13.7222 5.41332 13.8279 5.37246 13.9265C5.33159 14.025 5.2717 14.1146 5.1962 14.19C5.12446 14.2655 5.03845 14.3261 4.94315 14.3682C4.84785 14.4103 4.74515 14.433 4.641 14.4352C4.53684 14.4373 4.4333 14.4188 4.33635 14.3807C4.2394 14.3425 4.15098 14.2855 4.0762 14.213C2.97687 13.1183 1.8842 12.0163 0.798199 10.907C0.683388 10.7961 0.606792 10.6516 0.579471 10.4943C0.55215 10.337 0.575517 10.1751 0.646199 10.032C0.703992 9.88405 0.805253 9.75708 0.936638 9.66783C1.06802 9.57857 1.22337 9.53122 1.3822 9.532C1.4502 9.532 1.5172 9.532 1.5822 9.532H14.2062C14.3064 9.52755 14.4067 9.53156 14.5062 9.544C14.7025 9.57955 14.879 9.68559 15.0026 9.84217C15.1261 9.99876 15.1882 10.1951 15.1772 10.3943C15.1661 10.5934 15.0827 10.7817 14.9425 10.9236C14.8024 11.0655 14.6152 11.1514 14.4162 11.165C14.3322 11.171 14.2472 11.165 14.1632 11.165H3.3602V11.171Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
