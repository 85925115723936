import { acceptHMRUpdate, defineStore } from 'pinia';
import apiService from '@/services/api';
import loginModel from '@/models/authentication/login';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import router from '@/router';

// define store
// see: https://pinia.vuejs.org/core-concepts/
//
// alternative to main store state type, we can also force state type by define the object interface
export interface ModuleStoreState {
  Global: string;
  Segment: string;
  BusinessIntelligence: string;
  CustomerInsight: string;
  Communication: string;
  MKA: string;
  Analytics: string;
  DataManagement: string;
  DataSource: string;
  DataRelation: string;
  DataConnector: string;
  DataIngestion: string;
  DataMapping: string;
  DataMerge: string;
}

export const useModuleStore = defineStore('module', {
  // we use <Type>{} to ensure object creation with specify type
  // in this case, <AuthStoreState>{} to ensure state type
  state: () =>
    <ModuleStoreState>{
      Global: '1.0.0',
      Segment: '1.0.0',
      BusinessIntelligence: '1.0.0',
      CustomerInsight: '1.0.0',
      Communication: '1.0.0',
      MKA: '1.0.0',
      Analytics: '1.0.0',
      DataManagement: '1.0.0',
      DataSource: '1.0.0',
      DataRelation: '1.0.0',
      DataConnector: '1.0.0',
      DataIngestion: '1.0.0',
      DataMapping: '1.0.0',
      DataMerge: '1.0.0',
    },
  getters: {
    getGlobalVersion: (state) => state.Global,
    getSegmentVersion: (state) => state.Segment,
    getBusinessIntelligenceVersion: (state) => state.BusinessIntelligence,
    getCustomerInsightVersion: (state) => state.CustomerInsight,
    getCommunicationVersion: (state) => state.Communication,
    getMKAVersion: (state) => state.MKA,
    getAnalyticsVersion: (state) => state.Analytics,
    getDataManagementVersion: (state) => state.DataManagement,
    getDataSourceVersion: (state) => state.DataSource,
    getDataRelationVersion: (state) => state.DataRelation,
    getDataConnectorVersion: (state) => state.DataConnector,
    getDataIngestionVersion: (state) => state.DataIngestion,
    getDataMappingVersion: (state) => state.DataMapping,
    getDataMergeVersion: (state) => state.DataMerge,
  },
  actions: {
    async SET_MODULE_VERSION() {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const { fetchAllModuleVersions } = loginModel();
      const { openDefaultErrorModal } = useValidationModal();
      if (true) {
        //Martech
      } else {
        await apiService
          .apiRequest(fetchAllModuleVersions)
          .then((resp) => {
            const resModuleVersions = resp.data.module_versions;

            // Iterate through the fetched module versions
            resModuleVersions.forEach((moduleVersion: { module_name: string; version: string }) => {
              const moduleName = moduleVersion.module_name as keyof ModuleStoreState;

              // Update only if moduleName exists in the state
              if (this[moduleName] !== undefined) {
                this[moduleName] = moduleVersion.version;
              }
            });
          })
          .catch((err: any) => {
            openDefaultErrorModal(err, () => {
              // Optional: Add any logout or error-handling logic here
            });
          })
          .finally(() => {
            console.log('Current state:', JSON.parse(JSON.stringify(this)));
          });
      }
    },
    updateVersionByModule(version: string, module: keyof ModuleStoreState) {
      //Martech
      const brandRef = router.currentRoute.value.query.brandRef as string;
      const segmentV2Brand = ['BTSIT17XC9ZM', 'BL6ZLW8PXBXA', 'BC47UQQHYVIF', 'BNRE0BK41S3B'];
      if (segmentV2Brand.includes(brandRef)) {
        if (this[module] !== undefined) {
          // Update the version of the specified module
          this[module] = version;
        } else {
          console.warn(`Module ${module} does not exist in the state.`);
        }
      }
      // Check if the specified module exists in the state
    },
  },
});

// to add HMR support with vue-cli/webpack, these code must be added to each store file
if (import.meta.webpackHot) import.meta.webpackHot.accept(acceptHMRUpdate(useModuleStore, import.meta.webpackHot));
