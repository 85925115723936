import analyticsChartTab from '@models/analytics/churn-rate/chart/analytics-chart-tab';
import analyticsChartLayout from '@models/analytics/churn-rate/chart/analytics-chart-layout';
import analyticsChartChart from '@models/analytics/churn-rate/chart/analytics-chart';

export default function () {
  const { fetchAnalyticsChartTabsModel } = analyticsChartTab();
  const { fetchAnalyticsChartLayoutModel, fetchAnalyticsDeleteChartLayoutModel, fetchAnalyticsAdjustChartLayoutModel } = analyticsChartLayout();
  const {
    fetchAnalyticsChartGlobalFilterListModel,
    fetchAnalyticsChartDynamicFilterListModel,
    fetchChartSetListModel,
    fetchAnalyticsChartSettingEditModel,
    fetchAnalyticsChartDataInfoModel,
    fetchChartAddModel,
    fetchAnalyticsChartSettingInfoModel,
    fetchAnalyticsChartDataModel,
    fetchAnalyticsChartExportDataInfoModel,
    fetchAnalyticsChartActivityCampaignModel,
    fetchAnalyticsChartCampaignSummaryModel,
    fetchAnalyticsChartCampaignSummaryListModel,
  } = analyticsChartChart();

  return {
    fetchAnalyticsChartTabsModel,
    fetchAnalyticsChartLayoutModel,
    fetchAnalyticsAdjustChartLayoutModel,
    fetchAnalyticsDeleteChartLayoutModel,
    fetchChartSetListModel,
    fetchChartAddModel,
    fetchAnalyticsChartDataModel,
    fetchAnalyticsChartSettingInfoModel,
    fetchAnalyticsChartSettingEditModel,
    fetchAnalyticsChartDataInfoModel,
    fetchAnalyticsChartGlobalFilterListModel,
    fetchAnalyticsChartDynamicFilterListModel,
    fetchAnalyticsChartExportDataInfoModel,
    fetchAnalyticsChartActivityCampaignModel,
    fetchAnalyticsChartCampaignSummaryModel,
    fetchAnalyticsChartCampaignSummaryListModel,
  };
}
