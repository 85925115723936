import { getCurrentInstance, onMounted, reactive, Ref, ref, computed, nextTick, watch } from 'vue';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import router from '@/router';

import api from '@services/api';

import analyticsModel from '@models/analytics';

import useValidationModal from '@views/components/modal/hooks/useValidationModal';

import { HEATMAP, RADAR } from '@/constants/components/chart/base-chart';
import { COLOR_INDEX } from '@/constants/components/chart/chart-summarybox';
import { resolveGenderTypeTh } from '@/constants/components/chart/chart-infographic';
import { GIRD_LAYOUT, useAnalyticsChartFetch } from '../../../hooks/useAnalytics';
import { chartTypes } from '@/constants/components/chart/chart-types';
import { ITEM_TYPE } from '@/constants/modules/bi/item-type';

import { YUZU_BRANDS } from '@/constants/modules/bi/dynamic-dropdown';

interface ModalAnalytics {
  addAnalyticsChart: boolean;
  addTab: boolean;
  settingChart: boolean;
  dataInfo: boolean;
  editTabItem: boolean;
  dynamicFilter: boolean;
  filterSelector: boolean;
}

interface ChartItemProps {
  chartLayoutId: number | null;
  chartProps: Charts.ChartsProps;
  startDate?: Date | null;
  endDate?: Date | null;
}

export default function useOverViewAnal() {
  const vm = getCurrentInstance()?.proxy;

  const {
    fetchAnalyticsAdjustChartLayoutModel,
    fetchAnalyticsDeleteChartLayoutModel,
    fetchChartAddModel,
    fetchAnalyticsChartSettingInfoModel,
    fetchAnalyticsChartSettingEditModel,
    fetchAnalyticsChartDataInfoModel,
    fetchAnalyticsChartTabsModel,
  } = analyticsModel();

  const {
    layout,
    sourceList,
    sourceListOptions,
    relativeModel,
    loading,
    seen,
    fetched,
    filterList,
    fetchAnalyticsChartGlobalFilter,
    fetchAnalyticsDynamicFilter,
    fetchLayoutAnalyticsChart,
    setAllSeen,
    onSeen,
    onCustomChartContainerFetched,
    dynamicFilterDataList,
  } = useAnalyticsChartFetch();

  const { t } = useI18n();

  const { openSuccessModal, openErrorModal, openWarningModal } = useValidationModal();

  const startDate: Ref<Date | null> = ref(null);
  const endDate: Ref<Date | null> = ref(null);

  /**DynamicFilter */
  const dynamicFilter = ref<string[]>([]);
  const dynamicChartFilter = ref<string[]>([]);
  const dynamicChartOptions = ref<Bi.Filter.DynamicOptions | null>(null);
  const filterChartSegmentOptions = ref<Bi.Filter.DynamicOptions | null>(null);
  const filterChartSourceOptions = ref<Bi.Filter.DynamicOptions | null>(null);
  const isUseDynamicFilter = ref<boolean>(false);
  const isUseDynamicFilterInChart = ref<boolean>(false);
  const filterItemProps = ref<string[]>([]);

  //tab-list
  const tabList: Ref<Bi.BiTab.Item[]> = ref([]);
  const pageIdActive: Ref<number> = ref(0);

  //this state trigger when date range filter changed
  const isMainFilterChanged: Ref<boolean> = ref(false);

  //modal state
  const modalOpen: ModalAnalytics = reactive({
    addAnalyticsChart: false,
    addTab: false,
    dataInfo: false,
    settingChart: false,
    editTabItem: false,
    dynamicFilter: false,
    filterSelector: false,
  });

  const chartItemProps: Ref<ChartItemProps> = ref({
    chartLayoutId: null,
    chartProps: {
      chartId: null,
      chartSetId: null,
      title: '',
      tooltip: '',
      chartsType: 'none',
      options: {},
      graphRatioWidth: 100,
      graphRatioHeight: 100,
      implType: null,
      implKeyRef: '',
      extra: {
        left: {
          show: false,
          type: '',
        },
        top: {
          show: false,
          type: '',
        },
        bottom: {
          show: false,
          type: '',
        },
        right: {
          show: false,
          type: '',
        },
      },
      colorList: [],
      rawData: {
        title: '',
        columns: [],
        data: [],
        filter: {
          dateTime: false,
          source: false,
          store: false,
          dynamic_filter: [],
        },
      },
    },
  });

  const tabItemProps: Ref<Bi.BiTab.Item> = ref({
    seq: -1,
    pageId: -1,
    name: '',
    path: '',
    isDraggable: false,
    isEditable: false,
    isDeletable: false,
    isTypeDefault: false,
  });

  const chartSettingModalProps: Ref<Charts.ChartsSettingInfoModalProps> = ref({
    name: '',
    typeSelected: null,
    typeList: [],
    itemPropertyList: [],
  });

  const chartDataInfoModalProps: Ref<Charts.ChartsDataInfoModalProps> = ref({
    chartName: '',
    description: '',
    filters: [],
    dataInfo: {
      columns: [],
      data: [],
    },
  });

  const chartDataInfoExportProps: Ref<Charts.ChartDataInfoExportProps> = ref({
    chartLayoutId: null,
    chartId: null,
    chartSetId: null,
    implType: null,
    implKeyRef: '',
    filters: [],
  });

  const computedTabName = computed(() => {
    if (tabList.value.length > 0) {
      const found = tabList.value.find((el) => el.pageId === pageIdActive.value);
      if (found) {
        return found.name;
      } else {
        return t('bi.not_found_tab_item');
      }
    } else {
      return t('bi.tab_list_empty');
    }
  });

  const onClickDateSelect = () => {
    isMainFilterChanged.value = true;
  };

  const onResetDate = async () => {
    dynamicFilter.value = [];
    dynamicChartFilter.value = [];
    if (startDate.value == null && endDate.value == null) return;

    if (relativeModel.value == 'P90D') {
      endDate.value = new Date();
      startDate.value = new Date(new Date().setDate(endDate.value.getDate() - 90));
    } else {
      endDate.value = new Date();
      startDate.value = new Date(new Date().setDate(endDate.value.getDate() - 30));
    }

    onClickDateSelect();
    await fetchAnalyticsChartGlobalFilter();
    await nextTick(() => {
      isMainFilterChanged.value = false;
      setAllSeen(false);
      fetchLayoutAnalyticsChart(pageIdActive.value);
    });
  };

  const onExportPreview = () => {
    const tabName = tabList.value.find((el) => el.pageId === pageIdActive.value);

    if (sourceList.value.length && relativeModel.value && startDate.value && endDate.value && tabName && fetched.value.length) {
      const sourceFilter = sourceList.value.map((el) => {
        return el.value;
      });

      const start = startDate.value ? new Date(startDate.value).getTime() : '';
      const end = endDate.value ? new Date(endDate.value).getTime() : '';

      window.open(
        `/analytics/export-preview?layoutId=${pageIdActive.value}&source=${JSON.stringify(sourceFilter)}&startDate=${start}&endDate=${end}`,
        '_blank',
      );
    } else {
      openErrorModal(t('bi.fillDataSourceAndDate'));
    }
  };

  const onCloseAddAnalyticsChartModal = () => {
    modalOpen.addAnalyticsChart = false;
  };

  const onCloseAddTabModal = () => {
    modalOpen.addTab = false;
  };

  const onCloseDataInfoModal = () => {
    modalOpen.dataInfo = false;
  };

  const onCloseSettingChartModal = () => {
    modalOpen.settingChart = false;
  };

  const onCloseEditTabItemModal = () => {
    modalOpen.editTabItem = false;
  };

  const onCloseFilterSelectorModal = () => {
    modalOpen.filterSelector = false;
  };

  const onApplySetting = (selectedChartType: string, itemProperty: SettingChartModal.ItemProperty[]) => {
    fetchAnalyticsChartSettingEditModel.payload.ChartLayoutId = chartItemProps.value.chartLayoutId!;
    fetchAnalyticsChartSettingEditModel.payload.ChartId = chartItemProps.value.chartProps.chartId!;
    fetchAnalyticsChartSettingEditModel.payload.Chart_Color_List = <Charts.ChartsSettingEdit.Request.Payload.ColorItem[]>itemProperty.map((el) => ({
      colorid: el.colorId,
      seq: el.seq,
      hex_color: el.color,
    }));
    api
      .apiRequest(fetchAnalyticsChartSettingEditModel)
      .then(() => {})
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        if (chartItemProps.value.chartLayoutId) {
          const component = <any>vm?.$refs[`CustomChartContainerRef-${chartItemProps.value.chartLayoutId}`];
          component[0].fetchOptionGraphById(chartItemProps.value.chartLayoutId);
        } else {
          throw 'missing chart layout id';
        }
        modalOpen.settingChart = false;
      });
  };

  function mapChartDataInfoModalProps(
    input: Charts.ChartDataInfo.Request.Response,
    filters: DataInfoModal.Filter[],
  ): Charts.ChartsDataInfoModalProps {
    return <Charts.ChartsDataInfoModalProps>{
      chartName: input.chart_name,
      description: input.chart_desc,
      filters: filters,
      dataInfo: {
        columns: input.column.map((el) => ({
          key: el.column_key,
          label: el.column_value,
          columnType: el.column_type,
          columnTypeDesc: el.column_type_desc,
        })),
        data: input.rawdata.data[0]?.datalist || [],
      },
    };
  }

  const onClickDataInfo = (chartLayoutId: number, chartProps: Charts.ChartsProps, startDate: Date | null, endDate: Date | null) => {
    loading.value.dataInfo = true;
    const filters: DataInfoModal.Filter[] = [];
    filters.push({
      key: 'startDate',
      value: startDate ? DateTime.fromISO(startDate.toISOString()).toFormat('yyyy-MM-dd') : '',
    });
    filters.push({
      key: 'endDate',
      value: endDate ? DateTime.fromISO(endDate.toISOString()).toFormat('yyyy-MM-dd') : '',
    });
    filters.push({
      key: 'source',
      value: sourceList.value.map((el) => el.value),
    });
    fetchAnalyticsChartDataInfoModel.payload.ChartLayoutId = chartLayoutId;
    fetchAnalyticsChartDataInfoModel.payload.ChartSetId = chartProps.chartSetId!;
    fetchAnalyticsChartDataInfoModel.payload.ChartId = chartProps.chartId!;
    fetchAnalyticsChartDataInfoModel.payload.Impl_Type = chartProps.implType!;
    fetchAnalyticsChartDataInfoModel.payload.Impl_Key_Ref = chartProps.implKeyRef;
    fetchAnalyticsChartDataInfoModel.payload.Filters = filters.map((el) => ({
      Key: el.key,
      Value: el.value,
    }));

    // mapping chart data info export data
    chartDataInfoExportProps.value.chartLayoutId = chartLayoutId;
    chartDataInfoExportProps.value.chartSetId = chartProps.chartSetId!;
    chartDataInfoExportProps.value.chartId = chartProps.chartId!;
    chartDataInfoExportProps.value.implType = chartProps.implType!;
    chartDataInfoExportProps.value.implKeyRef = chartProps.implKeyRef;
    chartDataInfoExportProps.value.filters = filters;

    api
      .apiRequest(fetchAnalyticsChartDataInfoModel)
      .then((response) => {
        chartDataInfoModalProps.value = mapChartDataInfoModalProps(response.data.result as Charts.ChartDataInfo.Request.Response, filters);
        modalOpen.dataInfo = true;
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        loading.value.dataInfo = false;
      });
  };

  function mapChartSettingInfo(
    input: Charts.ChartsSettingInfo.Request.Response,
    rawData: Charts.ChartsProps.RawData,
    chartType: Charts.ChartsProps.ChartType,
    colorList: Charts.ChartsProps.ColorItem[],
  ): Charts.ChartsSettingInfoModalProps {
    switch (chartType) {
      case chartTypes.INFOGRAPHIC_GENDER:
      case chartTypes.INFOGRAPHIC_AGE: {
        const itemPropertyListMapped: SettingChartModal.ItemProperty[] = [];
        if (input.chart_color_list.length > 0) {
          rawData.data.forEach((el: any, index: number) => {
            itemPropertyListMapped.push({
              seq: input.chart_color_list[index].seq,
              label: resolveGenderTypeTh(<string>rawData.data[index].type),
              color: input.chart_color_list[index].hex_color,
              colorId: input.chart_color_list[index].colorid,
            });
          });
        } else {
          /* no mapping color list , do nothing */
        }
        return <Charts.ChartsSettingInfoModalProps>{
          name: input.chart_name,
          typeSelected: input.chart_type_list.find((el) => el.is_default)?.type_name,
          typeList: input.chart_type_list.map((el) => el.type_name),
          itemPropertyList: itemPropertyListMapped,
        };
      }

      case chartTypes.HEATMAP: {
        let itemPropertyListMapped: SettingChartModal.ItemProperty[] = [];
        itemPropertyListMapped = [
          {
            seq: colorList[HEATMAP.COLOR_INDEX].seq,
            label: 'Color',
            colorId: colorList[HEATMAP.COLOR_INDEX].colorId,
            color: colorList[HEATMAP.COLOR_INDEX].hexColor,
          },
        ];
        return <Charts.ChartsSettingInfoModalProps>{
          name: input.chart_name,
          typeSelected: input.chart_type_list.find((el) => el.is_default)?.type_name,
          typeList: input.chart_type_list.map((el) => el.type_name),
          itemPropertyList: itemPropertyListMapped,
        };
      }

      case chartTypes.BOX: {
        let itemPropertyListMapped: SettingChartModal.ItemProperty[] = [];
        itemPropertyListMapped = colorList.map((el, index) => ({
          seq: el.seq,
          label: index === COLOR_INDEX.BACKGROUND_COLOR_INDEX ? 'background' : 'label',
          colorId: el.colorId,
          color: el.hexColor,
        }));
        return <Charts.ChartsSettingInfoModalProps>{
          name: input.chart_name,
          typeSelected: input.chart_type_list.find((el) => el.is_default)?.type_name,
          typeList: input.chart_type_list.map((el) => el.type_name),
          itemPropertyList: itemPropertyListMapped,
        };
      }

      case chartTypes.RADAR: {
        const itemPropertyListMapped: SettingChartModal.ItemProperty[] = [];
        if (input.chart_color_list.length > 0) {
          rawData.data[RADAR.RAW_DATA_INDEX].data.forEach((el: any, index: number) => {
            itemPropertyListMapped.push({
              seq: input.chart_color_list[index].seq,
              label: el.name,
              color: input.chart_color_list[index].hex_color,
              colorId: input.chart_color_list[index].colorid,
            });
          });
        } else {
          /* no mapping color list , do nothing */
        }
        return <Charts.ChartsSettingInfoModalProps>{
          name: input.chart_name,
          typeSelected: input.chart_type_list.find((el) => el.is_default)?.type_name,
          typeList: input.chart_type_list.map((el) => el.type_name),
          itemPropertyList: itemPropertyListMapped,
        };
      }
      case chartTypes.GEOMAP: {
        let itemPropertyListMapped: SettingChartModal.ItemProperty[] = [];
        itemPropertyListMapped = [
          {
            seq: colorList[HEATMAP.COLOR_INDEX].seq,
            label: 'Color',
            colorId: colorList[HEATMAP.COLOR_INDEX].colorId,
            color: colorList[HEATMAP.COLOR_INDEX].hexColor,
          },
        ];
        return <Charts.ChartsSettingInfoModalProps>{
          name: input.chart_name,
          typeSelected: input.chart_type_list.find((el) => el.is_default)?.type_name,
          typeList: input.chart_type_list.map((el) => el.type_name),
          itemPropertyList: itemPropertyListMapped,
        };
      }
      default: {
        const itemPropertyListMapped: SettingChartModal.ItemProperty[] = [];
        if (input.chart_color_list.length > 0) {
          rawData.data.forEach((el: any, index: number) => {
            console.log(rawData.data[index].data);
            itemPropertyListMapped.push({
              seq: input.chart_color_list[index].seq,
              label: rawData.data[index].data || rawData.data[index].name,
              color: input.chart_color_list[index].hex_color,
              colorId: input.chart_color_list[index].colorid,
            });
          });
        } else {
          /* no mapping color list , do nothing */
        }
        return <Charts.ChartsSettingInfoModalProps>{
          name: input.chart_name,
          typeSelected: input.chart_type_list.find((el) => el.is_default)?.type_name,
          typeList: input.chart_type_list.map((el) => el.type_name),
          itemPropertyList: itemPropertyListMapped,
        };
      }
    }
  }

  const onClickSettingChart = (chartLayoutId: number, chartProps: Charts.ChartsProps, startDate: Date | null, endDate: Date | null) => {
    loading.value.settingChart = true;
    chartItemProps.value = {
      chartLayoutId,
      chartProps,
      startDate,
      endDate,
    };
    fetchAnalyticsChartSettingInfoModel.payload.ChartLayoutId = chartLayoutId;
    fetchAnalyticsChartSettingInfoModel.payload.ChartSetId = chartProps.chartSetId!;
    fetchAnalyticsChartSettingInfoModel.payload.ChartId = chartProps.chartId!;
    api
      .apiRequest(fetchAnalyticsChartSettingInfoModel)
      .then((response) => {
        chartSettingModalProps.value = mapChartSettingInfo(
          response.data as Charts.ChartsSettingInfo.Request.Response,
          chartProps.rawData,
          chartProps.chartsType,
          chartProps.colorList,
        );
        modalOpen.settingChart = true;
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        loading.value.settingChart = false;
      });
  };

  const onClickDelete = (chartLayoutId: number, chartProps: Charts.ChartsProps, startDate: Date | null, endDate: Date | null) => {
    loading.value.delete = true;
    chartItemProps.value = {
      chartLayoutId,
      chartProps,
      startDate,
      endDate,
    };
    openWarningModal(
      t('bi.confirm_deletion'),
      `${t('bi.confirm_deletion')} ${String(chartProps.title)} ${t('bi.or_not')}?`,
      () => {
        loading.value.layout = true;
        fetchAnalyticsDeleteChartLayoutModel.payload.ChartLayoutId = chartLayoutId;
        api
          .apiRequest(fetchAnalyticsDeleteChartLayoutModel)
          .then(async () => {
            await fetchLayoutAnalyticsChart(pageIdActive.value);
          })
          .catch((error) => {
            openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
            return Promise.reject();
          })
          .finally(() => {
            loading.value.layout = false;
            loading.value.delete = false;
          });
      },
      () => {
        loading.value.delete = false;
      },
      () => {
        loading.value.delete = false;
      },
      `${t('bi.confirm')}`,
    );
  };

  const onClickEditTab = (tabItem: Bi.BiTab.Item) => {
    modalOpen.editTabItem = true;
    tabItemProps.value = tabItem;
  };

  const onAdjustLayout = () => {
    fetchAnalyticsAdjustChartLayoutModel.payload.PageId = pageIdActive.value;
    fetchAnalyticsAdjustChartLayoutModel.payload.Layout = layout.value;
    api
      .apiRequest(fetchAnalyticsAdjustChartLayoutModel)
      .then(() => {})
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      });
  };

  const addNewChartFromSelection = (chartSetList: Bi.BiChartSet.Item[]) => {
    loading.value.layout = true;
    fetchChartAddModel.payload.PageId = pageIdActive.value;
    fetchChartAddModel.payload.Charts = chartSetList.map((el) => ({
      ChartSetId: el.chartSetId,
      ChartId: el.chartId,
    }));
    api
      .apiRequest(fetchChartAddModel)
      .then(async () => {
        openSuccessModal(t('bi.successful'));
        await fetchLayoutAnalyticsChart(pageIdActive.value);
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        loading.value.layout = false;
        modalOpen.addAnalyticsChart = false;
      });
  };

  async function onClickApplyFilter() {
    isMainFilterChanged.value = false;
    dynamicChartFilter.value = [];
    dynamicChartFilter.value = dynamicFilter.value;
    setAllSeen(false);
    await fetchLayoutAnalyticsChart(pageIdActive.value);
  }

  function scrollTopOnLoad() {
    const mainContent = document.getElementsByClassName('main-content')[0] as HTMLElement;
    mainContent.scrollTop = 0;
  }

  function fetchAnalyticsChartTabList(): Promise<void> {
    function mapBiTabList(input: Bi.BiTab.Request.Response.Item[]): Bi.BiTab.Item[] {
      return input.map((el: Bi.BiTab.Request.Response.Item) => ({
        seq: el.seq,
        pageId: el.pageid,
        name: el.tab_name,
        path: el.tab_name,
        isDraggable: el.type === ITEM_TYPE.GENERIC,
        isEditable: el.type === ITEM_TYPE.GENERIC,
        isDeletable: el.type === ITEM_TYPE.GENERIC,
        isTypeDefault: el.type === ITEM_TYPE.DEFAULT,
      }));
    }

    loading.value.tabs = true;
    return api
      .apiRequest(fetchAnalyticsChartTabsModel)
      .then((response) => {
        tabList.value = mapBiTabList(response.data.payload.data_list as Bi.BiTab.Request.Response.Item[]);
        pageIdActive.value = tabList.value.map((item: any) => item.pageId)[0];
        return Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject();
      })
      .finally(() => {
        loading.value.tabs = false;
      });
  }

  const onClickOpenSeeMoreFilterhModal = (
    chartLayoutId: number,
    chartProps: Charts.ChartsProps,
    sourceFilterList: string[],
    sourceFilterOption: Bi.Filter.DynamicOptions | null,
  ) => {
    chartItemProps.value.chartLayoutId = chartLayoutId;
    chartItemProps.value.chartProps = chartProps;
    filterItemProps.value = sourceFilterList;
    filterChartSourceOptions.value = sourceFilterOption;
    modalOpen.filterSelector = true;
  };

  const onFilterModalApply = (sourceFilterList: string[]) => {
    if (chartItemProps.value.chartLayoutId) {
      const component = <any>vm?.$refs[`CustomChartContainerRef-${chartItemProps.value.chartLayoutId}`];
      component[0].filterChartSource = sourceFilterList;
      component[0].fetchOptionGraphById(chartItemProps.value.chartLayoutId);
    } else {
      throw 'missing chart layout id';
    }
    modalOpen.filterSelector = false;
  };

  // Define string format
  // (Filter_GroupName)_(FilterId)_(Filter_Value)_(filter result Id)
  // ตัว (filter result Id) = 0 แปลว่า เอา filter นี้ทั้งหมด
  const mappingDynamicFilerToDropdown = (): Bi.Filter.DynamicOptions => {
    const filters: Bi.Filter.DynamicOptions = { options: [{ id: '', label: '' }] };
    if (dynamicFilterDataList.value.length > 0) {
      filters.options = dynamicFilterDataList.value.map((item) => ({
        id: `${item.filterGroupName}_${item.filterId}_${item.filterValue}_${0}`, // 0 (Zero) define to root filter table
        label: `${item.filterGroupName}/${item.filterValue}`,
        children: item.filterOptions.map((option) => ({
          id: `${item.filterGroupName}_${item.filterId}_${item.filterValue}_${option.value}`,
          label: option.label,
        })),
      }));
    }

    return filters;
  };

  const mappingFilerChartContainerToDropdown = (): Bi.Filter.DynamicOptions => {
    const filters: Bi.Filter.DynamicOptions = { options: [{ id: '', label: '' }] };
    if (sourceListOptions.value.length > 0) {
      filters.options = sourceListOptions.value.map((item) => ({
        id: item.value,
        label: item.label,
      }));
    }

    return filters;
  };

  onMounted(async () => {
    loading.value.layout = true;
    await Promise.all([fetchAnalyticsChartGlobalFilter(), fetchAnalyticsChartTabList()]);

    // Fixed brand 4
    if (vm?.$route.query.brandRef === 'BL6ZLW8PXBXA' || vm?.$route.query.brandRef === 'BNRE0BK41S3B') {
      await fetchAnalyticsDynamicFilter();
      dynamicChartOptions.value = mappingDynamicFilerToDropdown();
      isUseDynamicFilter.value = dynamicChartOptions.value.options.length ? true : false;
      isUseDynamicFilterInChart.value = true;
    }

    filterChartSegmentOptions.value = mappingDynamicFilerToDropdown();

    filterChartSourceOptions.value = mappingFilerChartContainerToDropdown();

    await fetchLayoutAnalyticsChart(pageIdActive.value);

    scrollTopOnLoad();
  });

  return {
    GIRD_LAYOUT,
    seen,
    layout,
    loading,
    pageIdActive,
    tabList,
    tabItemProps,
    computedTabName,
    sourceListOptions,
    sourceList,
    filterChartSourceOptions,
    filterChartSegmentOptions,
    startDate,
    endDate,
    relativeModel,
    isMainFilterChanged,
    modalOpen,
    chartSettingModalProps,
    chartDataInfoModalProps,
    onCustomChartContainerFetched,
    onSeen,
    onAdjustLayout,
    onClickEditTab,
    onCloseEditTabItemModal,
    onClickDateSelect,
    onResetDate,
    onCloseAddAnalyticsChartModal,
    onCloseDataInfoModal,
    onCloseSettingChartModal,
    onApplySetting,
    onCloseAddTabModal,
    onClickDataInfo,
    onClickSettingChart,
    onClickDelete,
    addNewChartFromSelection,
    onClickApplyFilter,
    chartDataInfoExportProps,
    onExportPreview,
    isUseDynamicFilter,
    dynamicChartOptions,
    dynamicFilter,
    dynamicChartFilter,
    filterList,
    filterItemProps,
    onClickSeeMoreFilter: onClickOpenSeeMoreFilterhModal,
    onCloseFilterSelectorModal,
    onFilterModalApply,
  };
}
