import { DateTime } from 'luxon';
import { CDP_DATA_TYPE, COLUMN_PROPERTY_CONST, OPERATOR_TYPE_ADVANCE_LIST, RULE_TYPE } from '../constant/segmentv2';
import {
  AggregateList,
  ColumnList,
  ColumnProperty,
  DataSource,
  DataTypeAggregate,
  DataTypeOperation,
  DataUnitItem,
  OperationList,
  Structure,
  TableList,
  TableRelationGroup,
} from '../types/data-source';
import { RuleItem, RuleSetItem, RuleSetRoot } from '../types/ruleset-payload';
import { SegmentOperator } from '../types/segment';
import { CDP_PREDICATE_EXP_OPERATION_TYPE } from '../constant/virtual-view';
import { h } from 'vue';
import { ColumnSchema } from '../types/column';
import { getOperationById } from './equation';

import { GROUP_MODE } from '../constant/segmentv2';
import { deepClone } from '../../customer-insight/utils';

let currentIndex = -1;

const sourceRelation: TableRelationGroup[] = [];
const rootStructure: Structure[] = [];
const dataTypeOperationList: DataTypeOperation[] = [];
const aggregateOperationList: DataTypeAggregate[] = [];

export function getNextPrefix() {
  currentIndex++;
  return generatePrefix(currentIndex);
}

export function generatePrefix(index: number, isLower: boolean | undefined = false) {
  const alphabet = isLower == true ? 'abcdefghijklmnopqrstuvwxyz' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  const base = alphabet.length;

  do {
    result = alphabet[index % base] + result;
    index = Math.floor(index / base) - 1;
  } while (index >= 0);

  return result;
}

export function resetPrefix() {
  currentIndex = -1;
}

// format for column id (double under scroll __)
// column id = segmentTableId__sourceId__sheetTableName__columnId

export function getColumnFormattedString(formattedString: string) {
  const [segmentTableId, sourceId, sheetTableName, columnId, dataType] = formattedString.split('__');
  return { segmentTableId, sourceId, sheetTableName, columnId, dataType };
}

function getTableById(roots: Structure[], tableId: number): TableList | null {
  return (
    roots
      .flatMap((root) => root.source_list) // Flatten all source lists
      .flatMap((source) => source.table_list) // Flatten all table lists
      .find((table) => table.table_id == tableId) ?? null
  ); // Find the table with the matching tableId
}

export function getTableNameById(tableId: number): string | undefined {
  return rootStructure
    .flatMap((root) => root.source_list) // Flatten all source lists
    .flatMap((source) => source.table_list) // Flatten all table lists
    .find((table) => table.table_id == tableId)?.table_name; // Find the table with the matching tableId;
}

export function getColumnNameById(tableId: number, columnId: number): string | undefined {
  return rootStructure
    .flatMap((root) => root.source_list) // Flatten all source lists
    .flatMap((source) => source.table_list) // Flatten all table lists
    .find((table) => table.table_id == tableId)
    ?.column_list.find((column) => column.column_id == columnId)?.column_alias; // Find the table with the matching tableId
}

export function getDataOperationByDataType(dataType: number): OperationList[] | [] {
  const propertyFound = dataTypeOperationList.find((p) => {
    if (p.data_type_mapping.includes(dataType)) return p;
  });

  // return result
  return propertyFound ? propertyFound.operation_list : [];
}

export function getAggDataOperationByDataType(dataType: number): AggregateList[] | [] {
  const propertyFound = aggregateOperationList.find((p) => {
    if (p.data_type_mapping.includes(dataType)) return p;
  });

  // return result
  return propertyFound ? propertyFound.aggregate_list : [];
}

export function getDataOperationById(dataType: number, id: number): OperationList | null {
  // Find the data type mapping that matches the provided dataType
  const propertyFound = dataTypeOperationList.find((p) => p.data_type_mapping.includes(dataType));

  // If a property is found, search for the operation within its operation list
  if (propertyFound) {
    const operationFound = propertyFound.operation_list.find((o) => o.value === id);

    // Return the found operation, or null if not found
    return operationFound ? operationFound : null;
  }

  // Return null if no matching property is found
  return null;
}

export function getAggDataOperationById(dataType: number, id: number): AggregateList | null {
  // Find the data type mapping that matches the provided dataType
  const propertyFound = aggregateOperationList.find((p) => p.data_type_mapping.includes(dataType));

  // If a property is found, search for the operation within its operation list
  if (propertyFound) {
    const operationFound = propertyFound.aggregate_list.find((o) => o.value === id);

    // Return the found operation, or null if not found
    return operationFound ? operationFound : null;
  }

  // Return null if no matching property is found
  return null;
}

export function getColumnProperty(tableId: number, columnId: number): ColumnProperty {
  const column = deepClone(COLUMN_PROPERTY_CONST);

  if (!rootStructure && !dataTypeOperationList) return column;

  const table = getTableById(rootStructure, tableId);
  if (table) {
    const columnFound = table.column_list.find((c) => c.column_id == columnId);
    if (columnFound) {
      column.column = columnFound;
      const propertyFound = dataTypeOperationList.find((p) => {
        if (p.data_type_mapping.includes(columnFound.data_type)) return p;
      });

      if (propertyFound) column.data_type_operation_list = propertyFound.operation_list;
    }
  }

  // return result
  return column;
}

export function getColumnAggregateProperty(tableId: number, columnId: number): ColumnProperty {
  const column = deepClone(COLUMN_PROPERTY_CONST);

  if (!rootStructure && !aggregateOperationList) return column;

  const table = getTableById(rootStructure, tableId);
  if (table) {
    const columnFound = table.column_list.find((c) => c.column_id == columnId);
    if (columnFound) {
      column.column = columnFound;
      const propertyFound = aggregateOperationList.find((p) => {
        if (p.data_type_mapping.includes(columnFound.data_type)) return p;
      });

      if (propertyFound) column.agg_operation_list = propertyFound.aggregate_list;
    }
  }

  // return result
  return column;
}

export const getColumnUnitDataById = (id: number, dataList: DataUnitItem[] = []): DataUnitItem => {
  const dataInfo: DataUnitItem = { value: 0, name_th: '', name_en: '', description_th: '', description_en: '' };
  const foundData = dataList.find((item) => item.value == id);
  return foundData ? foundData : dataInfo;
};

export const getAggDataOperationByFnName = (name: string, dataType: number): AggregateList | null => {
  // Find the data type mapping that matches the provided dataType
  const propertyFound = aggregateOperationList.find((p) => p.data_type_mapping.includes(dataType));

  // If a property is found, search for the operation within its operation list
  if (propertyFound) {
    const operationFound = propertyFound.aggregate_list.find((o) => o.name_en === name);

    // Return the found operation, or null if not found
    return operationFound ? operationFound : null;
  }

  // Return null if no matching property is found
  return null;
};

// export function getColumnAggregateOperator(tableId: number, columnId: number, source: TableRoot[]): Column | null {
//   const getColumn = getColumnAggregateProperty(tableId, columnId, source);
//   if (getColumn) return getColumn.aggregate_property.find(());
//   return null;
// }

export function getRuleOperator(operator_id: number | null): SegmentOperator | null {
  const operator = OPERATOR_TYPE_ADVANCE_LIST.find((v) => v.id == operator_id);

  return operator ? operator : null;
}

// main
export function setBasicRulesetsLogicalEquation(rulesetRoot: RuleSetRoot, operation: SegmentOperator): string {
  let logicalEquation = '';

  logicalEquation = rulesetRoot.rule_set_list.map((v) => v.set_label).join(` ${operation.key} `);

  return logicalEquation;
}

// rule, aggregate
export function getRuleLogicalEquation(rules: RuleItem[], isLower?: boolean) {
  let logicalEquation = '';
  rules.forEach((r, index) => {
    if (index == 0) {
      logicalEquation += `${generatePrefix(index, isLower)}`;
    } else {
      logicalEquation += ` ${r.logic_operator_desc || ''} ${generatePrefix(index, isLower)}`;
    }
  });

  return logicalEquation;
}

export function isValidOperatorForInputType(dataType: number, operatorId: number): boolean {
  return false;
}

export const CDPDataTypeValidation = (dataType: number) => {
  let isBit = false;
  let isNumber = false;
  let isDateTime = false;
  let isText = false;
  switch (dataType) {
    // Boolean
    case CDP_DATA_TYPE.BIT: // 11
      isBit = true;
      break;
    // Numberic
    case CDP_DATA_TYPE.TINYINT: // 12
    case CDP_DATA_TYPE.SMALLINT: // 13
    case CDP_DATA_TYPE.INT: // 14
    case CDP_DATA_TYPE.BIGINT: // 15
    case CDP_DATA_TYPE.SMALLMONEY: // 16
    case CDP_DATA_TYPE.MONEY: // 17
    case CDP_DATA_TYPE.NUMERIC: // 18
    case CDP_DATA_TYPE.DECIMAL: // 19
    case CDP_DATA_TYPE.FLOAT: // 21
    case CDP_DATA_TYPE.REAL: // 22
      isNumber = true;
      break;

    // Date/Time Types
    case CDP_DATA_TYPE.TIME: // 31
    case CDP_DATA_TYPE.DATE: // 32
    case CDP_DATA_TYPE.DATETIME2: // 33
    case CDP_DATA_TYPE.DATETIME_OFFSET: // 34
    case CDP_DATA_TYPE.DATETIME: // 35
    case CDP_DATA_TYPE.SMALLDATETIME: // 36
      isDateTime = true;
      break;
    // String
    case CDP_DATA_TYPE.CHAR: // 41
    case CDP_DATA_TYPE.VARCHAR: // 42
    case CDP_DATA_TYPE.TEXT: // 43
    case CDP_DATA_TYPE.NCHAR: // 51
    case CDP_DATA_TYPE.NVARCHAR: // 52
    case CDP_DATA_TYPE.NTEXT: // 53
      isText = true;
      break;
  }
  return { isBit, isNumber, isDateTime, isText };
};

export const setToMidnight = (inputDate: DateTime): DateTime => {
  return inputDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

// Component
export const DateTimeInputSlot = (props: any) => {
  let inputValue = 'undefined';
  if (props && props.timezone && props.format && props.value) {
    inputValue = DateTime.fromISO((props.value as string).toString(), { zone: props.timezone })
      .toFormat(props.format as string)
      .toString();
  }
  return h('div', { class: 'time-picker' }, [h('div', { class: 'value-wrp' }, inputValue)]);
};

// <T>: This defines a generic type T that will be inferred from the array passed
export const getDistinct = <T>(value: T, index: number, _self: T[]): boolean => {
  return _self.indexOf(value) === index;
};

// export const getDistinctTableIds = (ruleRoot: RuleSetRoot): (number | undefined)[] => {
//   const tableIds = ruleRoot.rule_set_list.flatMap((v) =>
//     v.rule_list.flatMap((r) => r.l_predicate.flatMap((p) => p.sub_predicate.map((sp) => sp.table_id))),
//   );

//   return tableIds.filter(getDistinct);
// };

export const getDistinctTableIds = (ruleRoot: RuleSetRoot): number[] => {
  const tableIds = ruleRoot.rule_set_list.flatMap((v) =>
    v.rule_list.flatMap((r) => r.l_predicate.flatMap((p) => p.sub_predicate.map((sp) => sp.table_id))),
  );

  // Filter out undefined values and ensure distinct table IDs
  return tableIds.filter((id): id is number => id !== undefined && getDistinct(id, tableIds.indexOf(id), tableIds));
};

// export const getDistinctRulesetTableIds = (ruleset: RuleSetItem): (number | undefined)[] => {
//   const rTableIds = ruleset.rule_list.flatMap((v) => v.r_predicate.flatMap((p) => p.sub_predicate.map((sp) => sp.table_id)));
//   const lTableIds = ruleset.rule_list.flatMap((v) => v.l_predicate.flatMap((p) => p.sub_predicate.map((sp) => sp.table_id)));

//   return rTableIds.concat(lTableIds).filter(getDistinct);
// };

export const getDistinctRulesetTableIds = (ruleset: RuleSetItem): number[] => {
  // const rTableIds = ruleset.rule_list
  //   .concat(ruleset.aggregate_list)
  //   .flatMap((v) => v.r_predicate.flatMap((p) => p.sub_predicate.map((sp) => sp.table_id)));

  const lTableIds = ruleset.rule_list
    .concat(ruleset.aggregate_list)
    .flatMap((v) => v.l_predicate.flatMap((p) => p.sub_predicate.map((sp) => sp.table_id)))
    .filter((id): id is number => id !== undefined);

  // Combine both rTableIds and lTableIds, filter out undefined values, and return only distinct numbers
  return lTableIds.filter(getDistinct);
};

export const setSourceStructureList = (structure: Structure[]) => {
  Object.assign(rootStructure, structure);
};

export const getSourceStructureList = () => rootStructure;

export const getTableIdFromSheetName = (sheetName: string): number => {
  for (const v of rootStructure) {
    for (const s of v.source_list) {
      const table = s.table_list.find((t) => t.table_name === sheetName);
      if (table) {
        return table.table_id; // Return the table_id as soon as it is found
      }
    }
  }

  return 0; // Return 0 if no match is found
};

export const setSourcesRelation = (relations: TableRelationGroup[]) => {
  Object.assign(sourceRelation, relations);
};

export const setDataTypeOperationList = (operationList: DataTypeOperation[]) => {
  Object.assign(dataTypeOperationList, operationList);
};
export const getSourcesRelation = () => sourceRelation;

export const getDataTypeOperationList = () => dataTypeOperationList;

export const setAggregateOperationList = (operationList: DataTypeAggregate[]) => {
  Object.assign(aggregateOperationList, operationList);
};

export const getAggregateOperationList = () => aggregateOperationList;

export const getAllSourceRelation = (): number[] => {
  // Initialize a Set to store unique tableIds
  const allSourceRelation = new Set<number>();

  // Loop through each item in sourceRelation
  sourceRelation.forEach((item) => {
    // Check if there are relations before processing
    if (item.relations.length > 0) {
      // Add the current item's table_id to the set
      allSourceRelation.add(item.table_id);

      // Add each related table_id to the set
      item.relations.forEach((relation) => allSourceRelation.add(relation));
    }
  });
  // Convert the Set to an Array and return as number[]
  return Array.from(allSourceRelation);
};

// Relation
export function getRelatedTableIds(tableId: number, tables: TableRelationGroup[]): number[] {
  const table = tables.find((t) => t.table_id === tableId);
  return table ? table.relations : [];
}

export function canDropColumn(rulesetColumns: ColumnSchema[], newColumn: ColumnSchema, tables: TableRelationGroup[]): boolean {
  if (rulesetColumns.length === 0) {
    return true; // Always allow drop if ruleset is empty
  }

  const relatedTableIds = getRelatedTableIds(newColumn.segmentTableId, tables);

  // Check if the new column is related to all existing columns
  return rulesetColumns.every((column) => relatedTableIds.includes(column.segmentTableId) || column.segmentTableId === newColumn.segmentTableId);
}

export function filterIncompatibleColumns(rulesetColumns: ColumnSchema[], tables: TableRelationGroup[]): ColumnSchema[] {
  return rulesetColumns.filter((column) => {
    const relatedTableIds = getRelatedTableIds(column.segmentTableId, tables);
    return relatedTableIds.length > 0; // Keep only columns with valid relations
  });
}

export function updateRulesetsAfterRemoval(removedRulesetId: number, allRulesets: ColumnSchema[][], tables: TableRelationGroup[]): ColumnSchema[][] {
  return allRulesets.map((rulesetColumns) => filterIncompatibleColumns(rulesetColumns, tables));
}

export function generateUniqueId(prefix = 'id') {
  const randomPart = Math.random().toString(36).substring(2, 6); // Short random string
  const timePart = Date.now().toString(36).slice(-4); // Last 4 digits of timestamp in base 36
  return `${prefix}-${timePart}${randomPart}`;
}

export function getKeyByValue<T extends object>(obj: T, value: T[keyof T]): keyof T | undefined {
  return (Object.entries(obj) as [keyof T, T[keyof T]][]).find(([_, val]) => val === value)?.[0];
}
