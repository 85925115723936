import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr';
import { jwtDecode } from 'jwt-decode';
import authService from '@/services/authentication';

import { useToast } from 'vue-toastification';
import Toast, { ToastProps, ToastStatus } from '../../views/components/toast/vue-hot-toast';

interface JwtPayload {
  brand_ref: string;
  exp: number;
  iat: number;
  iss: string;
  provider_ref: string;
  scope: string;
  sub: string;
  token_id: string;
  type: string;
}

interface NotifyMessage {
  body: string;
  brand_Ref: string;
  groupName: string;
  notificationPayload: string;
  role: string;
  identity: string;
  timeStamp: string;
  title: string;
  topic: string;
  url: string;

  // Notification Options
  // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/API/notifications/NotificationOptions
  // notificationPayload be override title, body, link
  // actions?: NotificationAction[];
  // badge?: string;
  // body?: string;
  // data?: any;
  // dir?: NotificationDirection;
  // icon?: string;
  // image?: string;
  // lang?: string;
  // renotify?: boolean;
  // requireInteraction?: boolean;
  // silent?: boolean | null;
  // tag?: string;
  // timestamp?: EpochTimeStamp;
  // vibrate?: VibratePattern;

  // notificationPayload?: NotificationOptions;
}

interface GroupHandler {
  join(): Promise<Error | null>;
  leave(): Promise<Error | null>;
}

interface ISignalRService {
  notificationEnabled: boolean;
  groupCallback: Record<string, (message: NotifyMessage) => void>;
  start(): Promise<Error | null>;
  disconnect(): void;
  connectionOff(topic: string): void;
  broadcastNotification(topic: string, message: NotifyMessage): Promise<Error | null>;
  sendNotificationToGroup(topic: string, groupName: string, message: NotifyMessage): Promise<Error | null>;
  addVisibilityChangeListener(): void;
  removeVisibilityChangeListener(): void;

  setNotificationEnabled(enabled: boolean): void;
  setGroupCallback(groupName: string, callback: (message: NotifyMessage) => void): void;
  group(groupName: string): GroupHandler;
}

// Methods in HubService
const _CDP_SignalR_Method = {
  START: 'StartCDPSignalRAsync',
  ADD_TO_GROUP: 'AddToGroupAsync',
  REMOVE_FROM_GROUP: 'RemoveFromGroupAsync',
  BROADCAST: 'BroadcastMessageAsync',
  SEND_TO_GROUP: 'SendNotificationToGroupAsync',
};

// SignalR Settings
const _mainTopic = 'cdp-system-notify';
const _connectionString = process.env.BASE_URL_SIGNALR!;

let brandData: JwtPayload | null = null;
const baseGroupList = <string[]>[];

const toast = useToast();
let notification: Notification | null = null;

const reconnectionInterval = [0, 2000, 10000, 30000, 100000]; // yields the default behavior
let connection = new HubConnectionBuilder()
  .withUrl(_connectionString as string)
  .withAutomaticReconnect(reconnectionInterval)
  .configureLogging(getLoggingConfiguration())
  .build();

// Export const
const signalRService: ISignalRService = {
  notificationEnabled: false,
  groupCallback: {},

  async start() {
    return await start();
  },
  disconnect() {
    return disconnect();
  },
  connectionOff(topic: string) {
    return connectionOff(topic);
  },
  async broadcastNotification(topic: string, message: NotifyMessage) {
    return await broadcastNotification(topic, message);
  },
  async sendNotificationToGroup(topic: string, groupName: string, message: NotifyMessage) {
    return await sendNotificationToGroup(topic, groupName, message);
  },
  addVisibilityChangeListener() {
    return addVisibilityChangeListener();
  },
  removeVisibilityChangeListener() {
    return removeVisibilityChangeListener();
  },

  setNotificationEnabled(enabled: boolean) {
    this.notificationEnabled = enabled;
  },
  setGroupCallback(groupName: string, callback: (message: NotifyMessage) => void): void {
    this.groupCallback[groupName] = callback;
  },

  group(groupName: string): GroupHandler {
    return {
      async join() {
        return await joinGroup(groupName);
      },
      async leave() {
        return await leaveGroup(groupName);
      },
    };
  },
};

// Check the environment (you might use a more sophisticated approach in a real application)
function getLoggingConfiguration(): LogLevel {
  const isDevelopment = process.env.BASE_ENV_URL === 'development';

  // Configure logging based on the environment
  if (isDevelopment) {
    return LogLevel.Information; // Log everything in development
  } else {
    return LogLevel.None; // Disable logging in production
  }
}

// Get session
const getTokenProperty = (): JwtPayload | null => {
  if (authService.getLocalBrandData() != null) {
    const brandData = jwtDecode<JwtPayload>(authService.getLocalBrandData()!);
    if (brandData) {
      return brandData;
    }
  }
  return null;
};

// export default function signalRService() {
const start = async (): Promise<Error | null> => {
  connection = new HubConnectionBuilder()
    .withUrl(_connectionString as string)
    .withAutomaticReconnect(reconnectionInterval)
    .configureLogging(getLoggingConfiguration())
    .build();

  await connection
    .start()
    .then(() => {
      if (connection) {
        // Handle connection events
        connection.onclose((error) => {
          if (notification) {
            // Close the web notification if it exists
            notification.close();
          }

          if (error) console.error(`Connection closed due to error: ${error ? error.message : ''}`);
        });

        connection.onreconnecting((error) => {
          console.warn(`Connection lost due to error. Reconnecting: ${error ? error.message : ''}`);
        });

        connection.onreconnected((connectionId) => {
          console.log(`Connection reestablished. Connection ID: ${connectionId || ''}`);
        });

        // Handle Error

        connection.on('HubError', (error: Error) => {
          console.error(`Hub error: ${error ? error.message : ''}`);
        });
      }
    })
    .catch((error: Error) => {
      console.error(`SignalR start failed: ${error.message}`);
      return new Error(`SignalR start failed: ${error.message}`);
    });

  getTokenProperty();
  // Handle api
  // base group to join
  brandData = getTokenProperty();
  registerBaseGroup();
  await initialBasicNotification();
  return null;
};

const registerBaseGroup = () => {
  baseGroupList.length = 0;
  // if (brandData) baseGroupList.push(`cdp-mka-${brandData.brand_ref}`);
};

// Join Sub, Brand_Ref to connection
// StartCDPSignalRAsync(string Brand_Ref, string Identity)
const initialConnectCDPSignalR = async (): Promise<void> => {
  if (connection)
    await connection
      .invoke(_CDP_SignalR_Method.START, getTokenProperty()?.brand_ref, getTokenProperty()?.sub)
      .catch((error) => console.error(_CDP_SignalR_Method.START, error));
};

const initialBasicNotification = async () => {
  // System notification
  if (connection)
    connection.on(_mainTopic, (result: NotifyMessage) => {
      showNotification(result);

      if (result.groupName && signalRService.groupCallback[result.groupName]) {
        signalRService.groupCallback[result.groupName](result);
      }
    });
  await initialConnectCDPSignalR();

  // Loop base group
  for (const groupName of baseGroupList) {
    const error = await joinGroup(groupName);
    if (error) console.log(error);
  }
};

// Add current connection to group
// AddToGroupAsync(string groupName)
const joinGroup = async (groupName: string): Promise<Error | null> => {
  if (connection)
    await connection
      .invoke(_CDP_SignalR_Method.ADD_TO_GROUP, groupName)
      .then(() => {
        console.log('Join group', groupName);
      })
      .catch((error: Error) => {
        console.error(`Error joining group: ${error.message}`);
        return error;
      });
  return null;
};

// Remove current connection from group
// RemoveFromGroupAsync(string groupName)
const leaveGroup = async (groupName: string): Promise<Error | null> => {
  if (connection)
    await connection
      .invoke(_CDP_SignalR_Method.REMOVE_FROM_GROUP, groupName)
      .then(() => {
        delete signalRService.groupCallback[groupName];
        console.log('Leave group');
      })
      .catch((error: Error) => {
        console.error(`Error leaving group: ${error.message}`);
        return error;
      });

  return null;
};

const broadcastNotification = async (topic: string, message: NotifyMessage): Promise<Error | null> => {
  if (connection)
    await connection.invoke(_CDP_SignalR_Method.BROADCAST, topic, message).catch((error: Error) => {
      console.error(`Error invoking SendMessage: ${error.message}`);
      return error;
    });
  return null;
};

const sendNotificationToGroup = async (topic: string, groupName: string, message: NotifyMessage): Promise<Error | null> => {
  if (connection)
    await connection.invoke(_CDP_SignalR_Method.SEND_TO_GROUP, topic, groupName, message).catch((error: Error) => {
      console.error(`Error invoking SendMessage: ${error.message}`);
      return error;
    });
  return null;
};

const disconnect = (): Error | null => {
  if (connection)
    connection
      .stop()
      .then(() => {
        signalRService.groupCallback = {};
      })
      .catch((error: Error) => {
        console.error(`Error stopping connection: ${error.message}`);
        return new Error(`Error stopping connection: ${error.message}`);
      });

  return null;
};

// Stop Listen event
const connectionOff = (topic: string) => {
  if (connection) connection.off(topic);
};

const buildMessage = (message: NotifyMessage): Notification => {
  let notificationOptions: NotificationOptions = {
    body: message.body,
    icon: 'https://portal.chococdp.com/img/CDPIcon.4ff44129.png',
  };

  // Override Notification Options
  if (message.notificationPayload) {
    notificationOptions = JSON.parse(message.notificationPayload);
  }

  notification = new Notification(message.title || 'CDP - Notification', notificationOptions);

  // Event Handler
  // Has Attach Link
  if (message.url != undefined) {
    notification.onclick = (event: Event) => {
      event.preventDefault();
      window.open(message.url, '_blank');
    };
  }
  return notification;
};

const buildToast = (message: NotifyMessage) => {
  if (message.title) {
    const toastOpt: ToastProps = {
      status: ToastStatus.SUCCESS,
      title: message.title,
      description: message.body,
    };

    toast.info(Toast(toastOpt));
  }
};

function showNotification(message: NotifyMessage) {
  try {
    const isUserOnScreen = handleVisibilityChangeHandler();

    if (signalRService.notificationEnabled) {
      if (isUserOnScreen) {
        buildToast(message);
      } else {
        buildMessage(message);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

const handleVisibilityChangeHandler = (): boolean => {
  // Detect tab visibility changes

  if (document.visibilityState === 'visible') {
    return true;
  }
  return false;
};

const addVisibilityChangeListener = (): void => {
  document.addEventListener('visibilitychange', handleVisibilityChangeHandler);
};

const removeVisibilityChangeListener = (): void => {
  document.removeEventListener('visibilitychange', handleVisibilityChangeHandler);
};

export { signalRService, connection, NotifyMessage };
