import { getCurrentInstance, Ref, ref, watch, onBeforeMount, computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  modelValue: number;
  perPages: number;
  totalRows?: number;
  listUnit?: string;
}

export default function usePagination(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { t } = useI18n();

  const currentPage: Ref<number> = ref(1);
  const inputBoxRef: Ref<HTMLInputElement | null> = ref(null);
  const totalPages: Ref<number> = ref(1);

  const paginationInfo = computed(() => {
    const startItem = props.perPages * (currentPage.value - 1);

    return props.totalRows && props.totalRows > 0
      ? `${startItem + 1}-${
          startItem + (currentPage.value * props.perPages <= props.totalRows ? props.perPages : props.totalRows % props.perPages)
        } ${t('component.pagination.of')} ${props.totalRows} ${props.listUnit ? props.listUnit : t('component.pagination.list')} `
      : '0-0 ' + t('component.pagination.of') + ' 0 ' + (props.listUnit ? props.listUnit : t('component.pagination.list')) + ' ';
  });

  const onSelectPage = (page: number) => {
    currentPage.value = page;
  };

  const onSearchPage = () => {
    const pageNumber = parseInt(inputBoxRef.value?.value as string);

    if (pageNumber > 0) {
      currentPage.value = pageNumber;
      (<HTMLInputElement>inputBoxRef.value).value = '';
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const key = event.key.toLowerCase();
    const acceptedKeyList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'enter', 'backspace', 'tab'];

    if (!acceptedKeyList.includes(key)) {
      return event.preventDefault();
    }
  };

  const onKeyUp = (event: KeyboardEvent) => {
    const key = event.key;

    if (key.toLowerCase() == 'enter') {
      return onSearchPage();
    }
  };

  const onClickDoubleLeftArrowButton = () => {
    currentPage.value = 1;
  };

  const onClickLeftArrowButton = () => {
    if (currentPage.value > 1) {
      return currentPage.value--;
    }
  };

  const onClickDoubleRightArrowButton = () => {
    currentPage.value = totalPages.value;
  };

  const onClickRightArrowButton = () => {
    if (currentPage.value < totalPages.value) {
      return currentPage.value++;
    }
  };

  const initTotalPages = () => {
    if (props.totalRows) {
      return (totalPages.value = Math.ceil(props.totalRows / props.perPages));
    }

    return (totalPages.value = 1);
  };

  watch(
    currentPage,
    () => {
      if (currentPage.value > totalPages.value) {
        currentPage.value = totalPages.value;
      }

      vm?.$emit('update:modelValue', currentPage.value);
    },
    { deep: true },
  );

  watch(
    () => props.totalRows,
    () => {
      initTotalPages();
    },
    { deep: true },
  );

  watch(
    () => props.modelValue,
    () => {
      currentPage.value = props.modelValue;
    },
  );

  onBeforeMount(() => {
    currentPage.value = props.modelValue;

    initTotalPages();
  });

  return {
    currentPage,
    inputBoxRef,
    totalPages,
    paginationInfo,
    onSelectPage,
    onSearchPage,
    onKeyUp,
    onKeyDown,
    onClickDoubleLeftArrowButton,
    onClickLeftArrowButton,
    onClickDoubleRightArrowButton,
    onClickRightArrowButton,
  };
}
