export default [
  {
    title: 'main_menu.data_connector',
    customIcon: 'DataConnectorIcon',
    children: [
      {
        title: 'main_menu.data_cconnector_overview',
        route: 'List Rawr',
        path: '/dataConnector',
      },
      {
        title: 'main_menu.data_connector_data_relationship',
        route: 'Data Relation',
        path: '/dataConnector/dataRelationship',
      },
      {
        title: 'main_menu.data_connector_coupon',
        route: 'Coupon',
        path: '/dataConnector/couponManagement',
      },
      {
        title: 'main_menu.data_connector_history',
        route: 'History',
        path: '/dataConnector/excelFileHistory',
      },
    ],
  },
];
