import { TreeGroup, TreeNode, ProcessedEquation } from '../types/segment';
import { generateUniqueId } from './util';

// export function buildEquationTree(expression: string): TreeNode {
//   function parseExpression(expr: string, depth = 0): TreeNode {
//     const tokens = expr.match(/\(|\)|"[^"]+"|'[^']+'|[\w-]+|AND|OR/g) || [];
//     const stack: Group[] = [{ type: 'group', operation: null, children: [], depth }];

//     tokens.forEach((token) => {
//       if (token === '(') {
//         const newGroup: Group = { type: 'group', operation: null, children: [], depth: stack.length };
//         stack[stack.length - 1].children.push(newGroup);
//         stack.push(newGroup);
//       } else if (token === ')') {
//         if (stack.length > 1) {
//           stack.pop();
//         }
//       } else if (token === 'AND' || token === 'OR') {
//         stack[stack.length - 1].operation = token;
//       } else {
//         const value = token.replace(/^['"]|['"]$/g, '');
//         stack[stack.length - 1].children.push({ type: 'term', value, depth: stack.length });
//       }
//     });

//     return stack[0];
//   }

//   return parseExpression(expression);
// }

// export function treeToString(node: TreeNode): string {
//   if (node.type === 'term') {
//     return node.value || '';
//   }

//   if (node.type === 'group' && node.children && node.children.length > 0) {
//     const childrenEquations = node.children.map(treeToString).join(` ${node.operation} `);
//     return `(${childrenEquations})`;
//   }

//   return '';
// }

// export const treeToEquation = (node: TreeNode): string => {
//   if (node.type === 'term') {
//     return node.value;
//   } else {
//     const childEquations = node.children.map(treeToEquation);
//     return `(${childEquations.join(` ${node.operation} `)})`;
//   }
// };

export function optimizeTree(node: TreeNode): TreeNode {
  if (node.type === 'term') {
    return node;
  }
  node.children = node.children?.map(optimizeTree);
  if (node.children?.length === 1) {
    return node.children[0];
  }
  return node;
}

// export function processEquation({ expression = '' }: { expression?: string } = {}): ProcessedEquation {
//   const tree = buildEquationTree(expression);
//   const optimizedTree = optimizeTree(tree);
//   return {
//     tree: optimizedTree,
//     equation: treeToString(optimizedTree),
//   };
// }

function generateRandomEquation(depth = 0, maxDepth = 3): string {
  const operators = ['AND', 'OR'];
  const terms = ['data-term', 'data-value', 'data-data', 'data-info', 'data-item'];

  if (depth >= maxDepth || Math.random() < 0.3) {
    // Generate a random term or a term with hyphen
    if (Math.random() < 0.7) {
      return terms[Math.floor(Math.random() * terms.length)] + (Math.random() < 0.3 ? '-' + Math.random().toString(36).substring(7) : '');
    } else {
      return Math.random().toString(36).substring(7); // random alphanumeric string
    }
  }

  const operator = operators[Math.floor(Math.random() * operators.length)];
  const numChildren = Math.floor(Math.random() * 3) + 2; // 2 to 4 children
  const children = [];

  for (let i = 0; i < numChildren; i++) {
    children.push(generateRandomEquation(depth + 1, maxDepth));
  }

  return `(${children.join(` ${operator} `)})`;
}

export function buildEquationTree(expression: string): TreeNode {
  const parseExpression = (expr: string, depth = 0): TreeNode => {
    const tokens = expr.match(/\(|\)|"[^"]+"|'[^']+'|[\w-]+|AND|OR/g) || [];
    const stack: TreeGroup[] = [{ id: generateUniqueId(), type: 'group', operation: null, children: [], depth }];

    for (const token of tokens) {
      switch (token) {
        case '(':
          const newGroup: TreeGroup = { id: generateUniqueId(), type: 'group', operation: null, children: [], depth: stack.length };
          stack[stack.length - 1].children.push(newGroup);
          stack.push(newGroup);
          break;
        case ')':
          stack.length > 1 && stack.pop();
          break;
        case 'AND':
        case 'OR':
          stack[stack.length - 1].operation = token;
          break;
        default:
          const value = token.replace(/^['"]|['"]$/g, '');
          stack[stack.length - 1].children.push({ id: generateUniqueId(), type: 'term', value, depth: stack.length });
          break;
      }
    }

    return stack[0];
  };

  return parseExpression(expression);
}

export function treeToString(node: TreeNode): string {
  if (node.type === 'term') return node.value || '';
  if (node.type === 'group' && node.children && node.children.length > 0) {
    const childrenEquations = node.children.map(treeToString).join(` ${node.operation} `);
    return `(${childrenEquations})`;
  }
  return '';
}

export function addNode(tree: TreeNode, value: string, operation: 'AND' | 'OR' | null = null, parentId?: string): TreeNode {
  if (parentId) {
    return addNodeToParent(tree, parentId, value, operation);
  }

  const newTerm: TreeNode = { id: generateUniqueId(), type: 'term', value, depth: tree.depth + 1 };

  if (tree.type === 'term') {
    return {
      id: generateUniqueId(),
      type: 'group',
      operation: operation || 'OR',
      children: [tree, newTerm],
      depth: tree.depth,
    };
  }

  if (operation && operation !== tree.operation) {
    tree.children?.push({
      id: generateUniqueId(),
      type: 'group',
      operation: operation,
      children: [newTerm],
      depth: tree.depth + 1,
    });
  } else {
    tree.children?.push(newTerm);
  }

  return tree;
}

function addNodeToParent(tree: TreeNode, parentId: string, value: string, operation: 'AND' | 'OR' | null): TreeNode {
  if (tree.id === parentId && tree.type === 'group') {
    return addNode(tree, value, operation);
  }

  if (tree.type === 'group') {
    tree.children = tree.children?.map((child) => addNodeToParent(child, parentId, value, operation));
  }

  return tree;
}

export function removeGroup(tree: TreeNode, groupId: string): TreeNode | null {
  if (tree.id === groupId) return null;

  if (tree.type === 'group') {
    tree.children = tree.children?.map((child) => removeGroup(child, groupId)).filter((child): child is TreeNode => child !== null);

    if (tree.children?.length === 0) return null;
    if (tree.children?.length === 1 && tree.children[0].type === 'group') return tree.children[0];
  }

  return tree;
}

export function removeChild(tree: TreeNode, childId: string): TreeNode | null {
  if (tree.type === 'group') {
    tree.children = tree.children?.filter((child) => child.id !== childId);

    if (tree.children?.length === 0) return null;
    if (tree.children?.length === 1 && tree.operation !== null) return tree.children[0];
  }

  return tree;
}

export function getOperationById(tree: TreeNode, nodeId: string): 'AND' | 'OR' | null {
  // If the current node's id matches, return its operation
  if (tree.id === nodeId) {
    return tree.type === 'group' ? tree.operation ?? null : null;
  }

  // If the node has children, recursively search them
  if (tree.children && tree.children.length > 0) {
    for (const child of tree.children) {
      const result = getOperationById(child, nodeId);
      if (result !== null) {
        return result;
      }
    }
  }

  // If no matching node is found, return null
  return null;
}

export function getOperationByValue(tree: TreeNode, targetValue: string): 'AND' | 'OR' | null {
  // Helper function to find the parent operation by searching the children
  function searchNode(node: TreeNode, parentOperation: 'AND' | 'OR' | null): 'AND' | 'OR' | null {
    // If the current node is a term and its value matches the target value, return the parent's operation
    if (node.type === 'term' && node.value === targetValue) {
      return parentOperation;
    }

    // If the node has children, recursively search them
    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        const result = searchNode(child, node.operation ?? null);
        if (result !== null) {
          return result;
        }
      }
    }

    // If no matching node is found, return null
    return null;
  }

  // Start the recursive search from the root node
  return searchNode(tree, null);
}

export function getValueByNodeId(tree: TreeNode, nodeId: string): string | null {
  // If the current node's id matches the nodeId, return its value (if available)
  if (tree.id === nodeId) {
    return tree.value || null;
  }

  // If the node has children, recursively search them
  if (tree.children && tree.children.length > 0) {
    for (const child of tree.children) {
      const result = getValueByNodeId(child, nodeId);
      if (result !== null) {
        return result;
      }
    }
  }

  // If no matching node is found, return null
  return null;
}

export function processEquation(expression: string): ProcessedEquation {
  // if (!expression) {
  //   expression = generateRandomEquation(undefined, 2);
  // }

  let tree = optimizeTree(buildEquationTree(expression));

  const updateTree = () => ({
    tree,
    equation: treeToString(tree),
  });

  return {
    tree,
    equation: treeToString(tree),
    addNode: (value: string, operation: 'AND' | 'OR' | null = null, parentId?: string) => {
      tree = addNode(tree, value, operation, parentId);
      return updateTree();
    },
    removeGroup: (groupId: string) => {
      tree = removeGroup(tree, groupId) || { id: generateUniqueId(), type: 'group', operation: null, children: [], depth: 0 };
      return updateTree();
    },
    removeChild: (childId: string) => {
      tree = removeChild(tree, childId) || { id: generateUniqueId(), type: 'group', operation: null, children: [], depth: 0 };
      return updateTree();
    },
  };
}
