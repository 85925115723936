import { jwtDecode, JwtPayload } from 'jwt-decode';

export default function useDecodeToken() {
  const decodeToken = () => {
    let tokenDecode: any = null;
    const token = JSON.parse(localStorage.getItem('brandData') || 'null')?.[0]?.accessToken;
    if (token) {
      tokenDecode = jwtDecode<JwtPayload>(token);
    }
    return tokenDecode;
  };

  const decodeParamToken = () => {
    let tokenDecode = null;
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      tokenDecode = jwtDecode<JwtPayload>(token);
    }
    return tokenDecode;
  };

  return {
    decodeToken,
    decodeParamToken,
  };
}
