<template>
  <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1620_34386)">
      <path
        d="M14.6311 22.4048C15.3852 22.4048 15.9965 21.8237 15.9965 21.1068C15.9965 20.39 15.3852 19.8089 14.6311 19.8089C13.877 19.8089 13.2656 20.39 13.2656 21.1068C13.2656 21.8237 13.877 22.4048 14.6311 22.4048Z"
      />
      <path
        d="M22.7434 17.4869L16.5049 17.5774C15.8435 17.5869 15.5228 18.3538 15.9989 18.792L19.1657 21.7117C19.4613 21.9832 19.9323 21.9761 20.218 21.6974L23.2896 18.6872C23.7506 18.2347 23.4073 17.4798 22.7434 17.4893V17.4869Z"
      />
      <path
        d="M12.5864 21.214C12.5864 20.5567 12.0252 20.0232 11.3337 20.0232H6.2728C5.02511 20.0209 4.00791 19.0516 4.00541 17.8727C4.00541 16.4391 4.50399 15.0482 5.39842 13.9146C5.68904 14.1814 6.00222 14.4291 6.33293 14.6529C7.74097 15.6103 9.39204 16.1176 11.0882 16.1318C11.1208 16.1318 11.1533 16.1318 11.1859 16.1318C11.206 16.1318 11.2235 16.1318 11.241 16.1318C11.3112 16.1318 11.3838 16.1295 11.454 16.1271C11.4891 16.1271 11.5241 16.1271 11.5592 16.1223C11.8899 16.108 12.2181 16.0771 12.5489 16.0247C14.24 15.7579 15.7683 15.0221 16.9734 13.9123C17.8052 14.9649 18.2862 16.2414 18.3514 17.5655H20.8618C20.7741 15.1602 19.6692 12.8692 17.7927 11.2426C17.7476 11.2021 17.6975 11.1687 17.6474 11.1354C17.7801 10.5281 17.8202 9.88749 17.7526 9.22781C17.4494 6.29377 14.9716 3.91701 11.8849 3.61456C7.92387 3.22637 4.58165 6.16993 4.58165 9.85653C4.58165 10.2947 4.62926 10.7234 4.71945 11.1354C4.66934 11.1687 4.61923 11.2021 4.57414 11.2426C2.62493 12.943 1.5 15.3555 1.5 17.8751C1.50752 20.3662 3.64463 22.3976 6.2703 22.4048H11.3337C12.0252 22.4048 12.5864 21.8713 12.5864 21.214ZM11.3838 5.97227C13.4633 6.06515 15.177 7.69173 15.2772 9.66839C15.39 11.8927 13.5285 13.7384 11.216 13.7527C11.1934 13.7527 11.1684 13.7527 11.1458 13.7527C8.90599 13.7313 7.08957 11.9951 7.08957 9.86129C7.08957 7.72745 9.03627 5.86748 11.3838 5.97227Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1620_34386">
        <rect width="22" height="19.9048" transform="translate(1.5 2.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
