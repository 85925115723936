import { watch, Ref, ref, onMounted, nextTick, toRaw, computed, toRefs, onUpdated, shallowRef, reactive } from 'vue';
import * as echarts from 'echarts';
import type { EChartsOption } from 'echarts';

interface Props {
  rawData?: Charts.ChartsProps.RawData;
  colorList: Charts.ChartsProps.ColorItem[];
  options: EChartsOption;
  height?: number | string;
  width?: number | string;
  responsiveWidth?: boolean;
  responsiveHeight?: boolean;
  eventType?: string;
  function?: Function;
}

export default function (props: Props) {
  const chart = shallowRef();
  const id = ref();

  const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  id.value = uid();

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 5,
    totalRows: 0,
  });

  const resolveHeight = computed(() => {
    return props.responsiveHeight ? '100%' : typeof props.height === 'number' ? `${props.height}px` : props.height;
  });

  const resolveWidth = computed(() => {
    return props.responsiveWidth ? '100%' : typeof props.width === 'number' ? `${props.width}px` : props.width;
  });

  const transformedData = props.rawData?.data || []; // Provide a default empty array

  const xAxisData = [...new Set(transformedData.flatMap((item: any) => item.xaxis))] as string[];
  const legendData = [...new Set(transformedData.map((item: any) => item.data))] as string[];

  const option: Ref<EChartsOption> = ref({});

  const init = () => {
    const dom = document.getElementById(id.value) as HTMLElement;
    if (!dom) return;

    chart.value = echarts.init(dom);

    // คำนวณค่า total ของแต่ละ stack ในแต่ละจุดของแกน X
    const totalPerStack = transformedData.reduce((totals: any, item: any) => {
      if (!totals[item.stack]) {
        totals[item.stack] = [];
      }

      if (Array.isArray(item.yaxis)) {
        item.yaxis.forEach((value: number, index: number) => {
          if (!totals[item.stack][index]) {
            totals[item.stack][index] = 0;
          }

          totals[item.stack][index] += value;
        });
      }

      return totals;
    }, {});

    // เปลี่ยนข้อมูลในแต่ละ stack เป็นเปอร์เซ็นต์ตามค่า total ของ stack เดียวกัน
    const seriesData = transformedData?.map((item: any, index: number) => {
      return {
        name: item.data,
        type: 'bar' as const,
        stack: item.stack,
        itemStyle: {
          color: props.colorList.filter((color: any) => color.colorName === item.data)[0]?.hexColor || '#000000',
        },
        data: item.yaxis.map((value: number, index: number) => {
          return (value / totalPerStack[item.stack][index].toFixed(2)) * 100;
        }),
      };
    });

    // ฟังก์ชันคำนวณเปอร์เซ็นต์ใหม่สำหรับซีรีส์ที่ยังแสดงผลอยู่
    function recalculatePercent(selectedLegend: any) {
      const visibleStacks = transformedData.filter((item: any) => selectedLegend[item.data]);

      const totalPerStack = visibleStacks.reduce((totals: any, item: any) => {
        if (!totals[item.stack]) {
          totals[item.stack] = [];
        }
        item.yaxis.forEach((value: number, index: number) => {
          if (!totals[item.stack][index]) {
            totals[item.stack][index] = 0;
          }
          totals[item.stack][index] += value; // รวมค่าของ stack เดียวกันในตำแหน่งเดียวกัน
        });
        return totals;
      }, {});

      // คำนวณข้อมูลใหม่เป็นเปอร์เซ็นต์
      const updatedSeriesData = visibleStacks.map((item: any) => {
        return {
          name: item.data,
          type: 'bar' as const,
          stack: item.stack,
          data: item.yaxis.map((value: number, index: number) => {
            return (value / totalPerStack[item.stack][index]) * 100; // เปอร์เซ็นต์ใหม่
          }),
        };
      });

      option.value.series = updatedSeriesData;
    }

    option.value = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params: any) {
          let result = `${params[0].axisValue}<br/>`;

          const stackGroups: { [key: string]: string[] } = {};

          params.forEach((item: any) => {
            const stack = item.seriesName;
            const dataValue = item.value.toFixed(2);
            const seriesInfo = `${item.marker} ${item.seriesName}: ${dataValue}%`;

            if (!stackGroups[stack]) {
              stackGroups[stack] = [];
            }
            stackGroups[stack].push(seriesInfo);
          });

          Object.keys(stackGroups).forEach((key) => {
            result += `<b>Stage ${key}:</b><br/>`;
            result += stackGroups[key].join('<br/>') + '<br/>';
          });

          return result;
        },
      },
      legend: {
        data: legendData,
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: 100,
        axisLabel: {
          formatter: '{value}%',
        },
      },
      series: seriesData,
    };

    chart.value.on('legendselectchanged', function (event: any) {
      const selected = event.selected;

      recalculatePercent(selected);
    });

    try {
      let initResize = false;

      setTimeout(() => {
        initResize = true;
        chart.value.resize();
        option.value && chart.value.setOption(option.value);
      }, 1000); // Delay initialization to ensure proper sizing

      new ResizeObserver(() => {
        if (initResize) {
          chart.value.resize();
          option.value && chart.value.setOption(option.value);
        }
      }).observe(dom);
    } catch (e) {
      // Handle the exception, especially when using with <transition> in Vue
    }
  };

  onMounted(() => {
    init();
  });

  watch(
    () => option.value,
    () => {
      if (chart.value) chart.value.setOption(toRaw(option.value));
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch([() => props.height, () => props.width], () => {
    setTimeout(() => {
      chart.value.resize();
    }, 100);
  });

  watch(
    () => option.value,
    () => {
      let totalXAxisData = 0;

      if (option.value.xAxis && Array.isArray(option.value.xAxis)) {
        option.value.xAxis.forEach((xAxisItem) => {
          // ตรวจสอบว่าประเภทของ xAxis เป็น 'category' และมี 'data' property
          if (xAxisItem.type === 'category' && 'data' in xAxisItem) {
            // ทำการ cast xAxisItem ให้เป็น object ที่มี property data
            const xAxisCategory = xAxisItem as { data: string[] };
            totalXAxisData += xAxisCategory.data.length;
          }
        });
      }

      pagination.perPage = totalXAxisData;
      pagination.totalRows = totalXAxisData;
    },
    { immediate: true },
  );

  return { id, resolveHeight, resolveWidth, pagination };
}
