export default {
  churn_rate: 'Churn Rate',
  churn_rate_detail_1: 'Customer churn rate is calculated',
  churn_rate_detail_2:
    'by determining the percentage of customers who have stopped using a company’s product or service within a specific time frame, compared to the total number of customers at the beginning of that time frame.',
  churn_rate_detail_3: 'The result, expressed as a percentage, is known as the "customer churn rate.',
  churn_rate_detail_4: 'There are 2 calculation formulas that can be used :',
  churn_rate_detail_5: 'ศึกษาเพิ่มเติมให้เหมาะสมกับธุรกิจ',

  standard_formula_detail: 'For calculations using only 2 values, it`s simple and suitable for startups with uncomplicated data. It can be used for daily, monthly, quarterly, or annual calculations.',
  adjusted_formula_detail: 'A calculation formula that incorporates new customers to provide a more accurate percentage, suitable for businesses with more complex data. It can be used for daily, monthly, quarterly, or annual calculations.',
};
