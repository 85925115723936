export default function () {
  const fetchChartSetListModel: API.RequestModel<Bi.BiChartSetList.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/SetList`,
    method: 'GET',
    payload: {
      PageId: 1,
      Limit: 1000,
      ModuleId: null!,
    },
  };

  const fetchChartAddModel: API.RequestModel<Bi.BiChartAdd.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/Add`,
    method: 'POST',
    payload: {
      PageId: null!,
      Charts: [],
    },
  };

  const fetchBiChartDataModel: API.RequestModel<Bi.BiChartData.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/Data`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      Filters: [],
    },
  };

  const fetchBiChartSettingInfoModel: API.RequestModel<Bi.BiChartSetting.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/SettingInfo`,
    method: 'GET',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
    },
  };

  const fetchBiChartSettingEditModel: API.RequestModel<Bi.BiChartSetting.Edit.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/SettingEdit`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartId: null!, // From chart_type_list => is_default = true
      Chart_Color_List: [],
    },
  };

  const fetchBiChartDataInfoModel: API.RequestModel<Bi.BiChartDataInfo.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/DataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
  };

  const fetchBiChartGlobalFilterListModel: API.RequestModel<Record<string, never>> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/GlobalFilterList`,
    method: 'GET',
    payload: {},
  };

  const fetchBiChartDynamicFilterListModel: API.RequestModel<Record<string, never>> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/DynamicFilterList`,
    method: 'GET',
    payload: {},
  };

  const fetchBiChartExportDataInfoModel: API.RequestModel<Bi.BiChartDataInfo.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/ExportDataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
    responseType: 'blob',
  };

  return {
    fetchChartSetListModel,
    fetchChartAddModel,
    fetchBiChartDataModel,
    fetchBiChartSettingInfoModel,
    fetchBiChartSettingEditModel,
    fetchBiChartDataInfoModel,
    fetchBiChartGlobalFilterListModel,
    fetchBiChartDynamicFilterListModel,
    fetchBiChartExportDataInfoModel,
  };
}
