import { resolveGenderTypeTh } from '@/constants/components/chart/chart-infographic';
import { HEATMAP, HORIZONTAL_BAR, RADAR, GAUGE, GEOMAP, INFOGRAPHIC } from '@/constants/components/chart/base-chart';
import { EChartsOption } from 'echarts';
import { toRaw } from 'vue';
import { chartTypes } from '@/constants/components/chart/chart-types';

interface ExtraTopLegendItem {
  label: string;
  value: number;
  color: string;
}

export function chartsPropsToEChartsOptionsMapper(
  chartType: Charts.ChartsProps.ChartType,
  rawData: Charts.ChartsProps.RawData,
  colorList: Charts.ChartsProps.ColorItem[],
  options: EChartsOption,
): EChartsOption {
  const newOption: EChartsOption = Object.assign({}, options);
  switch (chartType) {
    case chartTypes.PIE: {
      let presetSeries: any = [];
      presetSeries = [
        {
          // TODO: avoid magic number
          ...(Array.isArray(newOption.series) ? newOption.series[0] : newOption.series),
          data: rawData.data.map((el: any, index: number) => ({
            name: el.data,
            value: el.value,
            itemStyle: {
              color: colorList[index].hexColor,
            },
          })),
        },
      ];
      newOption.series = presetSeries;

      return newOption;
    }
    case chartTypes.STRAIGHT_LINE:
    case chartTypes.LINE: {
      let presetColor: any = [];
      presetColor = [...colorList.map((color) => color.hexColor)];
      newOption.color = presetColor;

      let presetXAxis: any = {};
      // TODO: avoid magic number
      presetXAxis = {
        ...newOption.xAxis,
        data: rawData.data[0].xaxis,
        name: rawData.data[0].xaxis_label,
      };
      newOption.xAxis = presetXAxis;

      let presetYAxis: any = {};
      // TODO: avoid magic number
      presetYAxis = {
        ...newOption.yAxis,
        name: rawData.data[0].yaxis_label,
      };
      newOption.yAxis = presetYAxis;

      const presetSeries: any = [];
      rawData.data.forEach((rawDataItem: any) => {
        presetSeries.push({
          // TODO: avoid magic number
          ...(Array.isArray(newOption.series) ? newOption.series[0] : newOption.series),
          name: rawDataItem.data,
          type: chartTypes.LINE,
          data: toRaw(rawDataItem.yaxis),
        });
      });
      newOption.series = presetSeries;

      return newOption;
    }
    case chartTypes.HORIZONTAL_BAR: {
      let presetColor: any = [];
      presetColor = [...colorList.map((color) => color.hexColor)];
      newOption.color = presetColor;

      let presetYAxis: any = {};
      presetYAxis = {
        ...newOption.yAxis,
        data: rawData.data[HORIZONTAL_BAR.RAW_DATA_INDEX].yaxis,
        name: rawData.data[HORIZONTAL_BAR.RAW_DATA_INDEX].yaxis_label,
      };
      newOption.yAxis = presetYAxis;

      let presetXAxis: any = {};
      presetXAxis = {
        ...newOption.xAxis,
        name: rawData.data[HORIZONTAL_BAR.RAW_DATA_INDEX].xaxis_label,
      };
      newOption.xAxis = presetXAxis;

      const presetSeries: any = [];
      rawData.data.forEach((rawDataItem: any) => {
        presetSeries.push({
          ...(Array.isArray(newOption.series) ? newOption.series[HORIZONTAL_BAR.SERIES_INDEX] : newOption.series),
          name: rawDataItem.data,
          type: chartTypes.BAR,
          data: toRaw(rawDataItem.xaxis),
        });
      });
      newOption.series = presetSeries;

      return newOption;
    }
    case chartTypes.VERTICAL_BAR:
    case chartTypes.VERTICAL_STACK_BAR: {
      let presetColor: any = [];
      presetColor = [...colorList.map((color) => color.hexColor)];
      newOption.color = presetColor;

      let presetXAxis: any = {};
      // TODO: avoid magic number
      presetXAxis = { ...newOption.xAxis, data: rawData.data[0].xaxis, name: rawData.data[0].xaxis_label };
      newOption.xAxis = presetXAxis;

      let presetYAxis: any = {};
      presetYAxis = {
        ...newOption.yAxis,
        name: rawData.data[0].yaxis_label,
      };
      newOption.yAxis = presetYAxis;

      const presetSeries: any = [];
      rawData.data.forEach((rawDataItem: any) => {
        presetSeries.push({
          // TODO: avoid magic number
          ...(Array.isArray(newOption.series) ? newOption.series[0] : newOption.series),
          name: rawDataItem.data,
          stack: rawDataItem.stack,
          data: rawDataItem.yaxis,
        });
      });
      newOption.series = presetSeries;

      return newOption;
    }
    case chartTypes.HEATMAP: {
      let presetXAxis: any = {};
      presetXAxis = {
        ...newOption.xAxis,
        data: rawData.data[HEATMAP.X_AXIS_INDEX].xaxis,
      };
      newOption.xAxis = presetXAxis;

      let presetYAxis: any = {};
      presetYAxis = {
        ...newOption.yAxis,
        data: rawData.data[HEATMAP.Y_AXIS_INDEX].yaxis,
      };
      newOption.yAxis = presetYAxis;

      let presetVisualMap: any = {};
      presetVisualMap = {
        ...newOption.visualMap,
        min: rawData.data[HEATMAP.RAW_DATA_INDEX].min,
        max: rawData.data[HEATMAP.RAW_DATA_INDEX].max,
        inRange: {
          color: colorList[HEATMAP.COLOR_INDEX].hexColor,
          colorLightness: [HEATMAP.COLOR_THRESHOLD_TOP, HEATMAP.COLOR_THRESHOLD_BOTTOM],
        },
      };
      newOption.visualMap = presetVisualMap;

      const presetSeries: any = [];
      rawData.data.forEach((rawDataItem: any) => {
        presetSeries.push({
          ...(Array.isArray(newOption.series) ? newOption.series[HEATMAP.SERIES_INDEX] : newOption.series),
          name: rawDataItem.dataname,
          data: rawDataItem.datalist,
        });
      });
      newOption.series = presetSeries;

      return newOption;
    }
    case chartTypes.RADAR: {
      /* map color */
      let presetColor: any = [];
      presetColor = [...colorList.map((color) => color.hexColor)];
      newOption.color = presetColor;

      /* map indicator */
      newOption.radar = {
        indicator: rawData.data[RADAR.RAW_DATA_INDEX].indicator.map((el: { name: string; max: number }) => ({
          name: el.name,
          max: el.max,
        })),
      };

      /* map series */
      newOption.series = [
        {
          name: '',
          type: 'radar',
          data: rawData.data[RADAR.RAW_DATA_INDEX].data.map((el: { name: string; value: number }) => el),
        },
      ];

      return newOption;
    }
    case chartTypes.GAUGE: {
      /* map color */
      let presetColor: any = [];
      presetColor = [...colorList.map((color) => color.hexColor)];
      newOption.color = presetColor;

      /* map series */
      let presetSeries: any = [];
      presetSeries = options.series;
      presetSeries.map((item: any) => {
        item.itemStyle = { color: presetColor[GAUGE.RAW_DATA_INDEX] };
        item.detail.formatter = (value: number) => value.toFixed(2);
        item.max = rawData.data[GAUGE.RAW_DATA_INDEX]?.max || 100;
        item.data = [
          {
            name: rawData.data[GAUGE.RAW_DATA_INDEX].name || '',
            value: rawData.data[GAUGE.RAW_DATA_INDEX].value || 0,
          },
        ];
      });

      newOption.series = presetSeries;
      return newOption;
    }
    case chartTypes.GEOMAP: {
      /* map color */
      let presetColor: any = [];
      presetColor = [...colorList.map((color) => color.hexColor)];
      newOption.color = presetColor;

      let maxValue = 0;

      const values = rawData.data.map((rawDataItem: any) => rawDataItem.value);

      if (values.length > 0) {
        maxValue = Math.max(...values);
      }

      newOption.title = [
        {
          text: 'ประเทศไทย',
          left: 'right',
        },
      ];

      newOption.tooltip = [
        {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
        },
      ];

      newOption.visualMap = [
        {
          left: 'right',
          min: 0,
          max: maxValue,
          inRange: {
            color: presetColor,
            colorLightness: [GEOMAP.COLOR_THRESHOLD_TOP, GEOMAP.COLOR_THRESHOLD_BOTTOM],
          },
          text: ['High', 'Low'],
          calculable: false,
        },
      ];

      newOption.series = [
        {
          name: 'ประเทศไทย',
          type: 'map',
          roam: true,
          map: 'Thailand',
          emphasis: {
            label: { show: true },
          },
          zoom: 0.25,
          data: rawData.data.map((rawDataItem: any) => ({
            name: rawDataItem.name,
            value: rawDataItem.value,
          })),
        },
      ];

      return newOption;
    }
    default: {
      return newOption;
    }
  }
}

//TODO: define type of rawData Top10Customer Type both response and component state
export function rawDataToCustomerBannerMapper(rawData: Charts.ChartsProps.RawData): ChartBanner.Customer[] {
  return rawData.data.map((el: any) => ({
    imageUrl: el.image,
    firstname: el.name.split(' ')[0],
    lastname: el.name.split(' ')[1],
    email: el.description,
    isVerified: el.verify,
    balancePoint: el.left_value,
    spending: el.right_value,
    leftTitle: el.left_title,
    rightTitle: el.right_title,
  })) as ChartBanner.Customer[];
}

export function rawDataToChartsInfoGraphic(
  rawData: Charts.ChartsProps.RawData,
  colorList: Charts.ChartsProps.ColorItem[],
  options: any,
): Infographic.Item[] {
  const sumValue = rawData.data
    .filter((item: { type: string; value: number }) => item.type !== INFOGRAPHIC.RAW_DATA_NULL)
    .reduce((previousValue: number, currentValue: { type: string; value: number }) => previousValue + currentValue.value, 0);
  return <Infographic.Item[]>rawData.data
    .map((el: { type: string; value: number; suffix: string }, index: number) => {
      if (el.type !== INFOGRAPHIC.RAW_DATA_NULL) {
        return {
          type: el.type,
          name: resolveGenderTypeTh(el.type, options),
          percent: ((el.value / sumValue) * 100).toFixed(2).toLocaleString(),
          value: el.value,
          itemStyle: {
            color: colorList[index].hexColor,
          },
          suffix: el.suffix,
        };
      }
    })
    .filter((item: any) => item !== undefined);
}

// Helper function to rename all keys in each object of the data array with a suffix
const renameAllKeysWithSuffix = (dataArray: Record<string, any>[], chartLayoutId: number): Record<string, any>[] => {
  return dataArray.map((obj: any) => {
    const newObj: Record<string, any> = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[`${key}_${chartLayoutId}`] = obj[key];
      }
    }
    return newObj;
  });
};

// Function to transform raw data to chart table columns
export function rawDataToChartTableColumn(rawData: Charts.ChartsProps.RawData, chartLayoutId: number): ChartTable.TableColumn[] {
  return <ChartTable.TableColumn[]>rawData.data[0].column.map((el: Record<string, any>) => ({
    key: `${el.column_key as string}_${chartLayoutId}`,
    label: el.column_value,
    columnType: el.column_type,
    columnTypeDesc: el.column_type_desc,
    sortable: true,
  }));
}

// Function to transform raw data to chart table data and rename all keys with a suffix
export function rawDataToChartTableData(rawData: Charts.ChartsProps.RawData, chartLayoutId: number): Record<string, any>[] {
  const data = <Record<string, any>[]>rawData.data[0].data;
  return renameAllKeysWithSuffix(data, chartLayoutId);
}
