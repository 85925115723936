import { computed, reactive, Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
// import saveAs from 'file-saver';

import router from '@/router';
// import api from '@/services/api';

import useConvertDate from '@/utils/hooks/useConvertDate';
// import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useSelectRow from '@/views/components/table/base-table/hooks/useSelectRowV2';

import { mapToColumnsObject } from '@/views/modules/segment-v2/utils';
import segmentV2Model from '@/models/segment-v2/segment';

import { ResultListType, SegmentType } from '@/constants/modules/segment-v2/segment-result';

import api from '@/services/api';

export default function (
  resultListType: ResultListType,
  loading: Ref<boolean>,
  segment: SegmentResult.Segment,
  breadcrumbList: Ref<BreadCrumbList.BreadCrumb[]>,
) {
  const { t } = useI18n();

  // const { isLoading } = useLoading();

  const { openDefaultErrorModal, openErrorModal } = useValidationModal();

  const { fetchSegmentResultModel, fetchSegmentDataModel } = segmentV2Model();

  const { convertDate } = useConvertDate();
  const lastUpdate: Ref<string> = ref('');
  let computeLastUpdateInterval: number = null!;
  // const setLastUpdateIntervalRealtime = (updateDate: string) => {
  //   const currentDateTime = new Date().getTime();
  //   const resultDate = new Date(updateDate).getTime() + (currentDateTime - new Date(updateDate).getTime());

  //   if (computeLastUpdateInterval !== null) clearInterval(computeLastUpdateInterval);
  //   computeLastUpdateInterval = window.setInterval(() => {
  //     lastUpdate.value = convertDate(new Date(resultDate).toISOString());
  //   }, 1000);
  // };
  const setLastUpdateInterval = (updateDate: string) => {
    const addHours = new Date(updateDate).getTime() + 7 * 60 * 60 * 1000;
    const resultDate = new Date(addHours).toISOString();

    if (computeLastUpdateInterval !== null) clearInterval(computeLastUpdateInterval);
    computeLastUpdateInterval = window.setInterval(() => {
      lastUpdate.value = convertDate(resultDate);
    }, 1000);
  };

  // TODO fetch data
  function refreshBatch() {
    loading.value = true;
    controllers.push(new AbortController());
    for (let i = 0; i < controllers.length - 1; i++) controllers[i].abort();
    fetchSegmentDataModel.payload.SegmentId = Number(router.currentRoute.value.params.segmentId);
    api
      .apiRequest(fetchSegmentDataModel)
      .then((res) => {
        if (res.status == 'success') {
          fetchSegmentResult();
        }
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {});
  }

  const searchText: Ref<string> = ref('');
  const controllers: AbortController[] = [];
  let searchDebounceTimeout: ReturnType<typeof setTimeout> = null!;

  const selectedRowDesc = computed(() => {
    if (pagination.totalRows == 0) return '';
    if (includeOrExcludeList.value.length > 0) {
      return (
        t('segment.components.save_custom_dialog.table.segments_inbox_before') +
        selectedRowCount.value +
        t('segment.components.save_custom_dialog.table.segments_inbox_after')
      );
    }
    if (isSelectedAll.value == false && includeOrExcludeList.value.length > 0) {
      return includeOrExcludeList.value.length;
    }
    return t('segment.components.save_custom_dialog.table.clear_select');
  });
  const selectedRowCount = computed(() => {
    if (isSelectedAll.value == true) {
      return pagination.totalRows - includeOrExcludeList.value.length;
    } else {
      return includeOrExcludeList.value.length;
    }
  });
  function clearSelectedRow() {
    if (isSelectedAll.value == true && includeOrExcludeList.value.length == 0) {
      isSelectedAll.value = false;
      const thisRow = document.querySelectorAll('tbody tr');
      thisRow.forEach((el: Element) => {
        (<HTMLInputElement>el).removeAttribute('style');
      });
    }
  }

  // const tableId: Ref<number> = ref(0);
  // const dataSourceDisplay: Ref<PreviewSegment.DatasourceDisplay> = ref(null!);
  const columns: Ref<DynamicTable.Column[]> = ref([]);
  const dataList: Ref<Record<string, any>[]> = ref([]);
  const uniqueKey: Ref<string> = ref('');
  function findUniqueKey(_columns: PreviewSegment.DatasourceDisplayColumns[]): void {
    const found = _columns.find((el: PreviewSegment.DatasourceDisplayColumns) => el.is_primary_key === true);
    if (found) {
      uniqueKey.value = found.key;
    } else {
      return openErrorModal('ไม่พบ unique key', 'ไม่พบ unique key', 'ตกลง');
    }
  }

  const { includeOrExcludeList, isSelectedAll, onSelectedAll, onSelectedRow } = useSelectRow(
    dataList,
    uniqueKey,
    ref(resultListType == ResultListType.Preview),
  );

  const ordering: SegmentResult.TableOrdering = reactive({
    seq: 0,
    key: '',
    direction: '',
  });
  function onSort(sortedColumn: { key: string; direction: string }) {
    ordering.seq = 1;
    ordering.key = sortedColumn.key;
    ordering.direction = sortedColumn.direction;

    pagination.currentPage = 1;
    fetchSegmentResult();
  }
  const paginationOptions: Ref<number[]> = ref([5, 10, 15, 20]);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  function fetchSegmentResult() {
    loading.value = true;
    controllers.push(new AbortController());
    for (let i = 0; i < controllers.length - 1; i++) controllers[i].abort();

    fetchSegmentResultModel.payload.Ordering = ordering.seq ? [ordering] : [];
    fetchSegmentResultModel.payload.Segment_Id = Number(router.currentRoute.value.params.segmentId);
    fetchSegmentResultModel.payload.Filters = [];
    fetchSegmentResultModel.payload.Page = pagination.currentPage;
    fetchSegmentResultModel.payload.Limit = pagination.perPage;
    fetchSegmentResultModel.payload.Search_Value = searchText.value;

    api
      .apiRequest(fetchSegmentResultModel)
      .then((res) => {
        if (res.data.segment.status == 2) {
          segment.segment_id = res.data.segment.segmentid;
          segment.segment_name = res.data.segment.segment_name;
          segment.folder_id = res.data.segment.folderid;
          segment.folder_name = res.data.segment.foldername;
          segment.update_date = res.data.segment.updated_dt;
          segment.segment_type_id = res.data.segment.segment_type_id;
          segment.is_active_campaign = res.data.segment.is_active_campaign;
          const tmpColumns: SegmentResult.DatasourceDisplayColumns[] = res.data.columns.map(
            (item: Partial<SegmentResult.DatasourceDisplayColumns>, index: number) => mapToColumnsObject(item, index),
          );
          columns.value = tmpColumns;
          dataList.value = res.data.datas;
          pagination.totalRows = res.data.filtered_record;
          findUniqueKey(tmpColumns);
          setLastUpdateInterval(segment.update_date);
          breadcrumbList.value = [
            {
              key: 1,
              url: {
                path: '/folder-insidev2',
                query: {
                  ...router.currentRoute.value.query,
                  folderId: segment.folder_id,
                },
              },
              name: segment.folder_name,
            },
            {
              key: 2,
              url: {
                name: 'segmentResultV2',
                params: {
                  segmentRef: segment.segment_id,
                },
                query: {
                  ...router.currentRoute.value.query,
                },
              },
              name: segment.segment_name,
            },
          ];
          loading.value = false;
        } else {
          setTimeout(() => {
            fetchSegmentResult();
          }, 5000);
        }
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        highlightTable();
      });
  }
  function highlightTable() {
    const highlightSegmentList = JSON.parse(sessionStorage.getItem('highlightSegmentList') || '[]');

    highlightSegmentList.forEach((hightlightSegment: Record<string, any>) => {
      const value: string = hightlightSegment[uniqueKey.value];
      const checkbox = document.querySelector(`.form-check-input[type=checkbox][value="${value}"]`);
      if (checkbox) {
        const tableRow = checkbox.closest('tr');
        setTimeout(() => {
          if (tableRow) tableRow.style.backgroundColor = '#C7E6FF';
        }, 1000);
      }
    });

    sessionStorage.removeItem('highlightSegmentList');
  }

  function onRowClick(row: any) {
    if (row.hasOwnProperty('cid') && (row.cid !== null || row.cid !== undefined)) {
      router.push({ name: 'customerProfileDashboard', params: { cId: row.cid, pageId: 1 } });
    } else if (row.hasOwnProperty('cdp_cid') && (row.cdp_cid !== null || row.cdp_cid !== undefined)) {
      router.push({ name: 'customerProfileDashboard', params: { cId: row.cdp_cid, pageId: 1 } });
    }
  }

  watch(
    () => searchText.value,
    () => {
      pagination.totalRows = 0;
      if (pagination.currentPage == 1) {
        if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
        searchDebounceTimeout = setTimeout(() => {
          fetchSegmentResult();
        }, 500);
      } else {
        if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
        searchDebounceTimeout = setTimeout(() => {
          pagination.currentPage = 1;
        }, 500);
      }
    },
  );
  watch(
    () => pagination.currentPage,
    () => {
      pagination.totalRows = 0;
      fetchSegmentResult();
    },
  );

  watch(
    () => pagination.perPage,
    (newPerpage) => {
      if (newPerpage) {
        pagination.currentPage = 1;
        pagination.totalRows = 0;
      }
      fetchSegmentResult();
    },
  );

  return {
    lastUpdate,
    refreshBatch,
    searchText,
    selectedRowDesc,
    selectedRowCount,
    clearSelectedRow,
    columns,
    dataList,
    uniqueKey,
    includeOrExcludeList,
    isSelectedAll,
    onSelectedAll,
    onSelectedRow,
    onSort,
    paginationOptions,
    pagination,
    fetchSegmentResult,
    onRowClick,
  };
}
