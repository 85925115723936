import { segmentVersionKey } from '@/constants/modules/segment-v2/segment-adaptor';

export default [
  {
    title: 'main_menu.segment',
    customIcon: 'SegmentIcon',
    versionKey: segmentVersionKey,
    version: '1.0.0',
    path: '/segment/data-segment/main',
    children: [],
  },
  {
    title: 'main_menu.segment',
    customIcon: 'SegmentIcon',
    versionKey: segmentVersionKey,
    version: '2.0.0',
    path: '/segmentv2',
    children: [],
  },
];
