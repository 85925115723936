import { AggregateList, ColumnProperty, OperationList } from '../types/data-source';
import { SubPredicate } from '../types/ruleset-payload';
import { CDP_DataType } from '../types/segment';

export const COLOR_REF = ['#007FFF', '#8F9DFF', '#5771FE', '#007FFF', '#8F9DFF', '#5771FE', '#007FFF', '#8F9DFF', '#5771FE', '#007FFF'];

export const OPERATOR_TYPE = {
  AND: { id: 1, key: 'AND', label: 'และ' },
  OR: { id: 2, key: 'OR', label: 'หรือ' },
  ADVANCE: { id: 3, key: 'ADVANCE', label: 'ขั้นสูง' },
};

export const OPERATOR_TYPE_BASIC_LIST = [
  { id: 1, key: 'AND', label: 'และ' },
  { id: 2, key: 'OR', label: 'หรือ' },
];

export const OPERATOR_TYPE_ADVANCE_LIST = [
  { id: 1, key: 'AND', label: 'และ' },
  { id: 2, key: 'OR', label: 'หรือ' },
  { id: 3, key: 'ADVANCE', label: 'ขั้นสูง' },
];

export const RPB_SET_TYPE = {
  RULE: 1,
  QUANTIFIER: 2,
  FILTER_BY_SEGMENT: 3,
};

export const RULE_TYPE = {
  RULE: 1,
  AGGREGATE: 2,
};

export const SEGMENT_TYPE = {
  CUSTOM: 1,
  REALTIME: 2,
  MANUAL_BATCH: 3,
  SCHEDULED_BATCH: 4,
};

export const COLUMN_PROPERTY_CONST: ColumnProperty = {
  column: { column_id: 0, column_seq: 0, column_name: '', column_alias: '', data_type: 0, data_type_desc: '', is_display: false, is_filter: false },
  data_type_operation_list: [],
  agg_operation_list: [],
};

export const CDP_DATA_TYPE: CDP_DataType = {
  // Numeric Types
  BIT: 11,
  TINYINT: 12,
  SMALLINT: 13,
  INT: 14,
  BIGINT: 15,
  SMALLMONEY: 16,
  MONEY: 17,
  NUMERIC: 18,
  DECIMAL: 19,
  FLOAT: 21,
  REAL: 22,

  // Date/Time Types
  TIME: 31,
  DATE: 32,
  DATETIME2: 33,
  DATETIME_OFFSET: 34,
  DATETIME: 35,
  SMALLDATETIME: 36,

  // String Types
  CHAR: 41,
  VARCHAR: 42,
  TEXT: 43,
  NCHAR: 51,
  NVARCHAR: 52,
  NTEXT: 53,

  // Binary Types
  BINARY: 61,
  VARBINARY: 62,
  IMAGE: 63,

  // Other Data Types
  HIERARCHYID: 71,
  UNIQUEIDENTIFIER: 72,
  SQL_VARIANT: 73,
  XML: 74,
  GEOMETRY: 75,
  GEOGRAPHY: 76,
};

export const GROUP_MODE = {
  INHERITED: 0,
  AND: 1,
  OR: 2,
  ADVANCE: 3,
};

export const GROUP_MAP_REF = {
  GROUP: { id: 1, label: 'Group' },
  RULESET: { id: 2, label: 'RuleSet' },
};

export const RPB_EXPRESSION_TYPE = {
  NONE: 0,
  SINGLE: 1,
  MULTIPLE: 2,
  SCALA_FUNCRION: 3,
  AGGREGATE_FUNCTION: 4,
  RAW_EXPRESSION: 5,
};

export const RPB_SUB_PREDICATE_POSITION = {
  LEFT: 1,
  RIGHT: 2,
};

export const RPB_SUB_PREDICATE_TYPE = {
  COLUMN: 1,
  RAW_EXPRESSION: 2,
};

export const DEFAULT_SUB_PREDICATE: SubPredicate = {
  sub_predicate_ref_uid: '', // This will be generated by the frontend (e.g., using uuidv4 or another method)
  sub_predicate_id: null, // Initially null until it's saved in the database
  seq: 0, // Default sequence value
  sub_type: null, // Default sub_type value
  table_id: null, // Placeholder for table_id
  column_id: null, // Placeholder for column_id
  relation_path_id: null, // Optional, can be undefined
  sql_function: null, // Optional, can be undefined
  sql_expression: null, // Optional, can be undefined
  append_operator: null, // Optional, can be undefined
};

export const DEFAULT_OPERATION: OperationList = {
  value: 0,
  name_th: '',
  name_en: '',
  description_th: '',
  description_en: '',
  tooltip_th: '',
  tooltip_en: '',
  is_filter: false,
  data_list: [],
  unit_list: [],
};

export const DEFAULT_AGGREGATE: AggregateList = {
  value: 0,
  name_th: '',
  name_en: '',
  description_th: '',
  description_en: '',
  tooltip_th: '',
  tooltip_en: '',
};
