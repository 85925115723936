export default function () {
  const fetchBiTabsModel: API.RequestModel<Bi.BiTab.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Page/List`,
    method: 'GET',
    payload: {
      Page: null!,
      Limit: null!,
    },
  };

  const fetchBiTabsReorderModel: API.RequestModel<Bi.BiTab.Reorder.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Page/Reorder`,
    method: 'POST',
    payload: {
      PageId: null!,
      From_Seq: null!,
      To_Seq: null!,
    },
  };

  const fetchBiTabsCreateModel: API.RequestModel<Bi.BiTab.Create.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Page/Create`,
    method: 'POST',
    payload: {
      Tab_Name: '',
      Tab_label: '',
      Tab_Icon: '',
    },
  };

  const fetchBiTabsEditModel: API.RequestModel<Bi.BiTab.Edit.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Page/Edit`,
    method: 'POST',
    payload: {
      PageId: null!,
      Tab_Name: '',
      Tab_label: '',
      Tab_Icon: '',
    },
  };

  const fetchBiTabsDeleteModel: API.RequestModel<Bi.BiTab.Delete.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_BI!}/Page/Delete`,
    method: 'POST',
    payload: {
      PageId: null!,
    },
  };

  return {
    fetchBiTabsModel,
    fetchBiTabsReorderModel,
    fetchBiTabsCreateModel,
    fetchBiTabsEditModel,
    fetchBiTabsDeleteModel,
  };
}
