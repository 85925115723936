<template>
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_167_255144" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="13">
      <path d="M7.85214 0.800781H0.369141V12.8018H7.85214V0.800781Z" fill="white" />
    </mask>
    <g mask="url(#mask0_167_255144)">
      <path
        d="M5.63942 12.8022C5.6986 12.7674 5.75276 12.7247 5.80042 12.6752C5.82664 12.6387 5.84301 12.596 5.84797 12.5513C5.85292 12.5066 5.84628 12.4614 5.8287 12.42C5.81113 12.3787 5.78319 12.3425 5.74759 12.315C5.71199 12.2876 5.66992 12.2697 5.62542 12.2632C5.5774 12.2565 5.52889 12.2539 5.48042 12.2552H2.73942C2.69262 12.2543 2.64581 12.2569 2.59942 12.2632C2.54695 12.2696 2.49781 12.2923 2.45903 12.3282C2.42024 12.3642 2.39378 12.4114 2.38342 12.4632C2.36871 12.5142 2.37045 12.5685 2.38841 12.6184C2.40636 12.6683 2.43961 12.7113 2.48342 12.7412C2.51552 12.7626 2.54892 12.782 2.58342 12.7992L5.63942 12.8022Z"
        fill="#FF2133"
      />
      <path
        d="M5.75525 4.75476H5.89825C6.42925 4.75476 6.96025 4.75476 7.49225 4.75476C7.56234 4.75752 7.6323 4.74665 7.69825 4.72275C7.73986 4.70317 7.77583 4.67337 7.8028 4.63611C7.82976 4.59886 7.84684 4.55537 7.85243 4.50972C7.85803 4.46407 7.85195 4.41775 7.83478 4.37508C7.81761 4.33242 7.7899 4.29481 7.75425 4.26576C7.56825 4.07576 7.37625 3.89275 7.18625 3.70675L4.35525 0.936758C4.17125 0.756758 4.05525 0.756758 3.86725 0.936758L0.517247 4.21376C0.470298 4.24681 0.432227 4.29093 0.406411 4.34221C0.380594 4.3935 0.367829 4.45036 0.369247 4.50776C0.387247 4.67076 0.503247 4.75776 0.706247 4.75776H2.28225V4.89976C2.28225 6.28642 2.28225 7.67309 2.28225 9.05976C2.27736 9.12009 2.2857 9.18076 2.30668 9.23754C2.32766 9.29432 2.36077 9.34583 2.40372 9.38849C2.44666 9.43114 2.49839 9.46392 2.55531 9.48452C2.61222 9.50512 2.67295 9.51305 2.73325 9.50776H5.48725C5.66825 9.50776 5.72425 9.46176 5.74825 9.28176C5.75552 9.208 5.75785 9.13383 5.75525 9.05976C5.75525 7.67709 5.75525 6.29442 5.75525 4.91176V4.75476Z"
        fill="#FF2133"
      />
      <path
        d="M4.11206 11.1574H2.74206C2.69122 11.1556 2.64032 11.1586 2.59006 11.1664C2.53069 11.1793 2.47746 11.212 2.43904 11.2591C2.40061 11.3062 2.37925 11.3649 2.37844 11.4256C2.37763 11.4864 2.39741 11.5456 2.43457 11.5937C2.47172 11.6418 2.52406 11.6759 2.58306 11.6904C2.62517 11.6989 2.66811 11.7026 2.71106 11.7014H5.51106C5.54616 11.7022 5.58128 11.7002 5.61606 11.6954C5.68028 11.6854 5.73883 11.6528 5.78117 11.6035C5.82352 11.5542 5.84688 11.4914 5.84706 11.4264C5.84658 11.3614 5.82248 11.2987 5.77924 11.2502C5.73601 11.2016 5.67659 11.1704 5.61206 11.1624C5.53425 11.1558 5.45611 11.1538 5.37806 11.1564H4.11206"
        fill="#FF2133"
      />
      <path
        d="M4.10915 10.0585H2.68016C2.62522 10.0541 2.57017 10.0658 2.52183 10.0923C2.47349 10.1188 2.43397 10.1588 2.40815 10.2075C2.38138 10.2493 2.36895 10.2986 2.37274 10.3481C2.37653 10.3975 2.39633 10.4444 2.42915 10.4815C2.48374 10.5378 2.55304 10.5777 2.62915 10.5965C2.72594 10.6094 2.82381 10.6121 2.92115 10.6045H5.43916C5.47816 10.6045 5.51716 10.6045 5.55616 10.6045C5.59385 10.607 5.63164 10.6016 5.66714 10.5887C5.70265 10.5758 5.73509 10.5557 5.76242 10.5296C5.78975 10.5036 5.81137 10.4721 5.82591 10.4372C5.84045 10.4024 5.8476 10.3649 5.84691 10.3271C5.84621 10.2893 5.83769 10.2521 5.82188 10.2178C5.80606 10.1835 5.7833 10.1529 5.75503 10.1278C5.72676 10.1028 5.6936 10.0838 5.65765 10.0723C5.6217 10.0607 5.58373 10.0567 5.54615 10.0605C5.06615 10.0605 4.58616 10.0605 4.10516 10.0605"
        fill="#FF2133"
      />
    </g>
  </svg>
</template>
