import { Component, RendererElement, h } from 'vue';
import './style.scss';

export const ToastStatus = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

export interface ToastProps {
  status: string;
  title: string;
  description: string;
  icon?: Component;
  onClick?: () => void;
}

export default function Toast(props: ToastProps): RendererElement {
  const title = h('p', { class: 'toast-title' }, props.title);
  const description = h('p', { class: 'toast-description' }, props.description);
  const icon = props.icon ? h('div', { class: 'toast-icon-wrp' }, h(props.icon)) : null;
  const toastWrapper = h('div', { class: 'cdp-toast-wrp', onclick: props.onClick }, [
    icon ?? icon,
    h('div', { class: 'toast-body' }, [h('div', { class: 'toast-title-wrp' }, title), h('div', { class: 'toast-description-wrp' }, description)]),
  ]);
  return toastWrapper;
}
