<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <mask id="mask0_1083_48949" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path d="M24 0H0V24H24V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1083_48949)">
        <path
          d="M12 0C9.62662 0 7.30651 0.703762 5.33312 2.02234C3.35973 3.34092 1.8217 5.21506 0.913445 7.40778C0.00519377 9.60049 -0.232439 12.0133 0.230584 14.3411C0.693607 16.6688 1.83647 18.8071 3.5147 20.4853C5.19293 22.1635 7.33116 23.3064 9.65893 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6403 21.9777 18.6669C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51471C18.2348 1.26427 15.1826 0 12 0ZM16 14.587C16.0966 14.6759 16.1748 14.783 16.2301 14.9021C16.2854 15.0212 16.3167 15.1501 16.3221 15.2813C16.3276 15.4125 16.3072 15.5435 16.262 15.6668C16.2169 15.7901 16.1479 15.9033 16.059 16C15.9701 16.0967 15.863 16.1748 15.7439 16.2301C15.6248 16.2854 15.4959 16.3167 15.3647 16.3221C15.2335 16.3276 15.1025 16.3072 14.9792 16.262C14.8559 16.2169 14.7426 16.1479 14.646 16.059C14.625 16.04 14.606 16.02 14.587 16L12 13.414L9.41399 16C9.22539 16.1822 8.97276 16.283 8.71056 16.2807C8.44837 16.2784 8.19761 16.1732 8.0122 15.9878C7.82679 15.8024 7.72157 15.5516 7.71929 15.2894C7.71701 15.0272 7.81784 14.7746 7.99999 14.586L10.586 12L7.99999 9.414C7.81784 9.2254 7.71701 8.97277 7.71929 8.71057C7.72157 8.44837 7.82679 8.19761 8.0122 8.01221C8.19761 7.8268 8.44837 7.72158 8.71056 7.7193C8.97276 7.71702 9.22539 7.81784 9.41399 8L12 10.586L14.587 8C14.6759 7.90335 14.783 7.82517 14.9021 7.7699C15.0212 7.71462 15.1501 7.68334 15.2813 7.67786C15.4125 7.67238 15.5435 7.69283 15.6668 7.73798C15.7901 7.78313 15.9033 7.85207 16 7.94098C16.0966 8.02988 16.1748 8.13698 16.2301 8.2561C16.2854 8.37523 16.3167 8.50404 16.3221 8.63525C16.3276 8.76646 16.3072 8.8975 16.262 9.02081C16.2169 9.14413 16.1479 9.25735 16.059 9.354C16.04 9.375 16.02 9.39402 16 9.41302L13.414 12L16 14.587Z"
          fill="#373737"
        />
      </g>
    </g>
  </svg>
</template>
