<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_167_331535" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
      <path d="M12.545 0.833984H0.5V12.846H12.545V0.833984Z" fill="white" />
    </mask>
    <g mask="url(#mask0_167_331535)">
      <path
        d="M10.91 7.90798V9.64298C10.91 10.0673 10.7414 10.4743 10.4414 10.7744C10.1413 11.0744 9.73435 11.243 9.31 11.243H3.7C3.27565 11.243 2.86869 11.0744 2.56863 10.7744C2.26857 10.4743 2.1 10.0673 2.1 9.64298V4.03398C2.1 3.60964 2.26857 3.20267 2.56863 2.90261C2.86869 2.60256 3.27565 2.43398 3.7 2.43398H4.748C4.96017 2.43398 5.16366 2.3497 5.31369 2.19967C5.46371 2.04964 5.548 1.84616 5.548 1.63398C5.548 1.42181 5.46371 1.21833 5.31369 1.0683C5.16366 0.91827 4.96017 0.833984 4.748 0.833984H3.7C2.85131 0.833984 2.03737 1.17113 1.43726 1.77124C0.837142 2.37136 0.5 3.18529 0.5 4.03398L0.5 9.64298C0.5 10.0632 0.58277 10.4793 0.743585 10.8676C0.904401 11.2558 1.14011 11.6086 1.43726 11.9057C2.03737 12.5058 2.85131 12.843 3.7 12.843H9.309C9.72923 12.843 10.1453 12.7602 10.5336 12.5994C10.9218 12.4386 11.2746 12.2029 11.5717 11.9057C11.8689 11.6086 12.1046 11.2558 12.2654 10.8676C12.4262 10.4793 12.509 10.0632 12.509 9.64298V7.90798C12.509 7.69581 12.4247 7.49233 12.2747 7.3423C12.1247 7.19227 11.9212 7.10798 11.709 7.10798C11.4968 7.10798 11.2933 7.19227 11.1433 7.3423C10.9933 7.49233 10.91 7.69581 10.91 7.90798Z"
        fill="#007FFF"
      />
      <path
        d="M11.7182 0.833984H8.51818C8.30601 0.833984 8.10253 0.91827 7.9525 1.0683C7.80247 1.21833 7.71818 1.42181 7.71818 1.63398C7.71818 1.84616 7.80247 2.04964 7.9525 2.19967C8.10253 2.3497 8.30601 2.43398 8.51818 2.43398H9.53918L4.59218 7.47798C4.51858 7.5533 4.46052 7.64237 4.42134 7.74012C4.38216 7.83787 4.36262 7.94237 4.36382 8.04768C4.36503 8.15298 4.38697 8.25701 4.42838 8.35384C4.46979 8.45066 4.52987 8.53838 4.60518 8.61198C4.68049 8.68559 4.76957 8.74364 4.86732 8.78282C4.96506 8.82201 5.06957 8.84155 5.17487 8.84034C5.28018 8.83914 5.38421 8.8172 5.48103 8.77579C5.57786 8.73438 5.66558 8.6743 5.73918 8.59898L10.9392 3.28898V4.28898C10.9392 4.50116 11.0235 4.70464 11.1735 4.85467C11.3235 5.0047 11.527 5.08898 11.7392 5.08898C11.9514 5.08898 12.1548 5.0047 12.3049 4.85467C12.4549 4.70464 12.5392 4.50116 12.5392 4.28898V1.65998C12.5392 1.44178 12.4528 1.23244 12.299 1.07767C12.1452 0.922913 11.9364 0.835305 11.7182 0.833984Z"
        fill="#007FFF"
      />
    </g>
  </svg>
</template>
