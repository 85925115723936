import churnRate from './churn-rate';
import segment from './segment';
import analytics from './analytics';
import dataConnect from './data-connect';
import communication from './communication';
import mka from './mka';
import report from './report';
import setting from './setting';

const verticalMenuList = [...analytics, ...segment, ...churnRate, ...dataConnect, ...communication, ...mka];

export default verticalMenuList;

