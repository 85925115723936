import { ref, Ref } from 'vue';
import type { EChartsOption } from 'echarts';

export default function useTestEChartsView() {
  const option: Ref<EChartsOption> = ref({});

  option.value = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: ['Forest', 'Steppe', 'Desert', 'Wetland'],
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar', 'stack'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: ['1 May 2024', '2 May 2024', '3 May 2024', '4 May 2024', '5 May 2024', '6 May 2024', '7 May 2024'],
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Forest',
        stack: '1',
        type: 'bar',
        barGap: 0,
        emphasis: {
          focus: 'series',
        },
        data: [320, 332, 301, 334, 390, 300, 300],
      },
      {
        name: 'Steppe',
        type: 'bar',
        stack: '1',
        emphasis: {
          focus: 'series',
        },
        data: [220, 182, 191, 234, 290, 300, 300],
      },
      {
        name: 'Desert',
        type: 'bar',
        stack: '1',
        emphasis: {
          focus: 'series',
        },
        data: [150, 232, 201, 154, 190, 300, 300],
      },
      {
        name: 'Wetland',
        type: 'bar',
        stack: '1',
        emphasis: {
          focus: 'series',
        },
        data: [98, 77, 101, 99, 40, 300, 300],
      },
      {
        name: 'Forest',
        stack: '2',
        type: 'bar',
        barGap: 0,
        emphasis: {
          focus: 'series',
        },
        data: [320, 332, 301, 334, 390, 300, 300],
      },
      {
        name: 'Steppe',
        type: 'bar',
        stack: '2',
        emphasis: {
          focus: 'series',
        },
        data: [220, 182, 191, 234, 290, 300, 300],
      },
      {
        name: 'Desert',
        type: 'bar',
        stack: '2',
        emphasis: {
          focus: 'series',
        },
        data: [150, 232, 201, 154, 190, 300, 300],
      },
      {
        name: 'Wetland',
        type: 'bar',
        stack: '2',
        emphasis: {
          focus: 'series',
        },
        data: [98, 77, 101, 99, 40, 300, 300],
      },
      {
        name: 'Forest',
        stack: '3',
        type: 'bar',
        barGap: 0,
        emphasis: {
          focus: 'series',
        },
        data: [320, 332, 301, 334, 390, 300, 300],
      },
      {
        name: 'Steppe',
        type: 'bar',
        stack: '3',
        emphasis: {
          focus: 'series',
        },
        data: [220, 182, 191, 234, 290, 300, 300],
      },
      {
        name: 'Desert',
        type: 'bar',
        stack: '3',
        emphasis: {
          focus: 'series',
        },
        data: [150, 232, 201, 154, 190, 300, 300],
      },
      {
        name: 'Wetland',
        type: 'bar',
        stack: '3',
        emphasis: {
          focus: 'series',
        },
        data: [98, 77, 101, 99, 40, 300, 300],
      },
    ],
  };

  return { option };
}
