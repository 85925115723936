<template>
  <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.3684 23.663L5.3194 20.863C5.30243 19.8793 5.49266 18.903 5.87772 17.9976C6.26278 17.0923 6.83405 16.2781 7.5544 15.608C8.28917 16.5045 9.21406 17.2264 10.2621 17.7216C11.3102 18.2168 12.4553 18.4727 13.6144 18.471C14.788 18.4734 15.947 18.2114 17.0055 17.7045C18.0639 17.1976 18.9946 16.4588 19.7284 15.543C20.4275 16.1822 20.9882 16.9578 21.3761 17.822C21.7639 18.6863 21.9706 19.6208 21.9834 20.568L22.0324 23.368L5.3694 23.663H5.3684Z"
      fill="#019EFF"
    />
    <path
      d="M13.1075 16.7453C16.3544 16.7453 18.9865 14.1132 18.9865 10.8663C18.9865 7.61942 16.3544 4.9873 13.1075 4.9873C9.86063 4.9873 7.22852 7.61942 7.22852 10.8663C7.22852 14.1132 9.86063 16.7453 13.1075 16.7453Z"
      fill="#019EFF"
    />
    <path
      d="M0.0312503 24.3672V21.3672C0.0308172 19.5805 0.508829 17.8263 1.41602 16.2871C2.1916 14.9774 3.25428 13.8603 4.52344 13.02C3.5507 11.6637 3.02859 10.0362 3.03125 8.36719C3.03125 6.24546 3.87422 4.21074 5.37451 2.71045C6.8748 1.21016 8.90952 0.367188 11.0312 0.367188C13.153 0.367188 15.1877 1.21016 16.688 2.71045C18.1883 4.21074 19.0312 6.24546 19.0312 8.36719C19.0339 10.0358 18.5122 11.6629 17.54 13.019C18.8095 13.859 19.8722 14.9762 20.6475 16.2861C21.5548 17.8256 22.0325 19.5802 22.0312 21.3672V24.3672H0.0312503ZM3.26318 17.1084C2.45726 18.3826 2.03006 19.8595 2.03125 21.3672V22.3672H20.0312V21.3672C20.0328 19.8596 19.6059 18.3827 18.8003 17.1084C18.133 16.0473 17.2269 15.1569 16.1543 14.5083C14.7183 15.7111 12.9044 16.3692 11.0312 16.3672C9.1588 16.3702 7.34502 15.7139 5.9082 14.5132C4.8367 15.1602 3.93099 16.0497 3.26318 17.1084ZM5.03125 8.36719C5.03284 9.958 5.66565 11.483 6.79053 12.6079C7.9154 13.7328 9.44044 14.3656 11.0312 14.3672C12.6221 14.3656 14.1471 13.7328 15.272 12.6079C16.3968 11.483 17.0297 9.958 17.0312 8.36719C17.0294 6.77646 16.3968 5.25128 15.272 4.12646C14.1472 3.00165 12.622 2.36904 11.0312 2.36719C9.44044 2.36878 7.9154 3.00159 6.79053 4.12646C5.66565 5.25134 5.03284 6.77638 5.03125 8.36719Z"
      fill="black"
    />
  </svg>
</template>
