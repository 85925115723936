// Constants for SQLType
const SQL_TYPE = {
  QUERY: 1,
  ROOTDATA: 2,
};

// Constants for JoinType
const CDP_JOIN_TYPE = {
  INNER: 1,
  LEFT: 2,
  RIGHT: 3,
  FULL: 4,
  CROSS: 5,
  CROSSAPPLY: 6,
  OUTERAPPLY: 7,
};

// Constants for OperationType
const CDP_SET_OPERATION_TYPE = {
  UNION: 1,
  UNIONALL: 2,
};

// Constants for PredicateType
const CDP_PREDICATE_TYPE = {
  JOIN: 1,
  WHERE: 2,
  HAVING: 3,
  RULE_SEGMENT: 4,
};

// Constants for LogicalOp
export const CDP_PREDICATE_LOGICAL_OP = {
  AND: 'AND',
  OR: 'OR',
};

// Constants for ExpressionType
const CDP_PREDICATE_EXPRESSION_TYPE = {
  COLUMN: 1,
  CONSTANT: 2,
  VARIABLE: 3,
  SCALARFUNC: 4,
  AGGREGATE: 5,
  RAW_CUSTOM: 99,
};

// Constant for Exp_Op_Not
const CDP_PREDICATE_EXP_OP_NOT = 100;

// Constants for ExpOperationType
export const CDP_PREDICATE_EXP_OPERATION_TYPE = {
  EQUAL: 1, // Values are equal
  GREATERTHAN: 2, // Left operand is greater than right operand
  LESSTHAN: 3, // Left operand is less than right operand
  GREATEREQUAL: 4, // Left operand is greater than or equal to right operand
  LESSEQUAL: 5, // Left operand is less than or equal to right operand
  LIKE_CONTAIN: 6, // String comparison (value contains a pattern)
  LIKE_STARTWITH: 7, // String comparison (value starts with a pattern)
  LIKE_ENDWITH: 8, // String comparison (value ends with a pattern)
  BETWEEN: 9, // Value falls within a specified range (inclusive)
  ISNULL: 10, // Checks if the value is NULL
  IN: 11, // Value is included in a list of values
  EXISTS: 12, // Checks if a subquery returns any results
  RAW_CUSTOM: 99, // Allows for user-defined logic (implementation specific)
  NOT_EQUAL: 101, // Values are not equal
  NOT_LIKE_CONTAIN: 106, // String comparison (value does not contain a pattern)
  NOT_LIKE_STARTWITH: 107, // String comparison (value does not start with a pattern)
  NOT_LIKE_ENDWITH: 108, // String comparison (value does not end with a pattern)
  NOT_BETWEEN: 109, // Value falls outside a specified range (inclusive)
  NOT_NULL: 110, // Checks if the value is NOT NULL
  NOT_IN: 111, // Value is not included in a list of values
  NOT_EXISTS: 112, // Checks if a subquery returns no results
  REG_EX: 201, // Custom expression

  // Additional constants (potential descriptions based on common usage):
  LAST: 51, // Might be related to filtering based on the last record/period
  THIS: 52, // Might be related to filtering based on the current record/period -> สำหรับช่วงเวลา วันนี้ / เดือนนี้ / ปีนี้ / ควอร์เทอร์นี้
  TODAY: 53, // Filter based on today's date -> เฉพาะวันนี้
  WITHIN: 54, // Filter based on a specific timeframe/duration -> ภายในอีก_ข้างหน้า
  DAY_OF_WEEK: 55, // Filter based on the day of the week -> ทุกๆ วัน ทีระบุ
  MONTHLY: 56, // Filter based on a specific month -> ทุกๆ เดือน ที
  PAST: 57, // Filter based on a past timeframe -> ย้อนหลัง ทุกๆ วัน / เดือน / ปี / ควอร์เทอร์ ก
  WEEKLY: 58, // Filter based on a specific week or recurring weekly basis -> ทุกๆ สัปดาห์ ที่ระบุ
  NOT_LAST: 151, // Might be the negation of LAST -> ยกเว้นช่วงเวลาย้อนหลังดังกล่าว

  RAW: 99,
};

// Constants for ExpCompType
const CDP_PREDICATE_EXP_COMP_TYPE = {
  NUMERIC: 1,
  STRING: 2,
  DATETIME: 3,
};

// Constants for ExpCompLabelUnit
const CDP_PREDICATE_EXP_COMP_LABEL_UNIT = {
  DAY: 1,
  MONTH: 2,
  YEAR: 3,
  QUARTER: 4,
  WEEK: 5,
  HOUR: 6,
  BAHT: 7,
  PERCENT: 8,
  WEEKDAY: 9,
};

// Constants for ExpCompOp_Day_Of_Week
const CDP_PREDICATE_EXP_COMP_OP_DAY_OF_WEEK = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
};

// Constants for ExpCompOp_Monthly
const CDP_PREDICATE_EXP_COMP_OP_MONTHLY = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

// Constants for ValueType
const CDP_SCALARFUNC_VALUE_TYPE = {
  COLUMNID: 1,
  DATETIME: 2,
  STRING: 3,
  INT: 4,
  DECIMAL: 5,
};
