import { onMounted, ref, Ref, watch, computed, toRaw } from 'vue';
import type { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { uid } from '../utils';

interface Props {
  rawData?: Charts.ChartsProps.RawData;
  colorList: Charts.ChartsProps.ColorItem[];
  height?: number | string;
  width?: number | string;
  responsiveWidth?: boolean;
  responsiveHeight?: boolean;
}

interface DataItem {
  name: string;
  value: number;
}

export default function (props: Props) {
  const chart = ref();
  const id = ref(uid());
  const option: Ref<EChartsOption> = ref({});

  const resolveHeight = computed(() => {
    return props.responsiveHeight ? '100%' : typeof props.height === 'number' ? `${props.height}px` : props.height;
  });

  const resolveWidth = computed(() => {
    return props.responsiveWidth ? '100%' : typeof props.width === 'number' ? `${props.width}px` : props.width;
  });

  const transformedData: DataItem[] =
    props.rawData?.data
      ?.map((dataItem: any) => {
        if (Array.isArray(dataItem.name)) {
          // If dataItem.name is an array, map over it
          return dataItem.name.map((name: string, index: number) => ({
            name: name,
            value: dataItem.value[index],
          }));
        } else {
          // If dataItem.name is not an array, return an object with a single item
          return {
            name: dataItem.name,
            value: dataItem.value,
          };
        }
      })
      ?.flat() || [];

  const totalValue = transformedData.reduce((acc: number, item: DataItem) => acc + item.value, 0);

  option.value = {
    tooltip: {
      trigger: 'item',
      formatter: function (params: any) {
        const percentage = ((params.value / totalValue) * 100).toFixed(2);
        return `${params.name}<br/>Normal Customer: ${percentage}%<br/>Segment Size: ${params.value} คน`;
      },
    },
    visualMap: {
      min: 0,
      max: 100,
      show: false,
      inRange: {
        color: props.colorList.map((item: any) => item.hexColor),
      },
    },
    series: [
      {
        type: 'treemap',
        roam: false,
        nodeClick: undefined,
        width: '100%',
        height: '90%',
        labelLayout: {
          moveOverlap: 'shiftY',
        },
        data: transformedData,
        label: {
          show: true,
          position: [5, 5],
          padding: 10,
          overflow: 'truncate',
          formatter: function (params: any) {
            const percentage = ((params.value / totalValue) * 100).toFixed(2);
            return [`{b|SEGMENT NAME:}`, `{c|${params.name}}`, `{b|Normal Customer: ${percentage}%}`, `{b|Segment Size: ${params.value} คน}`].join(
              '\n',
            );
          },
          rich: {
            b: {
              fontSize: 14,
              color: '#FFFFFF',
              lineHeight: 20,
            },
            c: {
              fontWeight: 'bold',
              fontSize: 20,
              color: '#FFFFFF',
              lineHeight: 28,
            },
          },
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: '#FFFFFF',
          gapWidth: 5,
          borderRadius: 10,
        },
        breadcrumb: {
          show: false,
        },
      },
    ],
  };

  const init = () => {
    const dom = document.getElementById(id.value) as HTMLElement;
    if (!dom) return;

    chart.value = echarts.init(dom);

    try {
      let initResize = false;

      setTimeout(() => {
        initResize = true;
        chart.value.resize();
        option.value && chart.value.setOption(option.value);
      }, 1000); // Delay initialization to ensure proper sizing

      new ResizeObserver(() => {
        if (initResize) {
          chart.value.resize();
          option.value && chart.value.setOption(option.value);
        }
      }).observe(dom);
    } catch (e) {
      // Handle the exception, especially when using with <transition> in Vue
    }
  };

  onMounted(() => {
    init();
  });

  watch(
    () => option.value,
    () => {
      if (chart.value) chart.value.setOption(toRaw(option.value));
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch([() => props.height, () => props.width], () => {
    setTimeout(() => {
      chart.value.resize();
      option.value && chart.value.setOption(option.value);
    }, 100);
  });

  return { id, uid, option, resolveHeight, resolveWidth };
}
