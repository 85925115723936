export default function () {
  const fetchAnalyticsChartTabsModel: API.RequestModel<Bi.BiTab.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/PageList`,
    method: 'GET',
    payload: {
      Page: null!,
      Limit: null!,
    },
  };

  return {
    fetchAnalyticsChartTabsModel,
  };
}
