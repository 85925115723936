import { watch, ref, Ref, getCurrentInstance } from 'vue';
import router from '@/router/index';
import { useSegmentVersion } from '@/store/segmentV2Store';
import { segmentVersionKey } from '@/constants/modules/segment-v2/segment-adaptor';

export default function useBreadCrumb() {
  interface BreadcrumbOption {
    text: string;
    active: boolean;
    to: string;
  }

  const segmentVersionStore = useSegmentVersion();
  const breadcrumb: Ref<BreadcrumbOption[]> = ref([]);
  const vm = getCurrentInstance()?.proxy;
  const initBreadcrumb = () => {
    const { pathToRegexp } = require('path-to-regexp');

    const allRoute = router.options.routes;
    let pathRegex: RegExp | null = null;

    if (router.currentRoute.value.matched.length > 0) {
      pathRegex = pathToRegexp(router.currentRoute.value.matched[0].path) || null;
      if (pathRegex) {
        const currentRouteInfo: any = allRoute.find((route) => {
          return pathRegex?.test(route.path);
        });

        if (router.currentRoute.value.meta?.breadcrumb) {
          return (breadcrumb.value = router.currentRoute.value.meta.breadcrumb as BreadcrumbOption[]);
        }

        if (currentRouteInfo?.breadcrumb) {
          return (breadcrumb.value = currentRouteInfo.breadcrumb);
        }
      }
    }

    return (breadcrumb.value = []);
  };

  const onClickBreadcrumb = (breadcrumb: Record<string, any>) => {
    if (breadcrumb.to) router.push({ path: breadcrumb.to, query: breadcrumb.query || {} }).then(() => {});
    switch (breadcrumb.versionKey) {
      case segmentVersionKey:
        if (segmentVersionStore.getSegmentVersion && breadcrumb.versionTo[segmentVersionStore.getSegmentVersion])
          router.push({ path: breadcrumb.versionTo[segmentVersionStore.getSegmentVersion], query: breadcrumb.query || {} }).then(() => {});
        break;
    }
  };

  watch(
    () => vm?.$route,
    () => {
      initBreadcrumb();
    },
    { deep: true, immediate: true },
  );

  return { breadcrumb, onClickBreadcrumb };
}
