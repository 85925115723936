import biTab from '@models/bi/bi-tab';
import biLayout from '@models/bi/bi-layout';
import biChart from '@models/bi/bi-chart';

export default function () {
  const { fetchBiTabsModel, fetchBiTabsReorderModel, fetchBiTabsDeleteModel, fetchBiTabsCreateModel, fetchBiTabsEditModel } = biTab();
  const { fetchBiLayoutModel, fetchBiDeleteLayoutModel, fetchBiAdjustLayoutModel } = biLayout();
  const {
    fetchBiChartGlobalFilterListModel,
    fetchBiChartDynamicFilterListModel,
    fetchChartSetListModel,
    fetchBiChartSettingEditModel,
    fetchBiChartDataInfoModel,
    fetchChartAddModel,
    fetchBiChartSettingInfoModel,
    fetchBiChartDataModel,
    fetchBiChartExportDataInfoModel,
  } = biChart();

  return {
    fetchBiTabsModel,
    fetchBiTabsReorderModel,
    fetchBiTabsCreateModel,
    fetchBiTabsEditModel,
    fetchBiTabsDeleteModel,
    fetchBiLayoutModel,
    fetchBiAdjustLayoutModel,
    fetchBiDeleteLayoutModel,
    fetchChartSetListModel,
    fetchChartAddModel,
    fetchBiChartDataModel,
    fetchBiChartSettingInfoModel,
    fetchBiChartSettingEditModel,
    fetchBiChartDataInfoModel,
    fetchBiChartGlobalFilterListModel,
    fetchBiChartDynamicFilterListModel,
    fetchBiChartExportDataInfoModel,
  };
}
