import { ref, Ref, onBeforeMount, watch, nextTick } from 'vue';

import TextColumn from '../components/TextColumn.vue';
import ImageColumn from '../components/ImageColumn.vue';
import ActionColumn from '../components/ActionColumn.vue';
import OptionColumn from '../components/OptionColumn.vue';
import DateColumn from '../components/DateColumn.vue';
interface Props {
  columns: Array<DynamicTable.Column>;
  data?: Array<Record<string, any>>;
  hover?: boolean;
  checkbox?: boolean;
  responsive?: boolean;
  stickyHeader?: boolean;
  sortableRow?: boolean;
  sortableCol?: boolean;
  currentPage?: number;
  perPages?: number;
  rowSelectVariant?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark';
  busy?: boolean;
  selectAll?: boolean;
}

export default function useDynamicTable(props: Props) {
  const displayColumn: Ref<DynamicTable.Column[]> = ref([]);

  const renderCell = (key: string) => `cell(${key})`;

  const renderHead = (key: string) => `head(${key})`;

  const resolveColumn = (type: string) => {
    switch (type) {
      case 'Text':
        return TextColumn;
      case 'Image':
        return ImageColumn;
      case 'Date':
        return DateColumn;
      case 'Option':
        return OptionColumn;
      case 'Action':
        return ActionColumn;

      default:
        return TextColumn;
    }
  };

  const resolveDisplayColumn = (columns: DynamicTable.Column[]) => {
    return columns;
  };

  const renderDisplayTable = (columns: DynamicTable.Column[]) => {
    const columnNonDisplayList = columns.filter((column) => column.is_display == false);
    const columnDisplayList = columns.filter((column) => column.is_display == true);

    columnNonDisplayList.map((col) => {
      let arr: string;
      nextTick(() => {
        const checkUp = document.querySelectorAll(`.${col.key}`);
        checkUp.forEach((element: Element) => {
          arr = element.classList[1];
          const selectTableColumn = document.querySelectorAll(`.${arr}`);
          selectTableColumn.forEach((element: Element) => {
            (<HTMLInputElement>element).style.display = 'none';
          });
        });
      });
    });

    columnDisplayList.map((col) => {
      let arr: string;
      nextTick(() => {
        const checkUp = document.querySelectorAll(`.${col.key}`);
        checkUp.forEach((element: Element) => {
          arr = element.classList[1];
          const selectTableColumn = document.querySelectorAll(`.${arr}`);
          selectTableColumn.forEach((element: Element) => {
            (<HTMLInputElement>element).style.display = 'table-cell';
          });
        });
      });
    });

    columns.map((col) => {
      let arr: string;
      nextTick(() => {
        const checkUp = document.querySelectorAll(`th.${col.key}`);
        checkUp.forEach((element: Element) => {
          arr = element.classList[1];
          const selectTableColumn = document.querySelectorAll(`th.${arr}`);
          selectTableColumn.forEach((element: Element) => {
            (<HTMLInputElement>element).style.width = `${String(col.columnwidth)}px`;
          });
        });
      });
    });
  };

  watch(
    () => props.columns,
    () => {
      if (props.columns) {
        displayColumn.value = resolveDisplayColumn(props.columns);
        renderDisplayTable(props.columns);
      }
    },
    { deep: true },
  );

  watch(
    () => props.busy,
    () => {
      if (props.busy == false) {
        renderDisplayTable(props.columns);
      }
    },
  );

  onBeforeMount(() => {
    if (props.columns) displayColumn.value = resolveDisplayColumn(props.columns);
  });

  return { displayColumn, renderHead, renderCell, resolveColumn };
}
