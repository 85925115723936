import { Ref } from 'vue';
import api from '@/services/api';

import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { SegmentType } from '@/constants/modules/segment-v2/segment-result';
import segmentV2Model from '@/models/segment-v2/segment';

export default function (
  isSelectedAll: Ref<boolean>,
  includeOrExcludeList: Ref<Record<string, any>[]>,
  saveSegmentState: SegmentResult.SaveSegmentState,
) {
  const { isLoading } = useLoading();
  const { fetchSaveSegmentModel } = segmentV2Model();
  const { openDefaultErrorModal } = useValidationModal();

  function saveSegment(callback: (response: any) => void) {
    isLoading(true);
    fetchSaveSegmentModel.payload.Preview_Ref = saveSegmentState.preview_ref;
    fetchSaveSegmentModel.payload.Folder_Id = saveSegmentState.folder?.folder_id;
    fetchSaveSegmentModel.payload.Segment_Type = saveSegmentState.segmentType;
    fetchSaveSegmentModel.payload.Segment_Name = saveSegmentState.segmentName;

    api
      .apiRequest(fetchSaveSegmentModel)
      .then((res) => {
        callback(res.data.segmentid);
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading(false);
      });
  }
  return {
    saveSegment,
  };
}
