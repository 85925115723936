import { RuleSetRoot } from '@/views/modules/segment-v2/types/ruleset-payload';
import { TreeNode } from '@/views/modules/segment-v2/types/segment';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { segmentVersionKey } from '@/constants/modules/segment-v2/segment-adaptor';
import { DataSource } from '@/views/modules/segment-v2/types/data-source';

import api from '@/services/api';
import segmentV2Model from '@/models/segment-v2/segment';
import router from '@/router';

const { fetchSourcesDetailModel } = segmentV2Model();
export const useBuilderConfigStore = defineStore({
  id: 'builder_config',

  state: (): { ruleSetRoot: RuleSetRoot } => ({
    ruleSetRoot: {
      preview_ref: null,
      rule_set_list: [],
      group_equation: '',
      view_table_list: [],
      view_column_list: [],
      view_group_by_list: [],
      view_filter_list: [],
    },
  }),
  getters: {
    getRoot(state) {
      return this.ruleSetRoot;
    },
  },
  actions: {
    update(state: RuleSetRoot) {
      this.ruleSetRoot = state;
    },
    reset() {
      this.ruleSetRoot = {
        preview_ref: null,
        rule_set_list: [],
        group_equation: '',
        view_table_list: [],
        view_column_list: [],
        view_group_by_list: [],
        view_filter_list: [],
      };
    },
  },
  persist: {
    key: 'segment_config_builder',
    storage: localStorage,
  },
});

// Tree Config
export const useBuilderTreeStore = defineStore({
  id: 'builder_tree',

  state: (): { tree: TreeNode } => ({
    tree: {
      id: '',
      type: 'term',
      depth: 0,
    },
  }),
  getters: {
    getTree(state) {
      return this.tree;
    },
  },
  actions: {
    update(state: TreeNode) {
      this.tree = state;
    },
  },
  persist: {
    key: 'segment_config_tree',
    storage: localStorage,
  },
});

export const useSourceDetailStore = defineStore({
  id: 'segment_source_detail',

  state: (): { sourceDetail: DataSource | null } => ({
    sourceDetail: null,
  }),
  getters: {
    getSourceDetail(state): DataSource | null {
      return state.sourceDetail;
    },
  },
  actions: {
    async fetchSourceDetail() {
      const response = await api.apiRequest(fetchSourcesDetailModel);

      this.sourceDetail = response.data as DataSource;
    },
    update(sourceDetail: DataSource) {
      this.sourceDetail = sourceDetail;
    },
    reset() {
      this.sourceDetail = null;
    },
  },
  persist: {
    key: 'segment_source_detail',
    storage: sessionStorage,
  },
});
//Martech
export const useSegmentVersion = defineStore({
  id: 'segment_version',

  state: (): { segmentVersion: string | null } => ({
    segmentVersion: null,
  }),
  getters: {
    getSegmentVersion(state): string | null {
      return state.segmentVersion;
    },
  },
  actions: {
    async fetchSegmentVersion() {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const brandRef = router.currentRoute.value.query.brandRef as string;
      const segmentV2BrandDev = ['BTSIT17XC9ZM', 'BL6ZLW8PXBXA', 'BNRE0BK41S3B'];
      const segmentV2BrandProd = ['BC47UQQHYVIF'];
      if (process.env.BASE_ENV_URL === 'development') {
        if (segmentV2BrandDev.includes(brandRef)) {
          this.update('2.0.0');
        } else {
          this.update('1.0.0');
        }
      } else if (process.env.BASE_ENV_URL === 'production') {
        if (segmentV2BrandProd.includes(brandRef)) {
          this.update('2.0.0');
        } else {
          this.update('1.0.0');
        }
      }
    },
    update(version: string) {
      this.segmentVersion = version;
    },
    reset() {
      this.segmentVersion = null;
    },
  },
  persist: {
    key: segmentVersionKey,
    storage: localStorage,
  },
});

// to add HMR support with vue-cli/webpack, these code must be added to each store file
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useBuilderConfigStore, import.meta.webpackHot));
  import.meta.webpackHot.accept(acceptHMRUpdate(useBuilderTreeStore, import.meta.webpackHot));
  import.meta.webpackHot.accept(acceptHMRUpdate(useSourceDetailStore, import.meta.webpackHot));
  import.meta.webpackHot.accept(acceptHMRUpdate(useSegmentVersion, import.meta.webpackHot));
}
