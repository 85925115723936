import { ref, Ref, reactive, onMounted, watch, getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n';

import api from '@services/api';
import router from '@/router';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useFilters from '@/utils/hooks/useFilters';

import AllFolderModel from '@/models/segment/taballsegment';
import segmentModel from '@/models/segment/segment';
import segmentResultModel from '@models/segment/result';
import dynamicTableModel from '@/models/component/table/dynamicTable';

interface Props {
  dataSource: Segment.Datasource;
}

export default function useAllFolder(props: Props) {
  const { openDefaultErrorModal } = useValidationModal();
  const { fetchSegmentListModel } = segmentModel();
  const { fetchAllFolderListModel } = AllFolderModel();
  const { updateDisplayColumnModel } = dynamicTableModel();
  const { fetchSegmentResultModel } = segmentResultModel();
  const vm = getCurrentInstance()?.proxy;
  const tableIdForModal: Ref<number> = ref(0);

  const { filterDisplayObj } = useFilters();
  const { t } = useI18n();

  const orderingSeq = ref(0);
  const totalRecordForModal: Ref<number> = ref(0);
  const orderingKey = ref('');
  const orderingDirection = ref('');
  const loadingForModal: Ref<boolean> = ref(false);
  const dataList: Ref<Segment.AllFolder.Folder[]> = ref([]);
  const allSegmentList: Ref<Segment.Segment[]> = ref([]);
  const selectedData: Ref<Segment.AllFolder.Folder | null> = ref(null);
  const segmentSelect: Ref<Segment.Segment> = ref(null!);
  const isOpenModalCreate: Ref<boolean> = ref(false);
  const isOpenModalEdit: Ref<boolean> = ref(false);
  const isOpenModalDelete: Ref<boolean> = ref(false);
  const textSearchForModal: Ref<string> = ref('');
  const filtersObj: Ref<Array<any>> = ref([]);
  const sortCol: Ref<Array<any>> = ref([]);
  const fieldsForModal: Ref<DynamicTable.Column[]> = ref([]);
  const uniqueKeyModal: Ref<string> = ref('');
  const listCustomerForModal: Ref<Record<string, any>[]> = ref([]);
  const selectAllForModal = ref(false);
  const rowSortForModal = ref(false);
  const columnSortForModal = ref(true);
  const filtersObjForModal: Ref<Array<any>> = ref([]);
  const clearSegment = ref(false);
  const tableSelectAll: Ref<boolean> = ref(false);
  const lengthData = ref(0);

  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  const paginationForModal: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 4,
    totalRows: 0,
  });

  const dataForm = reactive({
    payload: {
      name: '',
      folder_id: 0,
    },
  });

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    orderingSeq.value = 1;
    orderingKey.value = sortedColumn.key;
    orderingDirection.value = sortedColumn.direction;
    if (paginationForModal.currentPage == 1) {
      fetchCustomerList();
    } else {
      paginationForModal.currentPage = 1;
    }
  };

  const fetchDataAllFolder = () => {
    fetchAllFolderListModel.payload.root_tableId = props.dataSource.tableid;

    api
      .apiRequest(fetchAllFolderListModel)
      .then((response) => {
        dataList.value = response.data.payload;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      });
  };

  const fetchSegmentList = (val: Ref<Segment.AllFolder.Folder | null>) => {
    segmentSelect.value = null!;
    allSegmentList.value = [];
    fetchSegmentListModel.payload.ref_type = 1;
    fetchSegmentListModel.payload.reference = val.value?.folderid;

    api
      .apiRequest(fetchSegmentListModel)
      .then((response) => {
        const segmentList = response.data.segment_list as Segment.Segment[];
        allSegmentList.value = segmentList;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      });
  };

  const handleColumnChange = (col: Array<any>) => {
    fieldsForModal.value = col;
    const displayList: DynamicTable.UpdateDisplay[] = [];
    col.map((column, index) => {
      displayList.push({
        ColumnId: column.column_id,
        Key_Column: column.key,
        Seq: index + 1,
      });
    });
    // updateDisplayColumnModel.payload.TableId = tableIdForModal.value;
    // updateDisplayColumnModel.payload.displayTables = displayList;

    // api.apiRequest(updateDisplayColumnModel).catch((err) => {
    //   openDefaultErrorModal(err);
    // });
  };

  const fetchCustomerList = () => {
    loadingForModal.value = true;
    if (segmentSelect.value?.segmentid == undefined || segmentSelect.value?.segmentid == null) {
      loadingForModal.value = false;
    } else {
      fetchSegmentResultModel.payload.SegmentId = segmentSelect.value?.segmentid;
      fetchSegmentResultModel.payload.Page = paginationForModal.currentPage;
      fetchSegmentResultModel.payload.Limit = paginationForModal.perPage;
      fetchSegmentResultModel.payload.Filters = filtersObjForModal.value;
      fetchSegmentResultModel.payload.Search_Value = textSearchForModal.value;
      fetchSegmentResultModel.payload.Flag_Count = true;
      fetchSegmentResultModel.payload.ResultType = 1;

      if (orderingKey.value != '') {
        fetchSegmentResultModel.payload.Ordering = [
          {
            Seq: orderingSeq.value,
            Key: orderingKey.value,
            Direction: orderingDirection.value,
          },
        ];
      }

      api
        .apiRequest(fetchSegmentResultModel)
        .then((response) => {
          findUniqueKey(response.data.datasourcedisplay.columns);
          fieldsForModal.value = response.data.datasourcedisplay.columns;
          tableIdForModal.value = response.data.datasourcedisplay.tableid;
          listCustomerForModal.value = response.data.segment_result;
          paginationForModal.totalRows = response.data.filtered_record;
          lengthData.value = response.data.segment_size;
          totalRecordForModal.value = response.data.segment_size;
          if (response.status == 'success') {
            loadingForModal.value = false;
          }
        })
        .catch((err) => {
          openDefaultErrorModal(err);
          loadingForModal.value = false;
        })
        .finally(() => {
          filtersObj.value = [];
        });
    }
  };

  const findUniqueKey = (_columns: PreviewSegment.DatasourceDisplayColumns[]) => {
    const found = _columns.find((el: PreviewSegment.DatasourceDisplayColumns) => el.is_primary_key === true);
    if (found) {
      uniqueKeyModal.value = found.key;
    } else {
      throw 'uniqueKeyModal not found';
    }
  };

  const toSegmentList = (list: Record<string, any>) => {
    router.push({
      path: 'folder-inside',
      query: { ...router.currentRoute.value.query, folderId: list.folderid, folderName: list.name },
    });
  };

  const onOpenModal = (tag: 'create' | 'edit' | 'delete', data: Segment.AllFolder.Folder) => {
    if (tag === 'create') {
      dataForm.payload.name = '';
      isOpenModalCreate.value = true;
    } else if (tag === 'edit') {
      dataForm.payload.folder_id = data.folderid;
      dataForm.payload.name = data.name;
      isOpenModalEdit.value = true;
    } else if (tag === 'delete') {
      dataForm.payload.folder_id = data.folderid;
      dataForm.payload.name = data.name || '';
      isOpenModalDelete.value = true;
    }
  };

  const onChangeSearch = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      if (textSearchForModal.value != '') {
        filtersObjForModal.value = filterDisplayObj(fieldsForModal.value, textSearchForModal.value);
      }
      fetchCustomerList();
    }, 500);
  };

  const countTotal = (total: number, length: number, status: boolean) => {
    if (length > 0 && status == true)
      return (
        t('segment.components.save_custom_dialog.table.segments_inbox_before') +
        (total - length) +
        t('segment.components.save_custom_dialog.table.segments_inbox_after')
      );
    if (length == 0 && status == true) return t('segment.components.save_custom_dialog.table.clear_select');
    else
      return (
        t('segment.components.save_custom_dialog.table.segments_inbox_before') +
        length +
        t('segment.components.save_custom_dialog.table.segments_inbox_after')
      );
  };

  const clickStatusBar = (length: number, status: boolean) => {
    if (length == 0 && status == true) {
      tableSelectAll.value = true;
    }
  };

  const onCloseModal = () => {
    if (isOpenModalCreate.value) {
      isOpenModalCreate.value = false;
    } else if (isOpenModalEdit.value) {
      isOpenModalEdit.value = false;
    } else if (isOpenModalDelete.value) {
      isOpenModalDelete.value = false;
    }
  };

  watch(
    () => textSearchForModal.value,
    () => {
      paginationForModal.currentPage = 1;
      onChangeSearch();
    },
  );

  watch(
    () => paginationForModal.currentPage,
    () => {
      if (textSearchForModal.value.length == 0) return fetchCustomerList();
      else return onChangeSearch();
    },
  );

  watch(
    () => props.dataSource,
    () => {
      fetchDataAllFolder();
    },
  );

  watch(
    () => selectedData.value,
    () => {
      if (selectedData.value == null) {
        listCustomerForModal.value = [];
      }
      clearSegment.value = !clearSegment.value;
      paginationForModal.totalRows = 0;
      fetchSegmentList(selectedData);
    },
  );

  watch(
    () => segmentSelect.value,
    () => {
      if (segmentSelect.value != null) {
        vm?.$emit('update-segment-select', segmentSelect.value.segmentid);
      } else {
        listCustomerForModal.value = [];
        paginationForModal.totalRows = 0;
      }
      fetchCustomerList();
    },
  );

  onMounted(() => {
    fetchDataAllFolder();
  });

  return {
    loadingForModal,
    fieldsForModal,
    segmentSelect,
    selectedData,
    dataList,
    allSegmentList,
    paginationForModal,
    isOpenModalCreate,
    isOpenModalEdit,
    isOpenModalDelete,
    dataForm,
    textSearchForModal,
    onOpenModal,
    onCloseModal,
    onSort,
    toSegmentList,
    filtersObj,
    sortCol,
    listCustomerForModal,
    uniqueKeyModal,
    selectAllForModal,
    rowSortForModal,
    columnSortForModal,
    clearSegment,
    totalRecordForModal,
    countTotal,
    clickStatusBar,
    tableSelectAll,
    lengthData,
    tableIdForModal,
    handleColumnChange,
  };
}
