export const adjustDefaultLayout = () => {
  const contentWrapper = document.getElementsByClassName('content-wrapper')[0] as HTMLElement;
  const contentBody = document.getElementsByClassName('content-body')[0] as HTMLElement;
  if (contentWrapper) contentWrapper.style.cssText = 'display:initial !important;height: 100% !important;margin: 0 !important;padding: 0 !important;';
  if (contentBody) contentBody.style.cssText = 'display: initial !important;flex-direction: initial !important;flex-grow: initial !important;';
};

export const unMountedDefaultLayout = () => {
  const contentWrapper = document.getElementsByClassName('content-wrapper')[0] as HTMLElement;
  const contentBody = document.getElementsByClassName('content-body')[0] as HTMLElement;
  if (contentWrapper) contentWrapper.style.cssText = '';
  if (contentBody) contentBody.style.cssText = '';
};

export function mapToColumnsObject(
  originalObject: Partial<SegmentResult.DatasourceDisplayColumns>,
  index: number,
): SegmentResult.DatasourceDisplayColumns {
  return {
    seq: originalObject.seq ?? index + 1,
    column_seq: originalObject.column_seq ?? 0,
    column_id: originalObject.column_id ?? 0,
    ref_columnid: originalObject.ref_columnid ?? 0,
    ref_tableid: originalObject.ref_tableid ?? 0,
    key: originalObject.key ?? '',
    column_name: originalObject.column_name ?? '',
    column_alias: originalObject.column_alias ?? '',
    label_en: originalObject.label_en ?? '',
    label_th: originalObject.label_th ?? '',
    sortable: originalObject.sortable ?? false,
    type_id: originalObject.type_id ?? 0,
    type_desc: originalObject.type_desc ?? '',
    column_property: originalObject.column_property ?? {},
    operation_list: originalObject.operation_list ?? [],
    col_sqltype: originalObject.col_sqltype ?? 0,
    col_sqltype_desc: originalObject.col_sqltype_desc ?? '',
    is_primary_key: originalObject.is_primary_key ?? false,
    cdp_unique_index: originalObject.cdp_unique_index ?? 0,
    nullable: originalObject.nullable ?? true,
    data_type: originalObject.data_type ?? 0,
    data_type_desc: originalObject.data_type_desc ?? '',
    col_type: originalObject.col_type ?? 0,
    col_type_desc: originalObject.col_type_desc ?? '',
    is_groupby: originalObject.is_groupby ?? false,
    is_display: originalObject.is_display ?? false,
    is_filter: originalObject.is_filter ?? false,
    is_system_column: originalObject.is_system_column ?? false,
    align: originalObject.align ?? 0,
    align_desc: originalObject.align_desc ?? '',
    columnwidth: originalObject.columnwidth ?? undefined,
  };
}

export const defaultColumn = {
  seq: 0,
  column_seq: 0,
  column_id: 0,
  ref_columnid: 0,
  ref_tableid: 0,
  key: 'defaultcolumn',
  column_name: 'DefaultColumn',
  column_alias: 'DefaultColumn',
  label_en: '',
  label_th: '',
  sortable: false,
  type_id: 0,
  type_desc: '',
  column_property: {},
  operation_list: [],
  col_sqltype: 0,
  col_sqltype_desc: '',
  is_primary_key: true,
  cdp_unique_index: 0,
  nullable: true,
  data_type: 0,
  data_type_desc: '',
  col_type: 0,
  col_type_desc: '',
  is_groupby: false,
  is_display: false,
  is_filter: false,
  is_system_column: true,
  align: 0,
  align_desc: '',
  columnwidth: undefined,
};
