import { ref, Ref, watch, nextTick, toRaw } from 'vue';

export default function useSelectRow(list: Ref<Record<string, any>[]>, uniqueKey: Ref<string>, selectAll?: Ref<boolean>) {
  const isSelectedAll: Ref<boolean> = ref(selectAll || true);

  const includeOrExcludeList: Ref<Record<string, any>[]> = ref([]);

  function forceCheckedAll(value: boolean) {
    const tds = document.querySelectorAll('tbody tr td:first-child input[type=checkbox]');
    let eleArr1: string;
    nextTick(() => {
      tds.forEach((element: Element) => {
        (<HTMLInputElement>element).checked = value;
        eleArr1 = element.classList[1];
        const selectTableRow = document.querySelectorAll(`tbody tr.selected-${eleArr1}`);
        selectTableRow.forEach((el: Element) => {
          if ((<HTMLInputElement>element).checked == true) {
            (<HTMLInputElement>el).style.backgroundColor = '#EFF5FA';
          } else {
            (<HTMLInputElement>el).removeAttribute('style');
          }
        });
      });
    });
  }

  function onSelectedAll(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    isSelectedAll.value = inputElement.checked;
    includeOrExcludeList.value = [];
    forceCheckedAll(inputElement.checked);
  }

  function highlightTable() {
    const checkUp = document.querySelectorAll('tbody tr td:first-child input[type=checkbox]');
    let arr1: string;
    checkUp.forEach((element: Element) => {
      arr1 = element.classList[1];
      const selectTableRow = document.querySelectorAll(`tbody tr.selected-${arr1}`);
      selectTableRow.forEach((el: Element) => {
        if ((<HTMLInputElement>element).checked == true) {
          (<HTMLInputElement>el).style.backgroundColor = '#EFF5FA';
        } else {
          (<HTMLInputElement>el).removeAttribute('style');
        }
      });
    });
  }

  function onSelectedRow(e: Event, item: Record<string, any>) {
    const itemTmp = toRaw(item);
    const inputElement = e.target as HTMLInputElement;

    highlightTable();

    if (isSelectedAll.value && inputElement.checked) {
      includeOrExcludeList.value = includeOrExcludeList.value.filter((el: Record<string, any>) => el[uniqueKey.value] !== itemTmp[uniqueKey.value]);
    } else if (isSelectedAll.value && !inputElement.checked) {
      includeOrExcludeList.value.push(itemTmp);
    } else if (!isSelectedAll.value && inputElement.checked) {
      includeOrExcludeList.value.push(itemTmp);
    } else if (!isSelectedAll.value && !inputElement.checked) {
      includeOrExcludeList.value = includeOrExcludeList.value.filter((el: Record<string, any>) => el[uniqueKey.value] !== itemTmp[uniqueKey.value]);
    }

    const selectAllCheckbox = document.querySelector('thead tr th input[type=checkbox]');
    if (includeOrExcludeList.value.length > 0) {
      (<HTMLInputElement>selectAllCheckbox).checked = true;
      (<HTMLInputElement>selectAllCheckbox).indeterminate = true;
    } else if (!isSelectedAll.value) {
      (<HTMLInputElement>selectAllCheckbox).checked = false;
      (<HTMLInputElement>selectAllCheckbox).indeterminate = false;
    } else {
      (<HTMLInputElement>selectAllCheckbox).checked = true;
      (<HTMLInputElement>selectAllCheckbox).indeterminate = false;
    }
  }

  watch(list, () => {
    const includeOrExcludeListTmp = toRaw(includeOrExcludeList.value.map((el: Record<string, any>) => toRaw(el)));
    setTimeout(() => {
      const table_row = document.getElementsByClassName('dragable-row');
      for (let i = 0; i < table_row.length; i++) {
        const row = table_row[i];
        row.classList.add(`selected-${i}`);
      }

      let gq = -1;
      setTimeout(() => {
        const RowCheckUp = document.querySelectorAll('tbody tr td:first-child input[type=checkbox]');
        RowCheckUp.forEach((element: Element) => {
          (<HTMLInputElement>element).classList.add(`${++gq}`);
        });
      });

      setTimeout(() => {
        highlightTable();
      }, 0);

      const tds = document.querySelectorAll('tbody tr td:first-child input[type=checkbox]');
      tds.forEach((element: Element) => {
        includeOrExcludeListTmp.forEach((o: Record<string, any>) => {
          setTimeout(() => {
            let eleArr1: string;
            if (o[uniqueKey.value] === parseInt((<HTMLInputElement>element).value)) {
              if (isSelectedAll.value) {
                (<HTMLInputElement>element).checked = false;
                eleArr1 = element.classList[1];
                const selectTableRow = document.querySelectorAll(`tbody tr.selected-${eleArr1}`);
                selectTableRow.forEach((el: Element) => {
                  (<HTMLInputElement>el).removeAttribute('style');
                });
              } else {
                (<HTMLInputElement>element).checked = true;
                eleArr1 = element.classList[1];
                const selectTableRow = document.querySelectorAll(`tbody tr.selected-${eleArr1}`);
                selectTableRow.forEach((el: Element) => {
                  (<HTMLInputElement>el).style.backgroundColor = '#EFF5FA';
                });
              }
            }
          }, 10);
        });
      });
    });
  });

  return { isSelectedAll, includeOrExcludeList, onSelectedAll, onSelectedRow };
}
