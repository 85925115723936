<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 0.728516C17.2239 0.728516 19.8865 1.53623 22.1513 3.04952C24.4161 4.5628 26.1813 6.7137 27.2237 9.2302C28.2661 11.7467 28.5388 14.5158 28.0074 17.1873C27.476 19.8588 26.1644 22.3127 24.2383 24.2388C22.3123 26.1648 19.8583 27.4765 17.1868 28.0079C14.5153 28.5393 11.7462 28.2666 9.22971 27.2242C6.71321 26.1818 4.56231 24.4166 3.04903 22.1518C1.53574 19.887 0.728027 17.2244 0.728027 14.5005C0.728027 10.848 2.179 7.34499 4.76175 4.76224C7.3445 2.17949 10.8475 0.728516 14.5 0.728516Z"
      fill="#E0523F"
    />
    <path
      d="M16 8.5C16 7.67157 15.3284 7 14.5 7C13.6716 7 13 7.67157 13 8.5V16.5C13 17.3284 13.6716 18 14.5 18C15.3284 18 16 17.3284 16 16.5V8.5Z"
      fill="white"
    />
    <path
      d="M14.5 22C15.3284 22 16 21.3284 16 20.5C16 19.6716 15.3284 19 14.5 19C13.6716 19 13 19.6716 13 20.5C13 21.3284 13.6716 22 14.5 22Z"
      fill="white"
    />
  </svg>
</template>
