// ==== Dynamic Filter ==== //
export function getDynamicFormattedString(formattedString: string) {
  const [filterGroup, filterIdStr, filterValue, colIdStr] = formattedString.split('_');
  return { filterGroup, filterIdStr, filterValue, colIdStr };
}

export function getDynamicFormattedStringName(formattedString: string): string {
  const [filterGroup, filterIdStr, filterValue, colIdStr] = formattedString.split('_');
  return `${filterGroup}/${filterValue}`;
}

export function getDynamicLabelValue(formattedString: string, dynamicFilterOptions: Bi.Filter.DynamicOptions | null): string | undefined {
  if (!dynamicFilterOptions) return undefined;

  // Split the formatted string into its components
  // Sample [32_ChocoCrmPos_138]
  const { filterGroup, filterIdStr, filterValue, colIdStr } = getDynamicFormattedString(formattedString);

  // const filterId = parseInt(filterIdStr);
  const colId = parseInt(colIdStr);

  // Find the filter object that matches the filterId and filterValue
  const filter = dynamicFilterOptions?.options.find(
    (f) => getDynamicFormattedString(f.id).filterIdStr == filterIdStr && getDynamicFormattedString(f.id).filterValue == filterValue,
  );

  if (!filter) {
    return undefined;
  }

  // root filter
  if (colId == 0) {
    return `${filter.label} : ทั้งหมด`;
  }

  // Find the data object that matches the colId
  const data = filter.children?.find((d) => d.id == formattedString);

  return data?.label;
}

export function extractDynamicPayload(formattedStringList: string[], dynamicFilterOptions: Bi.Filter.DynamicOptions): string[] {
  const childrenDynamicFilter: string[] = [];

  formattedStringList.forEach((v) => {
    const parsedStr = getDynamicFormattedString(v);
    if (parseInt(parsedStr.colIdStr) == 0) {
      const filters = dynamicFilterOptions.options.find(
        (f) => parseInt(getDynamicFormattedString(f.id).filterIdStr) == parseInt(parsedStr.filterIdStr),
      );

      if (filters && filters.children) {
        childrenDynamicFilter.push(...filters.children.map((v) => v.id));
      }
    } else {
      childrenDynamicFilter.push(v);
    }
  });

  return childrenDynamicFilter;
}

export function extractDynamicTooltip(formattedStringList: string[], dynamicFilterOptions: Bi.Filter.DynamicOptions | null): string {
  const tooltips: string[] = [];

  formattedStringList.forEach((v) => {
    const parsedStr = getDynamicFormattedString(v);

    // Check if colIdStr is 0
    if (parseInt(parsedStr.colIdStr) === 0) {
      const filter = dynamicFilterOptions?.options.find(
        (f) =>
          getDynamicFormattedString(f.id).filterIdStr === parsedStr.filterIdStr &&
          getDynamicFormattedString(f.id).filterValue === parsedStr.filterValue,
      );

      if (filter && filter.children) {
        tooltips.push(...filter.children.map((child) => child.label));
      }
    }
  });

  return tooltips.join(', ');
}
