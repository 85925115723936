export default function segmentModel() {
  const fetchGetTableList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetTableList`,
    method: 'GET',
    payload: {},
  };
  const fetchGetDataDisplayList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetDataDisplayList`,
    method: 'POST',
    payload: {
      Ordering: null!,
      Table_Id: null!,
      Filters: null!,
      Page: null!,
      Limit: null!,
      Search_Value: null!,
    },
  };
  const fetchCountRecord: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/CountRecord`,
    method: 'POST',
    payload: {
      Ordering: null!,
      Table_Id: null!,
      Filters: null!,
      Page: null!,
      Limit: null!,
      Search_Value: null!,
    },
  };
  const fetchFolderListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetFolderList`,
    method: 'POST',
    payload: {},
  };
  const fetchSegmentListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentList`,
    method: 'POST',
    payload: {
      Ordering: [],
      Folder_Id: null!,
      Segment_Type: [], //list segment type
      Filters: [],
      Page: null!,
      Limit: null!,
      Search_Value: null!,
    },
  };
  const createFolderModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/CreateFolder`,
    method: 'POST',
    payload: {
      Folder_Name: null!,
      Parent_Folder_Id: null!,
    },
  };
  const fetchEditFolder: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/EditFolder`,
    method: 'POST',
    payload: {
      Folder_Id: null!,
      Folder_Name: null!,
    },
  };
  const fetchDeleteFolder: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/DeleteFolder`,
    method: 'POST',
    payload: {
      Folder_Id: null!,
    },
  };
  const fetchDeleteSegment: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/DeleteSegment`,
    method: 'POST',
    payload: {
      SegmentId: null!,
    },
  };
  const fetchExportSegmentData: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/ExportSegmentData`,
    method: 'POST',
    payload: {
      Table_List: null!,
    },
    responseType: 'blob',
  };
  const createPreviewDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/CreatePreviewData`,
    method: 'POST',
    payload: {
      rule_set_list: null!,
      view_table_list: null!,
      view_column_list: null!,
    },
  };
  const fetchSegmentPreviewDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentPreviewData`,
    method: 'POST',
    payload: {
      Preview_Ref: null!,
    },
  };
  // Detail Panel
  const fetchSourcesDetailModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetSourceDetail`,
    method: 'GET',
    payload: {},
  };

  const fetchSaveSegmentModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/SaveSegment`,
    method: 'POST',
    payload: {},
  };

  const fetchSegmentResultModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentResult`,
    method: 'POST',
    payload: {},
  };

  const fetchSegmentDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/FetchSegmentData`,
    method: 'POST',
    payload: {
      SegmentId: null!,
    },
  };

  const fetchGetSegmentConfigModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentConfig`,
    method: 'POST',
    payload: {
      SegmentId: null!,
    },
  };

  const fetchGetUniqueData: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/GetUniqueData`,
    method: 'POST',
    payload: {},
  };

  return {
    fetchGetTableList,
    fetchGetDataDisplayList,
    fetchCountRecord,
    fetchFolderListModel,
    fetchSegmentListModel,
    createFolderModel,
    fetchEditFolder,
    fetchDeleteFolder,
    fetchDeleteSegment,
    fetchExportSegmentData,
    createPreviewDataModel,
    fetchSegmentPreviewDataModel,
    // Detail
    fetchSourcesDetailModel,
    // Apply
    fetchSaveSegmentModel,
    fetchSegmentResultModel,
    fetchSegmentDataModel,
    fetchGetSegmentConfigModel,
    fetchGetUniqueData,
  };
}
