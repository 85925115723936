import { onMounted, ref, Ref, reactive, watch, toRaw } from 'vue';
import api from '@services/api';
import segmentModal from '@/models/segment/segmentModal';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import AllFolderModel from '@/models/segment/taballsegment';
import useLoading from '@/views/components/loading/hooks/useLoading';
import router from '@/router';
import segmentTabs from '@models/segment/segmentTabs';
import { useI18n } from 'vue-i18n';

interface Props {
  dataSource?: Segment.Datasource;
  isOpen: boolean;
  title?: string;
  isSelectSegment?: boolean;
  isViewData?: boolean;
  selectedViewMode?: number;
  isCopyTo?: boolean;
}

enum ViewModeType {
  Generic = 0,
  Customer = 1,
  Product = 2,
  Transaction = 3,
  GroupBy = 9,
}

export default function useDialogSegment(props: Props) {
  const { isLoading } = useLoading();
  const { fetchSegmentTabsModel } = segmentTabs();
  const { openDefaultErrorModal } = useValidationModal();
  const { fetchFolderForModal, fetchSegmentForModal } = segmentModal();
  const { createAllFolderListModel } = AllFolderModel();
  const { t } = useI18n();

  const selected: Record<string, any> = ref({
    folder: null,
    segment: null,
  });
  const textFolderName: Ref<string> = ref('');
  const isOpenCreateFolder: Ref<boolean> = ref(false);
  const isLoadingFolder: Ref<boolean> = ref(false);
  const isLoadingTable: Ref<boolean> = ref(false);
  const isBlockSearch: Ref<boolean> = ref(false);
  const folderList: Ref<Segment.Folder[]> = ref([]);
  const segmentName: Ref<string> = ref('');
  const countSelect: Ref<number> = ref(0);
  const segmentFolder: Ref<Segment.Segment[]> = ref([]);
  const segmentFolderFilter: Ref<Segment.Segment[]> = ref([]);
  const isToggleSelect: Ref<boolean> = ref(false);
  const selectItemViewData: Record<string, any> = ref([]);
  const optionItemViewData: Record<string, any> = ref([]);
  const dataSourceDisplay: Ref<Segment.Datasource[]> = ref([]);

  const outputValue: Record<string, any> = ref({
    Name: '',
    folder: { folderId: 0, label: '' },
    segment: { segmentId: 0, label: '' },
    TableId: 0,
    col_type_flag: 0,
    group_type: 0,
  });

  const field: BaseTable.TableColumn[] = [
    { key: 'name', label: t('segment.components.dialog_segment.table.segment_name') },
    { key: 'type', label: t('segment.components.dialog_segment.table.segment_type') },
  ];

  const fetchFolderModal = () => {
    isLoadingFolder.value = true;
    fetchFolderForModal.payload.root_tableId = props.isViewData
      ? outputValue.value.TableId
      : props.dataSource != undefined || props.dataSource != null
      ? props.dataSource.tableid
      : 20;
    api
      .apiRequest(fetchFolderForModal)
      .then((response) => {
        const responseFolder = response.data.payload;
        folderList.value = responseFolder.map((Folder: { folderid: number; name: string }) => ({ folderid: Folder.folderid, name: Folder.name }));
      })
      .catch((err) => {
        isLoadingFolder.value = false;
        openDefaultErrorModal(err);
      })
      .finally(() => {
        if (props.isViewData && folderList.value.length != 0) {
          selected.value.folder = folderList.value[0].folderid;
          outputValue.value.folder.folderid = folderList.value[0].folderid;
          outputValue.value.folder.name = folderList.value[0].name;
        } else {
          selected.value.folder = router.currentRoute.value.query.folderId;
          outputValue.value.folder.folderid = router.currentRoute.value.query.folderId;
          outputValue.value.folder.name = router.currentRoute.value.query.folderName;
        }
        isLoadingFolder.value = false;
        fetchSegmentModal();
      });
  };

  const fetchSegmentModal = () => {
    isLoadingTable.value = true;
    fetchSegmentForModal.payload.ref_type = 1;
    fetchSegmentForModal.payload.reference = selected.value.folder;

    api
      .apiRequest(fetchSegmentForModal)
      .then((response) => {
        const responseSegment = response.data.segment_list;
        segmentFolder.value = responseSegment;
        if (props.isCopyTo == true) {
          segmentFolderFilter.value = responseSegment.filter((val: any) => {
            return val.type == 3;
          });
        } else {
          segmentFolderFilter.value = segmentFolder.value;
        }
      })
      .catch((err) => {
        openDefaultErrorModal(err);
        isLoadingTable.value = false;
      })
      .finally(() => {
        isLoadingTable.value = false;
      });
  };

  const onCreateFolder = () => {
    isOpenCreateFolder.value = true;
  };

  const onCloseFolderModal = () => {
    isOpenCreateFolder.value = false;
  };

  const onSubmitCreateFolder = () => {
    isLoading(true);
    createAllFolderListModel.payload.Root_TableId = props.isViewData
      ? outputValue.value.TableId
      : props.dataSource != undefined || props.dataSource != null
      ? props.dataSource.tableid
      : 20;
    createAllFolderListModel.payload.name = textFolderName.value;
    createAllFolderListModel.payload.group_type = props.isViewData ? outputValue.value.group_type : 1;

    api
      .apiRequest(createAllFolderListModel)
      .then(() => {})
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isOpenCreateFolder.value = false;
        textFolderName.value = '';
        fetchFolderModal();
        isLoading(false);
      });
  };

  const resolveSegmentType = (type: Segment.SegmentType) => {
    switch (type) {
      case 1:
        return 'Batch';
      case 2:
        return 'Realtime';
      case 3:
        return 'Custom';
    }
  };

  const onKeyInput = (event: Event) => {
    outputValue.value.Name = (event.target as HTMLInputElement).value;
    const selectTableRow = document.querySelectorAll('#segment-name');
    selectTableRow.forEach((el: Element) => {
      if ((<HTMLInputElement>el).value) {
        (<HTMLInputElement>el).classList.add('value-active');
      } else {
        (<HTMLInputElement>el).classList.remove('value-active');
      }
    });
  };

  const onSelectedFolder = (val: { folderid: number; name: string }) => {
    selected.value.folder = val.folderid;
    outputValue.value.folder = val;
    fetchSegmentModal();
  };

  const onSelectedSegment = (val: Segment.Segment, classList: string) => {
    selected.value.segment = val.segmentid;
    outputValue.value.segment.segmentId = val.segmentid;
    outputValue.value.segment.label = val.name;
    countSelect.value++;

    const rowSelectItem = document.querySelectorAll(`tbody tr.${classList}`);
    rowSelectItem.forEach((elClass: Element) => {
      (<HTMLInputElement>elClass).classList.toggle('selected');
    });
  };

  const onInputSearchChanged = (event: Event) => {
    const filterList: any = [];

    if ((event.target as HTMLInputElement).value == '') {
      segmentFolderFilter.value = segmentFolder.value;
    } else {
      if (segmentFolder.value.length > 0) {
        segmentFolder.value.map((column) => {
          if (column.name.toLowerCase().indexOf((event.target as HTMLInputElement).value) > -1) {
            filterList.push({
              name: column.name,
              type: column.type,
            });
          }
        });
      }
      segmentFolderFilter.value = filterList;
    }
  };

  const convertDataSubmit = () => {
    const payload = {
      segmentName: outputValue.value.Name,
      folderSelect: {
        folderId: outputValue.value.folder.folderid,
        label: outputValue.value.folder.name,
      },
      segmentSelect: {
        segmentId: outputValue.value.segment.segmentId,
        label: outputValue.value.segment.label,
      },
      TableId: outputValue.value.TableId,
      column_type_flags: outputValue.value.col_type_flag,
    };
    return payload;
  };

  const onSelectedItemViewData = (select: Segment.Datasource[]) => {
    selectItemViewData.value = select;
    outputValue.value.col_type_flag = toRaw(selectItemViewData.value.column_type_flags);
    outputValue.value.group_type = toRaw(selectItemViewData.value.group_type);
    isToggleSelect.value = false;
  };

  const fetchDataSourceList = () => {
    api
      .apiRequest(fetchSegmentTabsModel)
      .then((response) => {
        dataSourceDisplay.value = response.data.payload;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        fetchDefualtViewData();
      });
  };

  const fetchDefualtViewData = () => {
    const filterOption: any = dataSourceDisplay.value.filter((obj: Segment.Datasource) => {
      return obj.column_type_flags == (props.selectedViewMode == ViewModeType.Transaction ? 4 : props.selectedViewMode);
    });

    selectItemViewData.value = filterOption[0];
    outputValue.value.TableId = toRaw(filterOption[0].tableid);
    outputValue.value.col_type_flag = toRaw(filterOption[0].column_type_flags);
    outputValue.value.group_type = toRaw(filterOption[0].group_type);

    fetchFolderModal();
  };

  watch(
    () => countSelect.value,
    (countSelectNew) => {
      setTimeout(() => {
        const watchSelectRow = document.querySelectorAll('tbody tr');
        watchSelectRow.forEach((watchNow: Element) => {
          const watchFolder = document.querySelectorAll('.select-folder');
          watchFolder.forEach((folderSection: Element) => {
            if (countSelectNew % 2 != 0) {
              if (!(<HTMLInputElement>watchNow).classList.contains('selected')) {
                (<HTMLInputElement>watchNow).classList.add('unselected');
                (<HTMLInputElement>folderSection).classList.add('folder-locked');
                isBlockSearch.value = true;
              }
            } else {
              (<HTMLInputElement>watchNow).classList.remove('unselected');
              (<HTMLInputElement>watchNow).classList.remove('selected');
              (<HTMLInputElement>folderSection).classList.remove('folder-locked');
              isBlockSearch.value = false;
            }
          });
        });
      }, 10);
    },
    { deep: true },
  );

  watch(
    () => props.isOpen,
    () => {
      if (props.isOpen && !props.isViewData) {
        fetchFolderModal();

        if (router.currentRoute.value.query.folderId != undefined) {
          selected.value.folder = router.currentRoute.value.query.folderId;
          outputValue.value.folder = { folderId: router.currentRoute.value.query.folderId, label: router.currentRoute.value.query.folderName };

          fetchSegmentModal();
        }
      } else if (props.isViewData && props.isOpen) {
        fetchDataSourceList();
      } else {
        isToggleSelect.value = false;
        outputValue.value.segment = {
          segmentId: 0,
          label: '',
        };
      }
    },
    { deep: true },
  );

  return {
    folderList,
    field,
    segmentFolder,
    segmentFolderFilter,
    selected,
    isLoadingTable,
    isLoadingFolder,
    segmentName,
    outputValue,
    textFolderName,
    isOpenCreateFolder,
    isBlockSearch,
    isToggleSelect,
    selectItemViewData,
    optionItemViewData,
    onInputSearchChanged,
    onKeyInput,
    onCreateFolder,
    onCloseFolderModal,
    onSubmitCreateFolder,
    resolveSegmentType,
    onSelectedFolder,
    onSelectedSegment,
    convertDataSubmit,
    onSelectedItemViewData,
  };
}
