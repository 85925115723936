import { onMounted, ref, Ref } from 'vue';
import type { EChartsOption } from 'echarts';

export default function useTestEChartsView() {
  const option: Ref<EChartsOption> = ref({});

  option.value = {
    series: [
      {
        type: 'treemap',
        roam: false,
        nodeClick: undefined,
        data: [
          {
            name: '7%',
            value: 7,
          },
          {
            name: '23%',
            value: 23,
          },
          {
            name: '70%',
            value: 70,
          },
        ]
      },
    ],
  };

  return { option };
}
