import { getCurrentInstance, onBeforeMount, Ref, ref, watch, reactive } from 'vue';
import { DateTime } from 'luxon';
import { EChartsOption } from 'echarts';

// API
import api from '@services/api';
import analyticsModel from '@models/analytics';

//libs
import { useI18n } from 'vue-i18n';

// constant
import { COLOR_INDEX, DATA_INDEX } from '@/constants/components/chart/chart-summarybox';
import { GEOMAP, HEATMAP, HORIZONTAL_BAR, INFOGRAPHIC, RADAR } from '@/constants/components/chart/base-chart';
import { DEFUALT_TOTAL_SUFFIX_FROM_ITEM_INDEX } from '@/constants/components/chart/chart-infographic';
import {
  CHURNRATE_STAGE as churnRateStageList,
  CHURNRATE_STAGE_ALL as churnRateStageListAll,
} from '@/constants/modules/analytics/churn-rate-chart/churn-rate-chart';

// components
import ChartsInfoGraphic from '@views/components/chart/ChartInfoGraphic.vue';
import ChartsTable from '@views/components/chart/ChartTable.vue';
import ChartsCustomerBanner from '@views/components/chart/ChartCustomerBanner.vue';
import ChartUnresolved from '@views/components/chart/ChartUnresolved.vue';
import CustomChart from '@views/components/chart/CustomChart.vue';
import ChartSummaryBox from '@views/components/chart/ChartSummaryBox.vue';
import ChartGEOMap from '@views/components/chart-map/CustomChartMap.vue';
import ChartScatter from '@views/components/chart/ChartScatter.vue';
import ChartTreemap from '@views/components/chart/ChartTreemap.vue';
import ChartBarStackPagination from '@views/components/chart/ChartBarStackPagination.vue';
import ChartBarWithLine from '@views/components/chart/ChartBarWithLine.vue';

import ExtraEmpty from '@views/modules/analytics/components/bi-chart/ExtraEmpty.vue';
import ExtraTopLegend from '@views/modules/analytics/components/bi-chart/ExtraTopLegend.vue';
import ExtraTopTotal from '@views/modules/analytics/components/bi-chart/ExtraTopTotal.vue';
import ExtraRightLegend from '@views/modules/analytics/components/bi-chart/ExtraRightLegend.vue';
import ExtraRightTotal from '@views/modules/analytics/components/bi-chart/ExtraRightTotal.vue';
import ExtraLeftLegend from '@views/modules/analytics/components/bi-chart/ExtraLeftLegend.vue';

import useValidationModal from '@views/components/modal/hooks/useValidationModal';
import { chartTypes } from '@/constants/components/chart/chart-types';

import {
  chartsPropsToEChartsOptionsMapper,
  rawDataToCustomerBannerMapper,
  rawDataToChartTableColumn,
  rawDataToChartTableData,
  rawDataToChartsInfoGraphic,
} from '../../../utils/bi-mapper';

import { extractDynamicPayload } from '../../../utils/bi-utils';

interface ExtraTopLegendItem {
  label: string;
  value: number;
  color: string;
}

interface ExtraRightLegendItem {
  label: string;
  value: number;
  color: string;
}

interface ExtraLeftLegendItem {
  label: string;
  value: number;
  color: string;
}

interface Props {
  chartLayoutId: number;
  width: number;
  height: number;
  index: string;
  sourceList: { label: string; value: string }[];
  mainStartDate: Date | null;
  mainEndDate: Date | null;
  mainRelativeModel: string | null;
  isMainFilterChange: boolean;
  useDynamicFilter: boolean;
  filterList: any[];
  filterChartSourceOptions: Bi.Filter.DynamicOptions | null;
  filterChartSegmentOptions: Bi.Filter.DynamicOptions | null;
}

interface ChurnRateStage {
  id: number;
  label: string;
  ids: number[];
}

// TODO: refactor to constant file
const DEFAULT_TEXT_TITLE_COLOR = 'black';
const DEFAULT_ICON_COLOR = '#93adc1';
const DEFAULT_BACKGROUND_COLOR = 'white';
const DEFAULT_DATE_PICKER_COLOR = 'grey';

export default function (
  props: Props,
  componentWidth: Ref<number>,
  componentHeight: Ref<number>,
  churnRateStage: Ref<ChurnRateStage>,
  churnRateStageAll: Ref<ChurnRateStage>,
) {
  const vm = getCurrentInstance()?.proxy;
  const chartProps: Ref<Charts.ChartsProps> = ref({
    chartId: null,
    chartSetId: null,
    title: '',
    tooltip: '',
    chartsType: 'none',
    options: {},
    graphRatioWidth: 100,
    graphRatioHeight: 100,
    implType: null,
    implKeyRef: '',
    extra: {
      left: {
        show: false,
        type: '',
      },
      top: {
        show: false,
        type: '',
      },
      bottom: {
        show: false,
        type: '',
      },
      right: {
        show: false,
        type: '',
      },
    },
    colorList: [],
    rawData: {
      title: '',
      columns: [],
      data: [],
      filter: {
        dateTime: false,
        source: false,
        store: false,
        dynamic_filter: [],
      },
    },
  });
  const { t } = useI18n();
  const loading: Ref<boolean> = ref(false);
  const isValidRawData: Ref<boolean> = ref(false);
  const textTitleColor: Ref<string> = ref(DEFAULT_TEXT_TITLE_COLOR);
  const iconColor: Ref<string> = ref(DEFAULT_ICON_COLOR);
  const backgroundColor: Ref<string> = ref(DEFAULT_BACKGROUND_COLOR);
  const datePickerColor: Ref<string> = ref(DEFAULT_DATE_PICKER_COLOR);
  const isClickElem: Ref<boolean> = ref(false);
  const startDate = ref<Date | null>(null);
  const endDate = ref<Date | null>(null);
  const relativeModel = ref<string | null>(null);
  const currentDynamicFilter = ref<string[]>([]);
  const filterIdList = ref<any[]>([]);
  const filterChartSource = ref<string[]>([]);
  const filterChartSegment = ref<string[]>([]);
  const segmentCount = ref<string[]>([]);
  const oldPage = ref<number>(0);

  // Reactive filter variables
  const isFilterSegment: Ref<boolean> = ref(false);
  const isFilterStage: Ref<boolean> = ref(false);
  const isFilterStageAll: Ref<boolean> = ref(false);
  const isFilterSource: Ref<boolean> = ref(false);
  const isFilterChurn: Ref<boolean> = ref(false);

  // Mapping for filter group names to reactive variables
  const filterMapping: Record<string, Ref<boolean>> = {
    Source: isFilterSource,
    Segment: isFilterSegment,
    Stage: isFilterStage,
    StageAll: isFilterStageAll,
    LosingCustomer: isFilterChurn,
  };

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 5,
    totalRows: 0,
  });

  const { fetchAnalyticsChartDataModel } = analyticsModel();

  const { openErrorModal } = useValidationModal();

  // TODO: move this function to utils mapper
  function chartPropsMapper(input: Charts.ChartsProps.Request.Response): Charts.ChartsProps {
    return {
      chartId: input.chartid,
      chartSetId: input.chartsetid,
      chartsType: input.type_name,
      title: input.chart_name || 'Not found, chart name',
      tooltip: input.chart_desc || 'Not found, tooltip',
      options: <EChartsOption>input.options,
      graphRatioHeight: props.useDynamicFilter && currentDynamicFilter.value.length ? input.ratio_height - 10 : input.ratio_height,
      graphRatioWidth: input.ratio_width,
      implType: input.impl_type,
      implKeyRef: input.impl_key_ref,
      extra: {
        top: {
          show: !!input.extra.extra_top,
          type: input.extra.extra_top,
        },
        left: {
          show: !!input.extra.extra_left,
          type: input.extra.extra_left,
        },
        right: {
          show: !!input.extra.extra_right,
          type: input.extra.extra_right,
        },
        bottom: {
          show: !!input.extra.extra_bottom,
          type: input.extra.extra_bottom,
        },
      },
      colorList: input.color_list
        ? input.color_list.map((el) => ({
            seq: el.seq,
            colorId: el.colorid,
            colorName: el.colorname,
            hexColor: el.hex_color,
          }))
        : [],
      rawData: {
        title: input.rawdata?.title || 'Not found, chart name',
        data: input.rawdata?.data,
        filter: {
          dateTime: input.rawdata?.filter.date_time,
          source: input.rawdata?.filter.source,
          store: input.rawdata?.filter.store,
          dynamic_filter: input.rawdata?.filter.dynamic_filter,
        },
      },
    };
  }

  const dropDownStage = churnRateStageList.map((item) => {
    item.label = t(item.label);
    return item;
  });

  const dropDownStageAll = churnRateStageListAll.map((item) => {
    item.label = t(item.label);
    return item;
  });

  function isRawDataValid(rawData: Charts.ChartsProps.RawData, chartType: string): boolean {
    switch (chartType) {
      case chartTypes.HEATMAP: {
        return rawData.data[HEATMAP.RAW_DATA_INDEX].datalist.length !== 0;
      }
      case chartTypes.HORIZONTAL_BAR: {
        return rawData.data[HORIZONTAL_BAR.RAW_DATA_INDEX].yaxis.length !== 0;
      }
      case chartTypes.BOX: {
        return true;
      }
      default: {
        if (!rawData.data) {
          return false;
        }

        if (Array.isArray(rawData.data) && rawData.data.length === 0) {
          return false;
        }

        return !(rawData.data && Object.keys(rawData.data).length === 0 && Object.getPrototypeOf(rawData.data) === Object.prototype);
      }
    }
  }

  function textTitleColorMapper(colorList: Charts.ChartsProps.ColorItem[], chartType: string): string {
    switch (chartType) {
      case chartTypes.BOX: {
        return colorList[COLOR_INDEX.TEXT_COLOR_INDEX].hexColor;
      }
      default: {
        return DEFAULT_TEXT_TITLE_COLOR;
      }
    }
  }

  function iconColorMapper(colorList: Charts.ChartsProps.ColorItem[], chartType: string): string {
    switch (chartType) {
      case chartTypes.BOX: {
        return colorList[COLOR_INDEX.TEXT_COLOR_INDEX].hexColor;
      }
      default: {
        return DEFAULT_ICON_COLOR;
      }
    }
  }

  function datePickerColorMapper(colorList: Charts.ChartsProps.ColorItem[], chartType: string): string {
    switch (chartType) {
      case chartTypes.BOX: {
        return colorList[COLOR_INDEX.TEXT_COLOR_INDEX].hexColor;
      }
      default: {
        return DEFAULT_DATE_PICKER_COLOR;
      }
    }
  }

  function backgroundColorMapper(colorList: Charts.ChartsProps.ColorItem[], chartType: string): string {
    switch (chartType) {
      case chartTypes.BOX: {
        return colorList[COLOR_INDEX.BACKGROUND_COLOR_INDEX].hexColor;
      }
      default: {
        return DEFAULT_BACKGROUND_COLOR;
      }
    }
  }

  async function fetchOptionGraphById(chartId: number): Promise<void> {
    fetchAnalyticsChartDataModel.payload.ChartLayoutId = chartId;
    fetchAnalyticsChartDataModel.payload.Filters = [
      {
        Key: 'startDate',
        Value: startDate.value ? DateTime.fromISO(startDate.value.toISOString()).toFormat('yyyy-MM-dd') : '',
      },
      {
        Key: 'endDate',
        Value: endDate.value ? DateTime.fromISO(endDate.value.toISOString()).toFormat('yyyy-MM-dd') : '',
      },
    ];

    // Filter Source
    if (filterChartSource.value.length > 0) {
      fetchAnalyticsChartDataModel.payload.Filters.push({
        Key: 'source',
        Value: filterChartSource.value,
      });
    } else {
      fetchAnalyticsChartDataModel.payload.Filters.push({
        Key: 'source',
        Value: props.sourceList.map((item) => item.value),
      });
    }

    // Filter Segment
    if (filterChartSegment.value.length > 0) {
      segmentCount.value = props.filterChartSegmentOptions ? extractDynamicPayload(filterChartSegment.value, props.filterChartSegmentOptions) : [];
      fetchAnalyticsChartDataModel.payload.Filters.push({
        Key: 'segment',
        Value: props.filterChartSegmentOptions ? extractDynamicPayload(filterChartSegment.value, props.filterChartSegmentOptions) : [],
      });
    }

    /// Check and set default value
    if (churnRateStage.value.id === 0) {
      churnRateStage.value.ids = churnRateStageList.filter((item) => item.id !== 0).map((item) => item.id);
    }

    // Filter Stage
    if (churnRateStage.value) {
      fetchAnalyticsChartDataModel.payload.Filters.push({
        Key: 'stage',
        Value: churnRateStage.value.id.toString(),
      });
    }

    // Filter Stage All
    if (churnRateStageAll.value) {
      fetchAnalyticsChartDataModel.payload.Filters.push({
        Key: 'stageAll',
        Value: churnRateStageAll.value.id.toString(),
      });
    }

    if (chartTypes.STACK_BAR_PAGINATION) {
      fetchAnalyticsChartDataModel.payload.Filters.push({
        Key: 'currentPage',
        Value: pagination.currentPage.toString(),
      });
      oldPage.value = pagination.currentPage;
    }

    loading.value = true;
    return api
      .apiRequest(fetchAnalyticsChartDataModel)
      .then((response) => {
        chartProps.value = chartPropsMapper(response.data.result as Charts.ChartsProps.Request.Response);

        if (chartProps.value.implKeyRef === 'Test') isValidRawData.value = true;
        else isValidRawData.value = isRawDataValid(chartProps.value.rawData, chartProps.value.chartsType);

        if (chartProps.value.colorList) {
          textTitleColor.value = textTitleColorMapper(chartProps.value.colorList, chartProps.value.chartsType);
          iconColor.value = iconColorMapper(chartProps.value.colorList, chartProps.value.chartsType);
          datePickerColor.value = datePickerColorMapper(chartProps.value.colorList, chartProps.value.chartsType);
          backgroundColor.value = backgroundColorMapper(chartProps.value.colorList, chartProps.value.chartsType);
        }
        const filterList = props.filterList;
        filterIdList.value = chartProps.value.rawData.filter.dynamic_filter;

        // Check if dynamicFilterId is not null or undefined
        if (filterIdList.value) {
          const filteredList = filterList.filter((filter) => filterIdList.value.includes(filter.filterid as number));

          // Update filter status using filterMapping
          filteredList.forEach((item) => {
            const filterRef = filterMapping[item.filter_groupname];
            if (filterRef) {
              filterRef.value = true;
            }
          });
        }

        if (chartTypes.STACK_BAR_PAGINATION) {
          if (filterChartSegment.value.length) {
            pagination.totalRows = segmentCount.value.length;
          } else {
            if (chartProps.value.rawData.data.length) pagination.totalRows = chartProps.value.rawData.data[0].total;
          }

          pagination.perPage = 5;
        }

        return Promise.resolve();
      })
      .catch((ex) => {
        //TODO: change error modal to alert toast
        console.log('Error:', ex);

        return Promise.reject();
      })
      .finally(() => {
        vm?.$emit('on-fetched', props.chartLayoutId);

        loading.value = false;
      });
  }

  function resolveByChartType() {
    switch (chartProps.value.chartsType) {
      case chartTypes.LINE:
      case chartTypes.STRAIGHT_LINE:
      case chartTypes.PIE:
      case chartTypes.BAR:
      case chartTypes.HORIZONTAL_BAR:
      case chartTypes.VERTICAL_BAR:
      case chartTypes.VERTICAL_STACK_BAR:
      case chartTypes.RADAR:
      case chartTypes.HEATMAP:
      case chartTypes.GAUGE:
        return CustomChart;
      case chartTypes.BOX:
        return ChartSummaryBox;
      case chartTypes.PROFILE:
        return ChartsCustomerBanner;
      case chartTypes.TABLE:
        return ChartsTable;
      case chartTypes.INFOGRAPHIC_AGE:
      case chartTypes.INFOGRAPHIC_GENDER:
        return ChartsInfoGraphic;
      case chartTypes.GEOMAP:
        return ChartGEOMap;
      case chartTypes.TREEMAPP:
        return ChartTreemap;
      case chartTypes.STACK_BAR_PAGINATION:
        return ChartBarStackPagination;
      case chartTypes.SCAATTER:
        return ChartScatter;
      case chartTypes.BAR_WITH_LINE:
        return ChartBarWithLine;
      case chartTypes.NONE:
        return ChartUnresolved;
      default:
        return ChartUnresolved;
    }
  }

  function getLength(number: number) {
    return number.toString().length;
  }

  //Double chart to show Data Info
  const onDoubleClickChart = () => {
    vm?.$emit('on-click-data-info', props.chartLayoutId, chartProps.value, startDate.value, endDate.value);
  };

  function resolveProps(): object {
    switch (chartProps.value.chartsType) {
      case chartTypes.LINE:
      case chartTypes.STRAIGHT_LINE:
      case chartTypes.PIE:
      case chartTypes.BAR:
      case chartTypes.HORIZONTAL_BAR:
      case chartTypes.VERTICAL_BAR:
      case chartTypes.VERTICAL_STACK_BAR:
      case chartTypes.RADAR:
      case chartTypes.HEATMAP:
      case chartTypes.GAUGE:
        return {
          options: chartsPropsToEChartsOptionsMapper(
            chartProps.value.chartsType,
            chartProps.value.rawData,
            chartProps.value.colorList,
            <EChartsOption>chartProps.value.options,
          ),
          height: Number(props.height) * Number(chartProps.value.graphRatioHeight),
          // width: Number(props.width) * Number(chartProps.value.graphRatioWidth),
          responsiveWidth: true,
          // responsiveHeight: true,
          eventType: 'dblclick',
          function: () => onDoubleClickChart(),
        };
      case 'box':
        return {
          number: (() => {
            try {
              return chartProps.value.rawData.data[DATA_INDEX.VALUE].value;
            } catch (e) {
              return 0;
            }
          })(),
          subtitle: (() => {
            try {
              return chartProps.value.rawData.data[DATA_INDEX.VALUE].title;
            } catch (e) {
              return '-';
            }
          })(),
          color: (() => {
            try {
              return chartProps.value.colorList[COLOR_INDEX.TEXT_COLOR_INDEX].hexColor;
            } catch (e) {
              return 'white';
            }
          })(),
          /* fontSize: `calc(${String(componentWidth.value / 100) + 'rem'} - ${
            getLength(<number>chartProps.value.rawData.data[DATA_INDEX.VALUE].value) * 1.5
          }px)`, don't use */
          isShowInfoAble: true,
        };
      case chartTypes.PROFILE:
        return {
          customers: rawDataToCustomerBannerMapper(chartProps.value.rawData),
          loading: false,
          autoRatio: true,
          isShowInfoAble: true,
        };
      case chartTypes.TABLE:
        return {
          columns: rawDataToChartTableColumn(chartProps.value.rawData, props.chartLayoutId),
          data: rawDataToChartTableData(chartProps.value.rawData, props.chartLayoutId),
          // TODO: declare type for chart "table" and mapping, not use "is_pagination" directly
          isPagination: chartProps.value.rawData.data[0].is_pagination,
          isShowInfoAble: true,
        };
      case chartTypes.INFOGRAPHIC_AGE:
      case chartTypes.INFOGRAPHIC_GENDER:
        const isNullTypePresent = chartProps.value.rawData.data.some((item: any) => item.type === INFOGRAPHIC.RAW_DATA_NULL);
        return {
          rawData: chartProps.value.rawData,
          items: rawDataToChartsInfoGraphic(chartProps.value.rawData, chartProps.value.colorList, chartProps.value.options),
          suffix: chartProps.value.rawData.data[DEFUALT_TOTAL_SUFFIX_FROM_ITEM_INDEX].suffix,
          autoRatio: true,
          isShowInfoAble: true,
          isShowExtraTopNoData: isNullTypePresent,
        };
      case chartTypes.GEOMAP:
        return {
          options: chartsPropsToEChartsOptionsMapper(
            chartProps.value.chartsType,
            chartProps.value.rawData,
            chartProps.value.colorList,
            <EChartsOption>chartProps.value.options,
          ),
          height: Number(props.height) * Number(chartProps.value.graphRatioHeight),
          width: Number(props.width) * Number(chartProps.value.graphRatioWidth),
          responsiveWidth: true,
          responsiveHeight: true,
          eventType: 'dblclick',
          function: () => onDoubleClickChart(),
        };
      case chartTypes.TREEMAPP:
        return {
          rawData: chartProps.value.rawData,
          colorList: chartProps.value.colorList.filter((item) => item.colorName === churnRateStage.value.label),
          height: Number(props.height) * Number(chartProps.value.graphRatioHeight),
          width: Number(props.width) * Number(chartProps.value.graphRatioWidth),
          responsiveWidth: true,
          responsiveHeight: true,
        };
      case chartTypes.STACK_BAR_PAGINATION:
        return {
          rawData: chartProps.value.rawData,
          colorList:
            churnRateStageAll.value.id == 0
              ? chartProps.value.colorList
              : chartProps.value.colorList.filter((item) => item.colorName === churnRateStageAll.value.label),
          height: Number(props.height) * Number(chartProps.value.graphRatioHeight),
          width: Number(props.width) * Number(chartProps.value.graphRatioWidth),
          responsiveWidth: true,
          responsiveHeight: true,
          pagination: pagination,
        };
      case chartTypes.SCAATTER:
        return {
          rawData: chartProps.value.rawData,
          colorList: chartProps.value.colorList.filter((item) => item.colorName === churnRateStage.value.label),
          height: Number(props.height) * Number(chartProps.value.graphRatioHeight),
          width: Number(props.width) * Number(chartProps.value.graphRatioWidth),
          responsiveWidth: true,
          responsiveHeight: true,
        };
      case chartTypes.BAR_WITH_LINE:
        return {
          rawData: chartProps.value.rawData,
          colorList: chartProps.value.colorList,
          height: Number(props.height) * Number(chartProps.value.graphRatioHeight),
          width: Number(props.width) * Number(chartProps.value.graphRatioWidth),
          responsiveWidth: true,
          responsiveHeight: true,
        };
      case chartTypes.NONE:
        return {};
      default:
        return {};
    }
  }

  const resolveEmits = (): object => {
    let itemEmits = {} as object;
    switch (chartProps.value.chartsType) {
      case chartTypes.STACK_BAR_PAGINATION:
        itemEmits = {
          ['on-page-change']: (page: number) => {
            if (typeof onPageChange === 'function') {
              onPageChange(page);
            }
          },
        };
        break;
      default:
        itemEmits = {
          ['on-click-data-info']: () => vm?.$emit('on-click-data-info', chartProps.value.chartId, chartProps.value, startDate.value, endDate.value),
        };
    }
    return itemEmits;
  };

  function resolveExtraTopComponent() {
    const { show, type } = chartProps.value.extra.top;
    const { chartsType } = chartProps.value;

    if (!show) {
      return ExtraEmpty;
    }

    if (chartsType === chartTypes.PIE) {
      if (type === 'extra-top-legend') {
        return ExtraTopLegend;
      } else if (type === 'extra-top-total') {
        return ExtraTopTotal;
      }
    }

    return ExtraEmpty;
  }

  function resolveExtraTopProps() {
    const { show, type } = chartProps.value.extra.top;
    const { chartsType, rawData, colorList } = chartProps.value;

    if (!show) {
      return;
    }

    if (chartsType === chartTypes.PIE && (type === 'extra-top-legend' || type === 'extra-top-total')) {
      const items = rawData.data.map((el: any, index: number) => ({
        label: el.data,
        value: el.value,
        color: colorList[index].hexColor,
      }));

      return {
        items: items as ExtraTopLegendItem[],
      };
    }
  }

  function resolveExtraRightComponent() {
    if (!chartProps.value.extra.right.show) {
      return ExtraEmpty;
    }

    switch (chartProps.value.chartsType) {
      case chartTypes.RADAR:
      case chartTypes.PIE:
        switch (chartProps.value.extra.right.type) {
          case 'extra-right-legend':
            return ExtraRightLegend;
          case 'extra-right-total':
            return ExtraRightTotal;
          default:
            return ExtraEmpty;
        }
      default:
        return ExtraEmpty;
    }
  }

  function resolveExtraRightProps() {
    if (!chartProps.value.extra.right.show) {
      return;
    }

    const { chartsType, extra, rawData, colorList } = chartProps.value;
    const { type } = extra.right;

    switch (chartsType) {
      case chartTypes.RADAR:
        if (type === 'extra-right-legend') {
          return {
            items: rawData.data[RADAR.RAW_DATA_INDEX].data.map((el: any, index: number) => ({
              label: el.name,
              value: el.value.reduce((a: any, b: any) => a + b, 0),
              color: colorList[index].hexColor,
            })),
          };
        }
        break;

      case chartTypes.PIE:
        if (type === 'extra-right-legend' || type === 'extra-right-total') {
          return {
            items: rawData.data.map((el: any, index: number) => ({
              label: el.data,
              value: el.value,
              color: colorList[index].hexColor,
            })),
          };
        }
        break;

      default:
        return;
    }
  }

  function resolveExtraLeftComponent() {
    const { show, type } = chartProps.value.extra.left;
    const { chartsType } = chartProps.value;

    if (!show) {
      return ExtraEmpty;
    }

    if (chartsType === chartTypes.GEOMAP) {
      return type === 'extra-left-legend' ? ExtraLeftLegend : ExtraEmpty;
    }

    return ExtraEmpty;
  }

  function resolveExtraLeftProps() {
    const { show, type } = chartProps.value.extra.left;
    const { chartsType, rawData, colorList } = chartProps.value;

    if (!show) {
      return;
    }

    if (chartsType === chartTypes.GEOMAP && type === 'extra-left-legend') {
      const itemsWithValues = rawData.data
        .map((el: any) => ({
          label: el.name,
          value: el.value,
        }))
        .sort((a: any, b: any) => b.value - a.value) // Sort by value descending
        .slice(0, 5); // Take top 5

      const color = colorList[GEOMAP.RAW_DATA_INDEX]?.hexColor || '#000000'; // Fallback color if index is out of bounds

      const sortedItems = itemsWithValues.map((item: any) => ({
        ...item,
        color,
      }));

      return {
        items: sortedItems as ExtraLeftLegendItem[],
      };
    }
  }

  const onClickElemInChart = () => {
    const elem = document.getElementById('grid-' + props.index) as HTMLElement;
    elem.style.zIndex = String(1);
    isClickElem.value = true;
  };

  const onOutClickElemInChart = async () => {
    if (isClickElem.value) {
      const elem = document.getElementById('grid-' + props.index) as HTMLElement;
      elem.style.zIndex = 'unset';
      isClickElem.value = false;

      await fetchOptionGraphById(props.chartLayoutId);
    }
  };

  const onClickSeeMoreFilter = () => {
    vm?.$emit('see-more-filter', props.chartLayoutId, chartProps.value, filterChartSource.value, props.filterChartSourceOptions);
  };

  const onPageChange = (page: number) => {
    pagination.currentPage = page;

    if (oldPage.value !== page) onClickElemInChart();
    onOutClickElemInChart();
  };

  watch(
    [() => props.mainStartDate, () => props.mainEndDate, () => props.mainRelativeModel],
    () => {
      if (props.mainStartDate == null) {
        startDate.value = null;
      } else {
        startDate.value = new Date(props.mainStartDate);
      }

      if (props.mainEndDate == null) {
        endDate.value = null;
      } else {
        endDate.value = new Date(props.mainEndDate);
      }

      relativeModel.value = props.mainRelativeModel;
    },
    { immediate: true },
  );

  watch([() => props.isMainFilterChange], async () => {
    if (!props.isMainFilterChange) {
      await fetchOptionGraphById(props.chartLayoutId);
    }
  });

  onBeforeMount(async () => {
    await fetchOptionGraphById(props.chartLayoutId);
  });

  return {
    DEFAULT_TEXT_TITLE_COLOR,
    DEFAULT_ICON_COLOR,
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_DATE_PICKER_COLOR,
    textTitleColor,
    iconColor,
    datePickerColor,
    backgroundColor,
    isValidRawData,
    chartProps,
    startDate,
    endDate,
    relativeModel,
    currentDynamicFilter,
    filterChartSource,
    filterChartSegment,
    loading,
    fetchOptionGraphById,
    onClickElemInChart,
    onOutClickElemInChart,
    resolveByChartType,
    resolveProps,
    resolveExtraTopComponent,
    resolveExtraTopProps,
    resolveExtraRightComponent,
    resolveExtraRightProps,
    resolveExtraLeftComponent,
    resolveExtraLeftProps,
    resolveEmits,
    isFilterSource,
    isFilterSegment,
    isFilterStage,
    isFilterStageAll,
    isFilterChurn,
    onClickSeeMoreFilter,
    dropDownStage,
    dropDownStageAll,
  };
}
