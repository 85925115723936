export const Status = {
  InActive: 0,
  Active: 1,
};
export const RelationType = {
  Undefined: 1,
  OneToOne: 2,
  OneToMany: 3,
  ManyToOne: 4,
};
export const RelationDirection = {
  BiDirection: 1,
  LeftToRight: 2,
  RightToLeft: 3,
};

export enum TableColumnMapping {
  LeftTable = 0,
  RightTable = 1,
}
