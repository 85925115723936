import router from '@/router';
import api from '@/services/api';
import useConvertDate from '@/utils/hooks/useConvertDate';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useSelectRow from '@/views/components/table/base-table/hooks/useSelectRowV2';
import saveAs from 'file-saver';
import { computed, reactive, Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import resultModel from '@/models/segment/result';
import dynamicTableModel from '@/models/component/table/dynamicTable';
import exportModel from '@/models/segment/exportReport';

import { ResultListType, OldSegmentType } from '@/constants/modules/segment-v2/segment-result';

export default function (resultListType: ResultListType, loading: Ref<boolean>, segment: SegmentResult.OldSegment) {
  const { t } = useI18n();

  const { isLoading } = useLoading();

  const { openDefaultErrorModal } = useValidationModal();

  const { convertDate } = useConvertDate();
  const lastUpdate: Ref<string> = ref('');
  let computeLastUpdateInterval: number = null!;
  const setLastUpdateIntervalRealtime = (updateDate: string) => {
    const currentDateTime = new Date().getTime();
    const resultDate = new Date(updateDate).getTime() + (currentDateTime - new Date(updateDate).getTime());

    if (computeLastUpdateInterval !== null) clearInterval(computeLastUpdateInterval);
    computeLastUpdateInterval = window.setInterval(() => {
      lastUpdate.value = convertDate(new Date(resultDate).toISOString());
    }, 1000);
  };
  const setLastUpdateInterval = (updateDate: string) => {
    const addHours = new Date(updateDate).getTime() + 7 * 60 * 60 * 1000;
    const resultDate = new Date(addHours).toISOString();

    if (computeLastUpdateInterval !== null) clearInterval(computeLastUpdateInterval);
    computeLastUpdateInterval = window.setInterval(() => {
      lastUpdate.value = convertDate(resultDate);
    }, 1000);
  };

  const { fetchSegmentResultModel, fetchSegmentFetchModel } = resultModel();
  function refreshBatch() {
    clearInterval(computeLastUpdateInterval);
    loading.value = true;
    fetchSegmentFetchModel.payload.SegmentId = router.currentRoute.value.query.segmentId;
    fetchSegmentFetchModel.payload.Search_Value = textSearch.value;
    fetchSegmentFetchModel.payload.Page = pagination.currentPage;
    fetchSegmentFetchModel.payload.Limit = pagination.perPage;
    fetchSegmentFetchModel.payload.Ordering = ordering.direction ? [ordering] : [];
    fetchSegmentFetchModel.payload.Filters = [];
    fetchSegmentFetchModel.payload.ResultType = 1;
    fetchSegmentFetchModel.payload.View_Select_Main = 0;

    api
      .apiRequest(fetchSegmentFetchModel)
      .then((response) => {
        findUniqueKey(response.data.datasourcedisplay.columns as PreviewSegment.DatasourceDisplayColumns[]);
        tableId.value = response.data.datasourcedisplay.tableid;
        dataList.value = response.data.segment_result;
        columns.value = response.data.datasourcedisplay.columns;
        pagination.totalRows = response.data.filtered_record;
        if (response.status == 'success') {
          setLastUpdateInterval(response.data.updated_dt as string);
          loading.value = false;
        }
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      });
  }
  function refreshRealtime() {
    fetchSegmentResultList();
  }

  const textSearch: Ref<string> = ref('');
  const controllers: AbortController[] = [];
  const retryCount: Ref<number> = ref(0);
  let searchDebounceTimeout: ReturnType<typeof setTimeout> = null!;
  watch(
    () => textSearch.value,
    () => {
      pagination.totalRows = 0;
      pagination.currentPage = 1;

      if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
      searchDebounceTimeout = setTimeout(() => {
        fetchSegmentResultList();
      }, 500);
    },
  );

  const { updateDisplayColumnModel } = dynamicTableModel();

  const { fetchSegmentExportReport } = exportModel();
  function exportSegment() {
    isLoading(true);

    fetchSegmentExportReport.payload.SegmentId = router.currentRoute.value.query.segmentId;
    fetchSegmentExportReport.payload.Search_Value = textSearch.value;
    fetchSegmentExportReport.payload.Page = pagination.currentPage;
    fetchSegmentExportReport.payload.Limit = 200000;
    fetchSegmentExportReport.payload.Ordering = ordering.direction ? [ordering] : [];
    fetchSegmentExportReport.payload.ResultType = 1;
    fetchSegmentExportReport.payload.View_Select_Main = 0;
    fetchSegmentExportReport.payload.Select_flag = 2; //1: Select for export, 2: Export all for this phase use 2 only

    api
      .apiRequest(fetchSegmentExportReport)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response as Blob,
          `Report-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
        );
      })
      .catch((error) => {
        openDefaultErrorModal(error);
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const selectedRowDesc = computed(() => {
    if (pagination.totalRows == 0) return '';
    if (includeOrExcludeList.value.length > 0) {
      return (
        t('segment.components.save_custom_dialog.table.segments_inbox_before') +
        selectedRowCount.value +
        t('segment.components.save_custom_dialog.table.segments_inbox_after')
      );
    }
    if (isSelectedAll.value == false && includeOrExcludeList.value.length > 0) {
      return includeOrExcludeList.value.length;
    }
    return t('segment.components.save_custom_dialog.table.clear_select');
  });
  const selectedRowCount = computed(() => {
    if (isSelectedAll.value == true) {
      return pagination.totalRows - includeOrExcludeList.value.length;
    } else {
      return includeOrExcludeList.value.length;
    }
  });
  function clearSelectedRow() {
    if (isSelectedAll.value == true && includeOrExcludeList.value.length == 0) {
      isSelectedAll.value = false;
      const thisRow = document.querySelectorAll('tbody tr');
      thisRow.forEach((el: Element) => {
        (<HTMLInputElement>el).removeAttribute('style');
      });
    }
  }

  const tableId: Ref<number> = ref(0);
  const dataSourceDisplay: Ref<PreviewSegment.DatasourceDisplay> = ref(null!);
  const columns: Ref<DynamicTable.Column[]> = ref([]);
  const dataList: Ref<Record<string, any>[]> = ref([]);
  const uniqueKey: Ref<string> = ref('');
  function findUniqueKey(_columns: PreviewSegment.DatasourceDisplayColumns[]): void {
    const found = _columns.find((el: PreviewSegment.DatasourceDisplayColumns) => el.is_primary_key === true);
    if (found) {
      uniqueKey.value = found.key;
    } else {
      throw 'uniqueKey not found';
    }
  }

  const { includeOrExcludeList, isSelectedAll, onSelectedAll, onSelectedRow } = useSelectRow(
    dataList,
    uniqueKey,
    ref(resultListType == ResultListType.Preview),
  );

  interface TableOrdering {
    seq: number;
    key: string;
    direction: string;
  }
  const ordering: TableOrdering = reactive({
    seq: 0,
    key: '',
    direction: '',
  });
  function onSort(sortedColumn: { key: string; direction: string }) {
    ordering.seq = 1;
    ordering.key = sortedColumn.key;
    ordering.direction = sortedColumn.direction;

    pagination.currentPage = 1;
    fetchSegmentResultList();
  }

  const paginationOptions: Ref<number[]> = ref([5, 10, 15, 20]);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });
  watch(
    () => pagination.perPage,
    () => {
      pagination.currentPage = 1;
      pagination.totalRows = 0;

      fetchSegmentResultList();
    },
  );
  watch(
    () => pagination.currentPage,
    () => {
      fetchSegmentResultList();
    },
  );

  function fetchSegmentResultList() {
    loading.value = true;
    controllers.push(new AbortController());
    for (let i = 0; i < controllers.length - 1; i++) controllers[i].abort();

    fetchSegmentResultModel.payload.SegmentId = router.currentRoute.value.query.segmentId;
    fetchSegmentResultModel.payload.Search_Value = textSearch.value;
    fetchSegmentResultModel.payload.Page = pagination.currentPage;
    fetchSegmentResultModel.payload.Limit = pagination.perPage;
    fetchSegmentResultModel.payload.Ordering = ordering.direction ? [ordering] : [];
    fetchSegmentResultModel.payload.Flag_Count = true;
    fetchSegmentResultModel.payload.ResultType = 1;
    fetchSegmentResultModel.payload.View_Select_Main = 0;
    fetchSegmentResultModel.signal = controllers[controllers.length - 1].signal;

    api
      .apiRequest(fetchSegmentResultModel)
      .then((response) => {
        tableId.value = response.data.datasourcedisplay.tableid;
        dataSourceDisplay.value = response.data.datasourcedisplay;
        columns.value = response.data.datasourcedisplay.columns;
        dataList.value = response.data.segment_result;
        findUniqueKey(response.data.datasourcedisplay.columns as PreviewSegment.DatasourceDisplayColumns[]);
        pagination.totalRows = response.data.filtered_record;
        segment.is_active_campaign = response.data.segment_is_lock;
        if (response.status == 'success') {
          if (resultListType == ResultListType.Result && segment.type == OldSegmentType.Realtime)
            setLastUpdateIntervalRealtime(response.data.updated_dt as string);
          if (resultListType == ResultListType.Result && segment.type != OldSegmentType.Realtime)
            setLastUpdateInterval(response.data.updated_dt as string);
          loading.value = false;
        }
      })
      .catch((error) => {
        ++retryCount.value;
        if (error.status != 422 && retryCount.value < 5) {
          openDefaultErrorModal(error, () => fetchSegmentResultList());
        } else {
          openDefaultErrorModal(error);
          loading.value = false;
        }
      })
      .finally(() => {
        highlightTable();
      });
  }
  function highlightTable() {
    const highlightSegmentList = JSON.parse(sessionStorage.getItem('highlightSegmentList') || '[]');

    highlightSegmentList.forEach((hightlightSegment: Record<string, any>) => {
      const value: string = hightlightSegment[uniqueKey.value];
      const checkbox = document.querySelector(`.form-check-input[type=checkbox][value="${value}"]`);
      if (checkbox) {
        const tableRow = checkbox.closest('tr');
        setTimeout(() => {
          if (tableRow) tableRow.style.backgroundColor = '#C7E6FF';
        }, 1000);
      }
    });

    sessionStorage.removeItem('highlightSegmentList');
  }

  function onRowClick(row: any) {
    if (row.hasOwnProperty('cid') && (row.cid !== null || row.cid !== undefined)) {
      router.push({ name: 'customerProfileDashboard', params: { cId: row.cid, pageId: 1 } });
    } else if (row.hasOwnProperty('cdp_cid') && (row.cdp_cid !== null || row.cdp_cid !== undefined)) {
      router.push({ name: 'customerProfileDashboard', params: { cId: row.cdp_cid, pageId: 1 } });
    }
  }

  return {
    lastUpdate,
    refreshRealtime,
    refreshBatch,
    textSearch,
    exportSegment,
    selectedRowDesc,
    selectedRowCount,
    clearSelectedRow,
    tableId,
    dataSourceDisplay,
    columns,
    dataList,
    uniqueKey,
    includeOrExcludeList,
    isSelectedAll,
    onSelectedAll,
    onSelectedRow,
    onSort,
    paginationOptions,
    pagination,
    fetchSegmentResultList,
    onRowClick,
  };
}
