import { onMounted, ref, Ref, watch, computed, toRaw } from 'vue';
import type { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { uid, calculateWidth, calculateFontSizeFromHeight, formatterLabel } from '../utils';

// Icon
import SeeMoreIcon from '@/assets/icons/modules/communication/dashboard-by-channel/see-more-icon.png';
import SMSIcon from '@/assets/icons/modules/communication/all-channel-tab/SMSIcon.png';
import LineOaIcon from '@/assets/icons/modules/communication/all-channel-tab/LineOaIcon.png';
import EmailIcon from '@/assets/icons/modules/communication/all-channel-tab/EmailIcon.png';
import MKAIcon from '@/assets/icons/modules/communication/all-channel-tab/MKAIcon.png';

// constant
import { CHANNEL_VALUE } from '@/views/modules/communication/pages/main/constants/channel';

// API
import api from '@services/api';
import analyticsModel from '@models/analytics';

const { fetchAnalyticsChartActivityCampaignModel } = analyticsModel();

interface Props {
  rawData?: Charts.ChartsProps.RawData;
  colorList: Charts.ChartsProps.ColorItem[];
  height?: number | string;
  width?: number | string;
  responsiveWidth?: boolean;
  responsiveHeight?: boolean;
  eventType?: string;
  function?: Function;
}

// send to component dashboard-seemore-modal
const chartLineSelectedFullDay: Ref<string> = ref('');
const chartLineSelectedHourRange: Ref<string> = ref('');
const hoursRange = ['00:00 - 05:59', '06:00 - 11:59', '12:00 - 17:59', '18:00 - 23:59'];
const chartLineSelectedDay: Ref<number> = ref(0);
const activityCampaignResp: Ref<ChurnRate.Campaign.Channel> = ref(null!);
const isDashboardSeeMoreModalOpen: Ref<boolean> = ref(false);
const chartLineSelectedSeeMoreChannel: Ref<number> = ref(0);
const subStartDateTime: Ref<string | Date> = ref('');
const subEndDateTime: Ref<string | Date> = ref('');

const messagesAll: Ref<number> = ref(0);
const smsMessage: Ref<number> = ref(0);
const smsCampign: Ref<number> = ref(0);
const lineMessage: Ref<number> = ref(0);
const lineCampign: Ref<number> = ref(0);
const emailMessage: Ref<number> = ref(0);
const emailCampign: Ref<number> = ref(0);
const mkaMessage: Ref<number> = ref(0);
const mkaCampign: Ref<number> = ref(0);

// const { openDefaultErrorModal } = useValidationModal();

export default function (props: Props) {
  const chart = ref();
  const id = ref();
  id.value = uid();
  const option: Ref<EChartsOption> = ref({});

  const resolveHeight = computed(() => {
    return props.responsiveHeight ? '100%' : typeof props.height === 'number' ? `${props.height}px` : props.height;
  });

  const resolveWidth = computed(() => {
    return props.responsiveWidth ? '100%' : typeof props.width === 'number' ? `${props.width}px` : props.width;
  });

  // Map data
  const data = props.rawData?.data?.[0];
  if (!data) return;

  const name = data.data;
  const timeSeries = data.xaxis;
  const customer: number[] = data.yaxis_bar ?? [];
  const communication: number[] = data.yaxis_line_1 ?? [];
  const mka: number[] = data.yaxis_line_2 ?? [];

  const totalCustomer = customer.reduce((acc, value) => acc + value, 0);
  const totalCommunication = communication.reduce((acc, value) => acc + value, 0);
  const totalMka = mka.reduce((acc, value) => acc + value, 0);

  const customerPercentages = customer.map((value: number) => parseFloat(((value / totalCustomer) * 100).toFixed(2)));
  const communicationPercentages = communication.map((value: number) => parseFloat(((value / totalCommunication) * 100).toFixed(2)));
  const mkaPercentages = mka.map((value: number) => parseFloat(((value / totalMka) * 100).toFixed(2)));

  const nameArray: string[] = Object.values(name) as string[];

  const color = props.colorList?.map((item: any) => item.hexColor) ?? [];

  const init = () => {
    const dom = document.getElementById(id.value) as HTMLElement;
    if (!dom) return;

    chart.value = echarts.init(dom);

    option.value = {
      tooltip: {
        show: true,
        trigger: 'item',
        triggerOn: 'click',
        formatter: function (params: any) {
          if (params.seriesType === 'bar') {
            const color = params.color;
            const originalCustomerValue = customer[params.dataIndex];

            return `
              <span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${color};"></span>
              Number of Churn Customer: ${originalCustomerValue}
            `;
          } else {
            if (params.seriesName === 'Communication') {
              // ใช้ HTML ในการกำหนดรูปแบบของ Tooltip
              return `<div id="line-tooltip" class="line-tooltip">
            <div style="width: 400px; height: auto; cursor: default;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: #fff; padding: 0 0 7px 0">
                <div class="line-tooltip-day" style="font-size: 13px; color: #606A6C; margin-bottom: 12px;">Mon</div>
                <div class="tooltip-total-customer" style="font-size: 20px; color: black; margin-bottom: 3px; font-weight: bold;">0</div>
                <div id="line-tooltip-custom-classifier" style="font-size: 12px; color: black;">Message</div>
              </div>
              <table style="width: max-content; min-width: 100%; border-spacing: 0px !important;">
                <thead>
                  <tr style="background-color: #f7f7f7; text-align: center;">
                    <th style="padding: 7px 0;">Channel</th>
                    <th>Campaign</t>
                    <th id="line-tooltip-custom-column">Message</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style="text-align: center; background-color: #fff;">
                  <tr>
                    <td style="padding: 10px 0; color: black;"><img src="${SMSIcon}" style="height:15px"></img> SMS </td>
                    <td class="sms-tooltip-campaign" style="padding: 10px 0; color: black;">0</td>
                    <td class="sms-tooltip-total-customer" style="padding: 10px 0; color: black;">0</td>
                    <td><div id="sms-tooltip-button" class="line-tooltip-button-container"><img src="${SeeMoreIcon}"></img>
                    <span class="tooltiptext">See more</span></div></td>
                  </tr>
                  <tr>
                    <td style="padding: 10px 0; color: black;"><img src="${LineOaIcon}" style="height:15px"></img> Line </td>
                    <td class="line-tooltip-campaign" style="padding: 10px 0; color: black;">0</td>
                    <td class="line-tooltip-messages" style="padding: 10px 0; color: black;">0</td>
                    <td><div id="line-tooltip-button" class="line-tooltip-button-container"><img src="${SeeMoreIcon}"></img>
                    <span class="tooltiptext">See more</span></div></td>
                  </tr>
                  <tr>
                  <td style="padding: 10px 0; color: black;"><img src="${EmailIcon}" style="height:15px; padding-left:5px"></img> Email </td>
                  <td class="email-tooltip-campaign" style="padding: 10px 0; color: black;">0</td>
                  <td class="email-tooltip-messages" style="padding: 10px 0; color: black;">0</td>
                  <td><div id="email-tooltip-button" class="line-tooltip-button-container"><img src="${SeeMoreIcon}"></img>
                  <span class="tooltiptext">See more</span></div></td>
                </tr>
                <tbody>
              </table>
            </div>
          </div>`;
            } else {
              // ใช้ HTML ในการกำหนดรูปแบบของ Tooltip
              return `<div id="line-tooltip" class="line-tooltip">
            <div style="width: 400px; height: auto; cursor: pointer;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: #fff; padding: 0 0 7px 0">
                <div class="line-tooltip-day" style="font-size: 13px; color: #606A6C; margin-bottom: 12px;">Mon</div>
                <div class="mka-tooltip-total-customer" style="font-size: 20px; color: black; margin-bottom: 3px; font-weight: bold;">0</div>
                <div id="line-tooltip-custom-classifier" style="font-size: 12px; color: black;">Message</div>
              </div>
              <table style="width: max-content; min-width: 100%; border-spacing: 0px !important;">
                <thead>
                  <tr style="background-color: #f7f7f7; text-align: center;">
                    <th style="padding: 7px 0;">Channel</th>
                    <th>Campaign</t>
                    <th id="line-tooltip-custom-column">Message</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style="text-align: center; background-color: #fff;">
                  <tr>
                    <td style="padding: 10px 0; color: black;"><img src="${MKAIcon}" style="height:15px"></img> MKA </td>
                    <td class="mka-tooltip-campaign" style="padding: 10px 0; color: black;">0</td>
                    <td class="mka-tooltip-messages" style="padding: 10px 0; color: black;">0</td>
                    <td><div id="mka-tooltip-button" class="line-tooltip-button-container"><img src="${SeeMoreIcon}"></img>
                    <span class="tooltiptext">See more</span></div></td>
                  </tr>
                <tbody>
              </table>
            </div>
          </div>`;
            }
          }
          // Return empty string if the series is not of type 'bar'
          return '';
        },
      },
      legend: {
        data: nameArray,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          data: timeSeries,
        },
      ],
      yAxis: [
        {
          type: 'value',
          scale: true,
          min: 0,
          max: 100,
          name: data.yaxis_label_left || 'Left Axis',
          axisLabel: {
            formatter: '{value}%',
          },
        },
        {
          type: 'value',
          scale: true,
          min: 0,
          max: 100,
          name: data.yaxis_label_right || 'Right Axis',
          axisLabel: {
            formatter: '{value}%',
          },
        },
      ],
      series: [
        {
          name: name[0],
          type: 'bar',
          data: customerPercentages,
          itemStyle: {
            color: color[0],
          },
          yAxisIndex: 0,
        },
        {
          name: name[1],
          type: 'line',
          data: communicationPercentages,
          itemStyle: {
            color: color[1],
          },
          yAxisIndex: 1,
        },
        {
          name: name[2],
          type: 'line',
          data: mkaPercentages,
          itemStyle: {
            color: color[2],
          },
          yAxisIndex: 1,
        },
      ],
    };

    try {
      chart.value = echarts.init(dom);
      chart.value.setOption(toRaw(option.value));
      let initResize = false;

      setTimeout(() => {
        initResize = true;
        chart.value.resize();
      }, 1000);

      new ResizeObserver(() => {
        if (initResize) chart.value.resize();
      }).observe(dom);

      if (props.eventType) {
        chart.value.on(props.eventType, props.function);
      }
    } catch (e) {
      //  TODO: this exception will happen when using with vue <transition>
    }
  };

  function setLineEvent() {
    let dataIndex: number;
    let prevDataIndex: number;
    chart.value.on('click', (params: any) => {
      chart.value
        .getDom()
        .querySelectorAll('.line-tooltip-day')
        .forEach((ele: any) => {
          ele.innerHTML = params.name;
        });
      dataIndex = params.dataIndex;
      if (dataIndex !== prevDataIndex) {
        prevDataIndex = dataIndex;
        chartLineSelectedFullDay.value = resolveFullDay(params.name as string);
        resolveHeatmapSelectedDay_HourRange(params?.data[0] as number, params?.data[1] as number);
        // fetchDeliveryRangeCell(chartKey, chartLineChart, params?.data[2] as number);
        // fetchOpenRangeCell(chartKey, chartLineChart, params?.data[2] as number);
        // if (chartKey === CHARTS_KEY.DELIVERY_RANGE) {
        fetchAnalyticsChartActivityCampaign(params.name as string);
        // }
        chart.value.dispatchAction({
          type: 'showTip',
        });
      } else {
        // if (chartKey === CHARTS_KEY.DELIVERY_RANGE) {
        //   setDeliveryRangeChartTooltipValue(chartLineChart, params?.data[2] as number);
        // }
        // setDeliveryRangeChartTooltipValue(chartLineChart, params?.data[2] as number);
        dataIndex = null!;
        prevDataIndex = null!;
        chart.value.dispatchAction({
          type: 'hideTip',
        });
      }
    });

    chart.value.on('showTip', () => {
      const chartLineTooltip = chart.value?.getDom().querySelector('#line-tooltip') as HTMLDivElement;
      const chartLineTooltipParent = chartLineTooltip?.parentElement;
      if (chartLineTooltipParent) {
        chartLineTooltipParent.style.pointerEvents = 'auto';
        chartLineTooltipParent.style.padding = '7px 0';
        chartLineTooltipParent.style.borderRadius = '9px';
      }
    });
    chart.value.on('hideTip', () => {
      dataIndex = null!;
      prevDataIndex = null!;
    });
  }

  async function fetchAnalyticsChartActivityCampaign(dateTime: string) {
    // const isoDateTime = new Date(dateTime).toISOString();
    // const { startDate, endDate } = getStartAndEndWithMilliseconds(isoDateTime);

    // fetchAnalyticsChartActivityCampaignModel.payload.Filters = [
    //   {
    //     Key: 'startDate',
    //     Value: startDate,
    //   },
    //   {
    //     Key: 'endDate',
    //     Value: endDate,
    //   },
    // ];

    const startDate = new Date(dateTime);
    startDate.setHours(startDate.getHours() + 7);
    const startDateString = startDate.toISOString().split('T')[0] + 'T00:00:00';

    const endDate = new Date(dateTime);
    endDate.setHours(endDate.getHours() + 7);
    const endDateString = endDate.toISOString().split('T')[0] + 'T23:59:59';

    subStartDateTime.value = startDateString;
    subEndDateTime.value = endDateString;

    fetchAnalyticsChartActivityCampaignModel.payload.Filters = [
      {
        Key: 'startDate',
        Value: startDateString,
      },
      {
        Key: 'endDate',
        Value: endDateString,
      },
    ];

    await api.apiRequest(fetchAnalyticsChartActivityCampaignModel).then((response) => {
      if (response && response.status === 'success' && response.data && response.data.result) {
        activityCampaignResp.value = {
          Message: response.data.result.message ?? 0,
          Sms_Message: response.data.result.sms_message ?? 0,
          Sms_Campaign: response.data.result.sms_campaign ?? 0,
          Line_Message: response.data.result.line_message ?? 0,
          Line_Campaign: response.data.result.line_campaign ?? 0,
          Email_Message: response.data.result.email_message ?? 0,
          Email_Campaign: response.data.result.email_campaign ?? 0,
          Mka_Message: response.data.result.mka_message ?? 0,
          Mka_Campaign: response.data.result.mka_campaign ?? 0,
        };
        messagesAll.value = activityCampaignResp.value.Message;
        smsMessage.value = activityCampaignResp.value.Sms_Message;
        smsCampign.value = activityCampaignResp.value.Sms_Campaign;
        lineMessage.value = activityCampaignResp.value.Line_Message;
        lineCampign.value = activityCampaignResp.value.Line_Campaign;
        emailMessage.value = activityCampaignResp.value.Email_Message;
        emailCampign.value = activityCampaignResp.value.Email_Campaign;
        mkaMessage.value = activityCampaignResp.value.Mka_Message;
        mkaCampign.value = activityCampaignResp.value.Mka_Campaign;
      }

      setEventChartTooltipValue(chart.value);
    });
  }

  function setEventChartTooltipValue(lineChart: echarts.ECharts) {
    const updateTooltipText = (selector: string, value: number) => {
      lineChart
        .getDom()
        .querySelectorAll(selector)
        .forEach((item) => {
          item.innerHTML = value.toString();
        });
    };

    const setSeeMoreButton = (selector: string, channel: number, messageValue: number, campaignValue: number) => {
      if (messageValue && campaignValue) {
        const seeMoreButton = lineChart.getDom().querySelector(selector) as HTMLDivElement;
        if (seeMoreButton) {
          seeMoreButton.onclick = () => {
            isDashboardSeeMoreModalOpen.value = true;
            chartLineSelectedSeeMoreChannel.value = channel;
          };
          seeMoreButton.style.opacity = '1';
          seeMoreButton.style.pointerEvents = 'auto';
        }
      }
    };

    updateTooltipText('.tooltip-total-customer', messagesAll.value);
    updateTooltipText('.mka-tooltip-total-customer', mkaMessage.value);
    // Tooltip SMS
    updateTooltipText('.sms-tooltip-total-customer', smsMessage.value);
    updateTooltipText('.sms-tooltip-campaign', smsCampign.value);

    // Tooltip LINE
    updateTooltipText('.line-tooltip-messages', lineMessage.value);
    updateTooltipText('.line-tooltip-campaign', lineCampign.value);

    // Tooltip Email
    updateTooltipText('.email-tooltip-messages', emailMessage.value);
    updateTooltipText('.email-tooltip-campaign', emailCampign.value);

    // Tooltip MKA
    updateTooltipText('.mka-tooltip-messages', mkaMessage.value);
    updateTooltipText('.mka-tooltip-campaign', mkaCampign.value);

    // Set see more button
    setSeeMoreButton('#sms-tooltip-button', CHANNEL_VALUE.SMS, smsMessage.value, smsCampign.value);
    setSeeMoreButton('#line-tooltip-button', CHANNEL_VALUE.LINE, lineMessage.value, lineCampign.value);
    setSeeMoreButton('#email-tooltip-button', CHANNEL_VALUE.EMAIL, emailMessage.value, emailCampign.value);
    setSeeMoreButton('#mka-tooltip-button', CHANNEL_VALUE.MKA, mkaMessage.value, mkaCampign.value);
  }

  function resolveHeatmapSelectedDay_HourRange(dayIndex: number, hourIndex: number) {
    chartLineSelectedHourRange.value = hoursRange[hourIndex];
    chartLineSelectedDay.value = dayIndex;
  }

  function resolveFullDay(day: string) {
    switch (day) {
      case 'Mon':
        return 'Monday';
      case 'Tue':
        return 'Tuesday';
      case 'Wed':
        return 'Wednesday';
      case 'Thu':
        return 'Thursday';
      case 'Fri':
        return 'Friday';
      case 'Sat':
        return 'Saturday';
      case 'Sun':
        return 'Sunday';

      default:
        return 'Monday';
    }
  }

  onMounted(() => {
    init();
    setLineEvent();
  });

  watch(
    () => option.value,
    () => {
      if (chart.value) chart.value.setOption(toRaw(option.value));
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch([() => props.height, () => props.width], () => {
    setTimeout(() => {
      chart.value.resize();
    }, 100);
  });

  return {
    id,
    uid,
    calculateFontSizeFromHeight,
    formatterLabel,
    calculateWidth,
    option,
    resolveHeight,
    resolveWidth,
    isDashboardSeeMoreModalOpen,
    chartLineSelectedFullDay,
    chartLineSelectedSeeMoreChannel,
    chartLineSelectedDay,
    chartLineSelectedHourRange,
    subStartDateTime,
    subEndDateTime,
  };
}
