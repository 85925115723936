import useValidationModal from '@views/components/modal/hooks/useValidationModal';
import { Ref, ref, shallowRef } from 'vue';

import api from '@services/api';
import analyticsModel from '@models/analytics';

export interface Loading {
  layout: boolean;
  tabs: boolean;
  exportPdf: boolean;
  dataInfo: boolean;
  settingChart: boolean;
  delete: boolean;
  filter: boolean;
}
export interface SourceListOptions {
  label: string;
  value: string;
}

export interface DynamicListOptions {
  label: string;
  value: string;
}

export interface DynamicListFilter {
  filterId: number;
  filterValue: string;
  filterGroupName: string;
  filterOptions: DynamicListOptions[];
}

export function useAnalyticsChartFetch() {
  const { openErrorModal } = useValidationModal();

  const { fetchAnalyticsChartLayoutModel, fetchAnalyticsChartGlobalFilterListModel, fetchAnalyticsChartDynamicFilterListModel } = analyticsModel();

  const DEFAULT_DATE_FILTER = 'P7D';

  //filter
  const sourceListOptions: Ref<SourceListOptions[]> = ref([]);
  const sourceList: Ref<SourceListOptions[]> = ref([]);
  const filterList: Ref<SourceListOptions[]> = ref([]);
  const relativeModel: Ref<string | null> = ref(null);
  const currentTabName: Ref<string | null> = ref('');
  const dynamicFilterDataList: Ref<DynamicListFilter[]> = ref([]);

  //Initial layout for grid layout
  const layout: Ref<Bi.BiLayout.Layout[]> = shallowRef([]);
  const seen: Ref<boolean[]> = ref([]);
  const fetched: Ref<{ chartLayoutId: number; isFetched: boolean }[]> = ref([]);

  //loading
  const loading: Ref<Loading> = ref({
    layout: false,
    tabs: false,
    exportPdf: false,
    dataInfo: false,
    settingChart: false,
    delete: false,
    filter: false,
  });

  function fetchAnalyticsChartGlobalFilter(): Promise<void> {
    function mapAnalyticsChartFilter(input: SourceListOptions[]): SourceListOptions[] {
      return input.map((el) => ({
        label: el.label,
        value: el.value,
      }));
    }

    loading.value.filter = true;

    return api
      .apiRequest(fetchAnalyticsChartGlobalFilterListModel)
      .then((response) => {
        sourceList.value = mapAnalyticsChartFilter(response.data.result.global_source as SourceListOptions[]);
        sourceListOptions.value = mapAnalyticsChartFilter(response.data.result.global_source as SourceListOptions[]);

        // 90D
        // relativeModel.value = response.data.result.global_datetime || DEFAULT_DATE_FILTER;

        // 7D
        relativeModel.value = DEFAULT_DATE_FILTER;

        return Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject();
      })
      .finally(() => {
        loading.value.filter = false;
      });
  }

  async function fetchAnalyticsDynamicFilter(): Promise<void> {
    function mapAnalyticsDynamicFilterOption(input: any): DynamicListOptions[] {
      return input.map((el: any) => ({
        label: el.col_value,
        value: el.col_id,
      }));
    }

    loading.value.filter = true;

    return api
      .apiRequest(fetchAnalyticsChartDynamicFilterListModel)
      .then((response) => {
        const data = response.data;
        filterList.value = data;
        dynamicFilterDataList.value = data
          .filter((item: any) => item.filter_groupname === 'Segment')
          .map((item: any) => ({
            filterId: item.filterid,
            filterGroupName: item.filter_groupname,
            filterValue: item.filter_value,
            filterOptions: item.data ? mapAnalyticsDynamicFilterOption(item.data) : [],
          }));
        return Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject(error);
      })
      .finally(() => {
        loading.value.filter = false;
      });
  }

  function fetchLayoutAnalyticsChart(tabId: number): Promise<void> {
    function mapLayout(input: Bi.BiLayout.Request.Response.Layout[]): Bi.BiLayout.Layout[] {
      return <Bi.BiLayout.Layout[]>input.map((el: Bi.BiLayout.Request.Response.Layout) => ({
        x: el.x,
        y: el.y,
        w: el.w,
        h: el.h,
        i: `${el.i}`,
        static: false,
        chartLayoutId: el.chartid,
        minW: el.min_w,
        minH: el.min_h,
      }));
    }

    loading.value.layout = true;
    loading.value.tabs = true;
    fetchAnalyticsChartLayoutModel.payload.PageId = tabId;

    return api
      .apiRequest(fetchAnalyticsChartLayoutModel)
      .then((response) => {
        layout.value = mapLayout(response.data.payload.data_list as Bi.BiLayout.Request.Response.Layout[]);
        setAllSeen(false);
        mapLayoutItemFetchStatus(layout.value);
        currentTabName.value = response.data.page_name;
        return Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject();
      })
      .finally(() => {
        loading.value.layout = false;
        loading.value.tabs = false;
      });
  }

  const mapLayoutItemFetchStatus = (layout: Bi.BiLayout.Layout[]) => {
    fetched.value = layout.map((layoutItem) => ({
      chartLayoutId: parseInt(layoutItem.i),
      isFetched: false,
    }));
  };

  function onSeen(index: number) {
    seen.value[index] = true;
  }

  function setAllSeen(bool: boolean) {
    seen.value = layout.value.map(() => bool);
  }

  const onCustomChartContainerFetched = (chartLayoutId: number) => {
    const found = fetched.value.find((el) => chartLayoutId === el.chartLayoutId);
    if (found) {
      found.isFetched = true;
      return found;
    }
    throw 'custom chart container not found in fetched array';
  };

  return {
    currentTabName,
    layout,
    sourceList,
    sourceListOptions,
    relativeModel,
    loading,
    seen,
    fetched,
    filterList,
    fetchAnalyticsChartGlobalFilter,
    fetchAnalyticsDynamicFilter,
    fetchLayoutAnalyticsChart,
    setAllSeen,
    onSeen,
    onCustomChartContainerFetched,
    dynamicFilterDataList,
  };
}

export const GIRD_LAYOUT: { COL_NUMBERS: number; ROW_HEIGHT: number } = {
  COL_NUMBERS: 12,
  ROW_HEIGHT: 100,
};
