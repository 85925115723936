export default function dataRelationModel() {
  const fetchRelationListModel: API.RequestModel<DataRelation.RelationListPayload> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/GetRelationList`,
    method: 'POST',
    payload: {
      Search: '',
      Filters: [{ Seq: 1, Key: '', Value: '' }],
      Ordering: [{ Seq: 1, Key: '', Direction: '' }],
      Page: 1,
      Limit: 10,
    },
  };

  const deleteRelationModel: API.RequestModel<{ relation_id: number | null }> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/DeleteRelation`,
    method: 'POST',
    payload: {
      relation_id: null,
    },
  };

  const getSourceListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/GetSourceList`,
    method: 'GET',
    payload: {},
  };
  const getTableListModel: API.RequestModel<{ SourceId: number | null }> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/GetTableList`,
    method: 'GET',
    payload: {
      SourceId: null,
    },
  };
  const getColumnListModel: API.RequestModel<{ table_id: number | null }> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/GetColumnList`,
    method: 'GET',
    payload: {
      table_id: null,
    },
  };

  const validateExistRelationModel: API.RequestModel<{
    Left_Table_Id: number | null;
    Right_Table_Id: number | null;
  }> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/ValidateExistRelation`,
    method: 'POST',
    payload: {
      Left_Table_Id: null,
      Right_Table_Id: null,
    },
  };

  const createRelationModel: API.RequestModel<DataRelation.CreateRelationPayload> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/CreateRelation`,
    method: 'POST',
    payload: {
      Left_Table_Id: null,
      Right_Table_Id: null,
      Relation_Type: 1,
      Relation_Direction: 1,
      Left_Source_Id: null,
      Right_Source_Id: null,
      Column_Relations: [],
    },
  };
  const getRelationDetailModel: API.RequestModel<{ relationId: number | null }> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/GetRelationDetail`,
    method: 'GET',
    payload: {
      relationId: null,
    },
  };
  const editRelationModel: API.RequestModel<DataRelation.EditRelationPayload> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/EditRelation`,
    method: 'POST',
    payload: {
      Relation_Id: null,
      Column_Relations: [],
    },
  };
  const getRelationDetailOverall: API.RequestModel<{ Relation_Id: number | null }> = {
    endpoint: `${process.env.BASE_URL_DATA_RELATION!}/DataRelation/GetRelationDetailOverall`,
    method: 'POST',
    payload: {
      Relation_Id: null,
    },
  };
  return {
    fetchRelationListModel,
    deleteRelationModel,
    getSourceListModel,
    getTableListModel,
    getColumnListModel,
    validateExistRelationModel,
    createRelationModel,
    getRelationDetailModel,
    editRelationModel,
    getRelationDetailOverall,
  };
}
