import { ComponentPublicInstance, computed, nextTick, reactive, Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
// import saveAs from 'file-saver';

import router from '@/router';
import api from '@/services/api';

// import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useSelectRow from '@/views/components/table/base-table/hooks/useSelectRowV2';

import { mapToColumnsObject, defaultColumn } from '@/views/modules/segment-v2/utils';

import segmentModel from '@/models/segment-v2/segment';

import { ResultListType } from '@/constants/modules/segment-v2/segment-result';

import { useBuilderConfigStore } from '@/store/segmentV2Store';
import { useRouterBypassStore } from '@/store/routerStore';

export default function (resultListType: ResultListType, loading: Ref<boolean>, previewRef: string | null) {
  const { t } = useI18n();

  // const { isLoading } = useLoading();
  const configStore = useBuilderConfigStore();
  const routerStore = useRouterBypassStore();

  const { openDefaultErrorModal, openErrorModal } = useValidationModal();

  const { fetchSegmentPreviewDataModel } = segmentModel();

  const searchText: Ref<string> = ref('');
  const controllers: AbortController[] = [];
  let searchDebounceTimeout: ReturnType<typeof setTimeout> = null!;
  watch(
    () => searchText.value,
    () => {
      pagination.totalRows = 0;
      pagination.currentPage = 1;

      if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
      searchDebounceTimeout = setTimeout(() => {
        processDataList();
      }, 500);
    },
  );

  function exportSegment() {
    return openErrorModal('ขออภัย', 'ฟีเจอร์ยังไม่พร้อมใช้งาน', 'ตกลง');
  }

  const selectedRowDesc = computed(() => {
    if (pagination.totalRows == 0) return '';
    if (includeOrExcludeList.value.length > 0) {
      return (
        t('segment.components.save_custom_dialog.table.segments_inbox_before') +
        selectedRowCount.value +
        t('segment.components.save_custom_dialog.table.segments_inbox_after')
      );
    }
    if (isSelectedAll.value == false && includeOrExcludeList.value.length > 0) {
      return includeOrExcludeList.value.length;
    }
    return t('segment.components.save_custom_dialog.table.clear_select');
  });
  const selectedRowCount = computed(() => {
    if (isSelectedAll.value == true) {
      return pagination.totalRows - includeOrExcludeList.value.length;
    } else {
      return includeOrExcludeList.value.length;
    }
  });
  function clearSelectedRow() {
    if (isSelectedAll.value == true && includeOrExcludeList.value.length == 0) {
      isSelectedAll.value = false;
      const thisRow = document.querySelectorAll('tbody tr');
      thisRow.forEach((el: Element) => {
        (<HTMLInputElement>el).removeAttribute('style');
      });
    }
  }

  const previewTable: Ref<ComponentPublicInstance | null> = ref(null);
  const columns: Ref<PreviewSegment.DatasourceDisplayColumns[]> = ref([]);
  const allDataList: Ref<Record<string, any>[]> = ref([]);
  const dataList: Ref<Record<string, any>[]> = ref([]);
  const uniqueKey: Ref<string> = ref('');
  function findUniqueKey(_columns: PreviewSegment.DatasourceDisplayColumns[]): void {
    const found = _columns.find((el: PreviewSegment.DatasourceDisplayColumns) => el.is_primary_key === true);
    if (found) {
      uniqueKey.value = found.key;
    } else {
      // TODO require unique key
      columns.value.unshift(defaultColumn);
      allDataList.value.forEach((data, index) => {
        data.defaultcolumn = index + 1;
      });

      uniqueKey.value = defaultColumn.key;
    }
  }

  const { includeOrExcludeList, isSelectedAll, onSelectedAll, onSelectedRow } = useSelectRow(dataList, uniqueKey, ref(false));

  const ordering: SegmentResult.TableOrdering = reactive({
    seq: 0,
    key: '',
    direction: '',
  });
  function onSort(sortedColumn: { key: string; direction: string }) {
    ordering.seq = 1;
    ordering.key = sortedColumn.key;
    ordering.direction = sortedColumn.direction;

    pagination.currentPage = 1;
    processDataList();
  }

  const actualTotalRecord: Ref<number> = ref(0);
  const paginationOptions: Ref<number[]> = ref([5, 10, 15, 20]);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });
  const paginationKey: Ref<number> = ref(0);
  function reloadPagination() {
    paginationKey.value++;
  }
  watch(
    () => [pagination.perPage, pagination.currentPage],
    ([newPerPage, newCurrentPage], [oldPerPage, oldCurrentPage]) => {
      if (oldPerPage != newPerPage && newCurrentPage == oldCurrentPage) {
        pagination.currentPage = 1;
        pagination.totalRows = 0;
      }

      processDataList();
    },
  );

  function processDataList() {
    loading.value = true;
    let processedDataList = [...allDataList.value];

    if (searchText.value) {
      processedDataList = processedDataList.filter((item) => {
        return Object.values(item).some((value) => String(value).toLowerCase().includes(searchText.value.toLowerCase()));
      });
    }

    if (ordering.key) {
      processedDataList.sort((a, b) => {
        switch (ordering.direction) {
          case 'ASC':
            return a[ordering.key] < b[ordering.key] ? -1 : a[ordering.key] > b[ordering.key] ? 1 : 0;
          case 'DESC':
            return a[ordering.key] > b[ordering.key] ? -1 : a[ordering.key] < b[ordering.key] ? 1 : 0;
          case '':
            return 0;
          default:
            return 0;
        }
      });
    }

    const start = (pagination.currentPage - 1) * pagination.perPage;
    const end = start + pagination.perPage;
    dataList.value = processedDataList.slice(start, end);

    pagination.totalRows = processedDataList.length;
    reloadPagination();

    setTimeout(() => {
      loading.value = false;
    }, 10);
  }

  function fetchSegmentPreviewData() {
    loading.value = true;

    controllers.push(new AbortController());
    for (let i = 0; i < controllers.length - 1; i++) controllers[i].abort();

    fetchSegmentPreviewDataModel.payload.Preview_Ref = previewRef;
    api
      .apiRequest(fetchSegmentPreviewDataModel)
      .then((response) => {
        if (response.data.status == 'Success') {
          const tmpColumns: SegmentResult.DatasourceDisplayColumns[] = response.data.columns.map(
            (item: Partial<SegmentResult.DatasourceDisplayColumns>, index: number) => mapToColumnsObject(item, index),
          );
          columns.value = tmpColumns;
          allDataList.value = response.data.datas;
          actualTotalRecord.value = response.data.total_record;

          findUniqueKey(tmpColumns);
          processDataList();
          loading.value = false;
        } else if (response.data.status == 'Pending' || response.data.status == 'Executing') {
          setTimeout(() => {
            fetchSegmentPreviewData();
          }, 5000);
        }
      })
      .catch((error) => {
        openDefaultErrorModal(error, () => {
          if (error?.data?.error?.message == 'Invalid PreviewRef or Cache Expired') {
            configStore.reset();
            routerStore.update(true);
            router.replace({
              path: '/segmentv2',
              query: {
                ...router.currentRoute.value.query,
              },
            });
          }
        });

        loading.value = false;
      })
      .finally(() => {
        highlightTable();
      });
  }
  function highlightTable() {
    const highlightSegmentList = JSON.parse(sessionStorage.getItem('highlightSegmentList') || '[]');

    highlightSegmentList.forEach((hightlightSegment: Record<string, any>) => {
      const value: string = hightlightSegment[uniqueKey.value];
      const checkbox = document.querySelector(`.form-check-input[type=checkbox][value="${value}"]`);
      if (checkbox) {
        const tableRow = checkbox.closest('tr');
        setTimeout(() => {
          if (tableRow) tableRow.style.backgroundColor = '#C7E6FF';
        }, 1000);
      }
    });

    sessionStorage.removeItem('highlightSegmentList');
  }

  async function onRowClick(row: any) {
    const keyValue = row[uniqueKey.value];
    const foundIndex = includeOrExcludeList.value.findIndex((el) => el?.[uniqueKey.value] === keyValue);

    if (foundIndex != -1) {
      includeOrExcludeList.value.splice(foundIndex, 1);
    } else {
      includeOrExcludeList.value.push(row as Record<string, any>);
    }

    if (previewTable.value) {
      const checkbox = previewTable.value.$el.querySelector(`input[value="${keyValue as number}"]`);

      if (checkbox) {
        checkbox.checked = !checkbox.checked;
        const tableRow = checkbox.closest('tr');

        const selectAllCheckbox = previewTable.value.$el.querySelector('thead tr th input[type=checkbox]');
        if (isSelectedAll.value && includeOrExcludeList.value.length > 0) {
          (<HTMLInputElement>selectAllCheckbox).indeterminate = true;
        } else if (isSelectedAll.value) {
          (<HTMLInputElement>selectAllCheckbox).indeterminate = false;
        }

        await nextTick();

        if (tableRow) tableRow.style.backgroundColor = checkbox.checked ? '#EFF5FA' : '';
      }
    }
  }

  return {
    searchText,
    exportSegment,
    selectedRowDesc,
    selectedRowCount,
    clearSelectedRow,
    previewTable,
    columns,
    dataList,
    uniqueKey,
    includeOrExcludeList,
    isSelectedAll,
    onSelectedAll,
    onSelectedRow,
    onSort,
    actualTotalRecord,
    paginationOptions,
    pagination,
    paginationKey,
    fetchSegmentPreviewData,
    onRowClick,
  };
}
