<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_167_104826" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="19">
      <path d="M18.5 0.488281H0.5V18.4883H18.5V0.488281Z" fill="white" />
    </mask>
    <g mask="url(#mask0_167_104826)">
      <path
        d="M2 11.4883C2.29667 11.4883 2.5867 11.4003 2.83337 11.2355C3.08005 11.0707 3.27227 10.8364 3.3858 10.5623C3.49933 10.2882 3.52907 9.9866 3.47119 9.69562C3.41331 9.40465 3.27045 9.1374 3.06067 8.92762C2.85089 8.71784 2.58364 8.57497 2.29266 8.5171C2.00169 8.45922 1.70005 8.48895 1.42596 8.60248C1.15188 8.71602 0.91763 8.90824 0.752808 9.15491C0.587986 9.40159 0.5 9.69162 0.5 9.98829C0.5 10.3861 0.658026 10.7677 0.939331 11.049C1.22064 11.3303 1.60218 11.4883 2 11.4883Z"
        fill="currentColor"
      />
      <path
        d="M9.5 11.4883C9.79667 11.4883 10.0867 11.4003 10.3334 11.2355C10.58 11.0707 10.7723 10.8364 10.8858 10.5623C10.9993 10.2882 11.0291 9.9866 10.9712 9.69562C10.9133 9.40465 10.7704 9.1374 10.5607 8.92762C10.3509 8.71784 10.0836 8.57497 9.79266 8.5171C9.50169 8.45922 9.20005 8.48895 8.92596 8.60248C8.65188 8.71602 8.41763 8.90824 8.25281 9.15491C8.08799 9.40159 8 9.69162 8 9.98829C8 10.3861 8.15803 10.7677 8.43933 11.049C8.72064 11.3303 9.10218 11.4883 9.5 11.4883Z"
        fill="currentColor"
      />
      <path
        d="M17 11.4883C17.2967 11.4883 17.5867 11.4003 17.8334 11.2355C18.08 11.0707 18.2723 10.8364 18.3858 10.5623C18.4993 10.2882 18.5291 9.9866 18.4712 9.69562C18.4133 9.40465 18.2704 9.1374 18.0607 8.92762C17.8509 8.71784 17.5836 8.57497 17.2927 8.5171C17.0017 8.45922 16.7001 8.48895 16.426 8.60248C16.1519 8.71602 15.9176 8.90824 15.7528 9.15491C15.588 9.40159 15.5 9.69162 15.5 9.98829C15.5 10.3861 15.658 10.7677 15.9393 11.049C16.2206 11.3303 16.6022 11.4883 17 11.4883Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
