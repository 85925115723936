export default function () {
  const fetchChartSetListModel: API.RequestModel<Record<string, never>> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/SetList`,
    method: 'GET',
    payload: {},
  };

  const fetchChartAddModel: API.RequestModel<Bi.BiChartAdd.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/Add`,
    method: 'POST',
    payload: {
      PageId: null!,
      Charts: [],
    },
  };

  const fetchAnalyticsChartDataModel: API.RequestModel<Bi.BiChartData.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/Data`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      Filters: [],
    },
  };

  const fetchAnalyticsChartSettingInfoModel: API.RequestModel<Bi.BiChartSetting.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/SettingInfo`,
    method: 'GET',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
    },
  };

  const fetchAnalyticsChartSettingEditModel: API.RequestModel<Bi.BiChartSetting.Edit.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/SettingEdit`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartId: null!, // From chart_type_list => is_default = true
      Chart_Color_List: [],
    },
  };

  const fetchAnalyticsChartDataInfoModel: API.RequestModel<Bi.BiChartDataInfo.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/DataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
  };

  const fetchAnalyticsChartGlobalFilterListModel: API.RequestModel<Record<string, never>> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/GlobalFilterList`,
    method: 'GET',
    payload: {},
  };

  const fetchAnalyticsChartDynamicFilterListModel: API.RequestModel<Record<string, never>> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/DynamicFilterList`,
    method: 'GET',
    payload: {},
  };

  const fetchAnalyticsChartExportDataInfoModel: API.RequestModel<Bi.BiChartDataInfo.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/ExportDataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
    responseType: 'blob',
  };

  const fetchAnalyticsChartActivityCampaignModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/ActivityCampaign`,
    method: 'POST',
    payload: {},
  };

  const fetchAnalyticsChartCampaignSummaryModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/CampaignSummary`,
    method: 'POST',
    payload: {},
  };

  const fetchAnalyticsChartCampaignSummaryListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_ANALYTICS!}/Chart/CampaignSummaryList`,
    method: 'POST',
    payload: {},
  };

  return {
    fetchChartSetListModel,
    fetchChartAddModel,
    fetchAnalyticsChartDataModel,
    fetchAnalyticsChartSettingInfoModel,
    fetchAnalyticsChartSettingEditModel,
    fetchAnalyticsChartDataInfoModel,
    fetchAnalyticsChartGlobalFilterListModel,
    fetchAnalyticsChartDynamicFilterListModel,
    fetchAnalyticsChartExportDataInfoModel,
    fetchAnalyticsChartActivityCampaignModel,
    fetchAnalyticsChartCampaignSummaryModel,
    fetchAnalyticsChartCampaignSummaryListModel,
  };
}
