import api from '@/services/api';
import dataRelationModel from '@models/dataConnect/dataRelation';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useI18n } from 'vue-i18n';

export default function useRelationModal() {
  const { getRelationDetailModel, editRelationModel } = dataRelationModel();
  const { getSourceListModel, getTableListModel, getColumnListModel } = dataRelationModel();

  const { openErrorModal, openSuccessModal } = useValidationModal();
  const { t } = useI18n();

  const errorHandler = (err: any) => {
    if (err.data) {
      if (err.data.error.http_status == 404) {
        openErrorModal('404 Not Found', '');
      } else {
        const title = err.data.error.message.split(',');
        openErrorModal(title[0] as string, title.slice(1).join(',') as string);
      }
    } else {
      Promise.reject(err);
    }
  };

  const fetchSourceList = async (): Promise<DataRelation.ResSourceDropDown[] | null> => {
    try {
      const response = await api.apiRequest(getSourceListModel);
      const res = response.data.sources as DataRelation.ResSourceDropDown[];
      return res; // Return the resolved value directly
    } catch (err) {
      errorHandler(err);
      return null; // Return null in case of an error
    }
  };

  const fetchRelationDetail = async (relationId: number): Promise<DataRelation.ResRelationDetail | null> => {
    try {
      // Set the relationId in the payload
      getRelationDetailModel.payload.relationId = relationId;

      // Make the API request and wait for the response
      const response = await api.apiRequest(getRelationDetailModel);

      // Extract and return the result
      const res = response.data.result as DataRelation.ResRelationDetail;
      return res;
    } catch (err) {
      // Handle any errors
      errorHandler(err);
      return null; // Return null in case of an error
    }
  };

  const fetchColumnList = async (tableId: number): Promise<{ columns: DataRelation.ColumnTable[]; rows: Record<string, any>[] } | null> => {
    try {
      // Set the table_id in the payload
      getColumnListModel.payload.table_id = tableId;

      // Make the API request and wait for the response
      const response = await api.apiRequest(getColumnListModel);
      const res: DataRelation.ResTableMapping = response.data;

      // Map the columns from the response
      const columns: DataRelation.ColumnTable[] = res.columns.map((item: DataRelation.ResColumn) => ({
        key: item.column_name.toLowerCase(),
        label: item.column_name,
        column_id: item.column_id,
      }));

      // Return the columns and rows data
      return { columns, rows: res.sample };
    } catch (err) {
      // Handle any errors
      errorHandler(err);
      return null; // Return null in case of an error
    }
  };
  const saveEditRelation = async (relationId: number, columnRelations: DataRelation.ColumnRelations[]): Promise<void> => {
    editRelationModel.payload.Relation_Id = relationId;
    editRelationModel.payload.Column_Relations = columnRelations;
    await api
      .apiRequest(editRelationModel)
      .then(() => {
        openSuccessModal(t('dataRelation.relationship_pair_completed'));
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const fetchTableListDropdown = async (sourceId: number): Promise<DataRelation.DropDown[]> => {
    const relationDropdown: DataRelation.DropDown[] = [];
    getTableListModel.payload.SourceId = sourceId;
    await api
      .apiRequest(getTableListModel)
      .then((response) => {
        const res = response.data.tables as DataRelation.ResTableDropDown[];

        res.forEach((item: DataRelation.ResTableDropDown) => {
          relationDropdown.push({ id: item.table_id, key: item.table_name, label: item.table_name, Value: item.table_name });
        });
      })
      .catch((err) => {
        errorHandler(err);
      });

    return relationDropdown;
  };

  return { errorHandler, fetchSourceList, fetchRelationDetail, fetchColumnList, saveEditRelation, fetchTableListDropdown };
}
