import { ref, Ref, reactive, watch, markRaw, onMounted, onBeforeUnmount } from 'vue';
import api from '@services/api';
import router from '@/router';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';

import segmentResultModel from '@models/segment/result';
import segmentModel from '@/models/segment/segment';
import AllFolderModel from '@/models/segment/taballsegment';
import dynamicTableModel from '@/models/component/table/dynamicTable';

import useConvertDate from '@/utils/hooks/useConvertDate';
import useFilters from '@/utils/hooks/useFilters';
import BoxIcon from '@assets/icons/base/button/BoxIcon.vue';

import { useI18n } from 'vue-i18n';

interface Props {
  selectedSource: Segment.Datasource;
}

interface SegmentDetailOrdering {
  key: string;
  direction: string;
}

interface SegmentViewmode {
  selectedOption: {
    mode: number;
    title: string;
    type: number;
  };
}

enum ViewModeType {
  Generic = 0,
  Customer = 1,
  Product = 2,
  Transaction = 3,
  Group_by = 9,
}

export default function useDetailSegmentList(props: Props, filter: any, ordering: any, selectViewMode: SegmentViewmode) {
  const { isLoading } = useLoading();
  const { fetchSegmentResultModel, fetchSegmentFetchModel } = segmentResultModel();
  const { fetchDisplayColumnModel, fetchSegmentDetailModel, fetchSegmentTabForModal } = segmentModel();
  const { updateDisplayColumnModel } = dynamicTableModel();
  const { renameAllFolderListModel } = AllFolderModel();
  const { openDefaultErrorModal, openSuccessModal, openErrorModal } = useValidationModal();
  const { convertUTCToDeviceTimeZone } = useConvertDate();
  const { filterDisplayObj } = useFilters();
  const { t } = useI18n();

  const openAllFolder: Ref<boolean> = ref(false);
  const rowsSelectTotal = ref();
  const onBusy: Ref<boolean> = ref(false);
  const isModalEditName: Ref<boolean> = ref(false);
  const fields: Ref<DynamicTable.Column[]> = ref([]);
  const filtedFields: Ref<DynamicTable.Column[]> = ref([]);
  const dataSourceForModal: Ref<Record<string, any>[]> = ref([]);
  const dataList: Ref<Record<string, any>[]> = ref([]);
  const textSearch: Ref<string> = ref('');
  const filtersObj: Ref<Array<any>> = ref([]);
  const type: Ref<number> = ref(0);
  const lastUpdated: Ref<string> = ref('');
  const showLastUpdate: Ref<string> = ref('');
  const uniqueKey: Ref<string> = ref('');
  const isSelectedAllDetail: Ref<boolean> = ref(false);
  const segmentSegmentName: Ref<string> = ref(String(router.currentRoute.value.query.segmentName));
  const tableSelectAll: Ref<boolean> = ref(false);
  const totalRecord: Ref<number> = ref(0);
  const payloadForUpdate: Ref<any> = ref();
  const errorCount: Ref<number> = ref(0);
  const isDialogSegmentOpen: Ref<boolean> = ref(false);
  const tableIdForSegment: Ref<number> = ref(0);

  const sortModal: SegmentDetailOrdering = reactive({
    key: '',
    direction: '',
  });
  const optionSubmitItem = ref([{ title: 'บันทึกกลุ่มเป้าหมายที่เลือก (Selected)', icon: markRaw(BoxIcon) }]);
  const datasourceDisplay: Ref<Array<any>> = ref([]);
  const datasourceDisplayTableId: Ref<number> = ref(0);
  const root_tableid: Ref<number> = ref(null!);

  const refreshTableDisplay: Ref<boolean> = ref(false);

  const fetchRetrySelected: Ref<boolean> = ref(false);
  const isDialogOpen: Ref<boolean> = ref(false);

  let interval: ReturnType<typeof setInterval> = null!;
  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  function findUniqueKey(_columns: PreviewSegment.DatasourceDisplayColumns[]): void {
    const found = _columns.find((el: PreviewSegment.DatasourceDisplayColumns) => el.is_primary_key === true);
    if (found) {
      uniqueKey.value = found.key;
    } else {
      throw 'uniqueKey not found';
    }
  }

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 20,
    totalRows: 0,
  });

  const campaignActive: Ref<boolean> = ref(false);
  const sqlOnlyActive: Ref<boolean> = ref(false);

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    sortModal.key = sortedColumn.key;
    sortModal.direction = sortedColumn.direction;
    fetchDetailSegmentList();
  };

  const isViewStatus: Ref<boolean> = ref(false);
  const resolveTypeViewMove = (type: number) => {
    switch (type) {
      case ViewModeType.Customer:
        isViewStatus.value = false;
        return false;
      case ViewModeType.Product:
        isViewStatus.value = false;
        return false;
      case ViewModeType.Transaction:
        isViewStatus.value = false;
        return false;
      default:
        isViewStatus.value = true;
        return true;
    }
  };

  const fetchDetailSegmentList = () => {
    onBusy.value = true;

    fetchSegmentResultModel.payload.SegmentId = router.currentRoute.value.query.segmentId;
    fetchSegmentResultModel.payload.Limit = pagination.perPage;
    fetchSegmentResultModel.payload.Page = pagination.currentPage;
    fetchSegmentResultModel.payload.Filters = filter.value.length != 0 ? filter.value[0].filters : [];

    fetchSegmentResultModel.payload.Search_Value = textSearch.value;
    fetchSegmentResultModel.payload.Flag_Count = true;
    fetchSegmentResultModel.payload.ResultType = selectViewMode.selectedOption.type;
    fetchSegmentResultModel.payload.View_Select_Main = selectViewMode.selectedOption.mode;
    // fetchSegmentResultModel.payload.ResultType = 1;
    fetchSegmentResultModel.payload.Ordering = sortModal.key && sortModal.direction ? [sortModal] : [];

    api
      .apiRequest(fetchSegmentResultModel)
      .then((response) => {
        datasourceDisplay.value = response.data.datasourcedisplay;
        datasourceDisplayTableId.value = response.data.datasourcedisplay.tableid;
        tableIdForSegment.value = response.data.datasourcedisplay.tableid;
        fields.value = (response.data as PreviewSegment.SegmentResult).datasourcedisplay.columns as DynamicTable.Column[];
        filtedFields.value = (response.data as PreviewSegment.SegmentResult).datasourcedisplay.columns as DynamicTable.Column[];
        findUniqueKey((response.data as PreviewSegment.SegmentResult).datasourcedisplay.columns);
        dataList.value = response.data.segment_result;
        pagination.totalRows = response.data.filtered_record;
        totalRecord.value = response.data.segment_size;
        root_tableid.value = response.data.root_tableid ?? null!;

        datasourceDisplay.value = response.data.datasourcedisplay;
        lastUpdated.value = response.data.updated_dt;
        campaignActive.value = response.data.segment_is_lock;

        sqlOnlyActive.value = response.data.flag_raw_sql_only;
        if (response.status == 'success') {
          if (Number(router.currentRoute.value.query.type) == 2) {
            onFetchRealTime();
          } else {
            onFetchBatch();
          }
          fetchRetrySelected.value = false;
          filtersObj.value = [];
          onBusy.value = false;
        }
      })
      .catch((err) => {
        ++errorCount.value;
        if (err.status != 422 && errorCount.value < 5) {
          retryPayload();
        } else {
          openDefaultErrorModal(err);
        }
      })
      .finally(() => {
        filtersObj.value = [];
      });
  };

  const retryPayload = () => {
    fetchDetailSegmentList();
  };

  const onfetchSegmentTabForModal = () => {
    onBusy.value = true;
    api
      .apiRequest(fetchSegmentTabForModal)
      .then((response) => {
        dataSourceForModal.value = response.data.payload;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        onBusy.value = false;
      });
  };

  const fetchDetailSegment = () => {
    clearTimeout(interval);
    dataList.value = [];
    onBusy.value = true;
    fetchSegmentFetchModel.payload.SegmentId = router.currentRoute.value.query.segmentId;
    fetchSegmentFetchModel.payload.ResultType = selectViewMode.selectedOption.type;
    fetchSegmentFetchModel.payload.View_Select_Main = selectViewMode.selectedOption.mode;
    fetchSegmentFetchModel.payload.Limit = pagination.perPage;
    fetchSegmentFetchModel.payload.Page = pagination.currentPage;
    fetchSegmentFetchModel.payload.Filters = filter.value.length != 0 ? filter.value[0].filters : [];
    fetchSegmentFetchModel.payload.Ordering = sortModal.key && sortModal.direction ? [sortModal] : [];
    fetchSegmentFetchModel.payload.Search_Value = textSearch.value;

    api
      .apiRequest(fetchSegmentFetchModel)
      .then((response) => {
        findUniqueKey(response.data.datasourcedisplay.columns);
        fields.value = response.data.datasourcedisplay.columns;
        filtedFields.value = response.data.datasourcedisplay.columns;
        tableIdForSegment.value = response.data.datasourcedisplay.tableid;
        dataList.value = response.data.segment_result;
        pagination.totalRows = response.data.filtered_record;
        totalRecord.value = response.data.segment_size;
        lastUpdated.value = response.data.updated_dt;
        if (response.status == 'success') {
          fetchRetrySelected.value = false;
          onBusy.value = false;
          onFetchBatch();
        }
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        filtersObj.value = [];
      });
  };

  const handleColumnChange = (col: Array<any>) => {
    filtedFields.value = col;
    const displayList: DynamicTable.UpdateDisplay[] = [];
    col.map((column, index) => {
      displayList.push({
        ColumnId: column.column_id,
        Key_Column: column.key,
        Seq: index + 1,
      });
    });
    // updateDisplayColumnModel.payload.TableId = tableIdForSegment.value;
    // updateDisplayColumnModel.payload.displayTables = displayList;
    // if (selectViewMode.selectedOption.mode == 0 || selectViewMode.selectedOption.mode == 9) {
    //   api.apiRequest(updateDisplayColumnModel).catch((err) => {
    //     openDefaultErrorModal(err);
    //   });
    // } else {
    //   payloadForUpdate.value = updateDisplayColumnModel.payload.displayTables;
    // }
  };

  const onEditNameSegment = (val: string) => {
    isLoading(true);
    renameAllFolderListModel.payload.ref_type = 2;
    renameAllFolderListModel.payload.reference = Number(router.currentRoute.value.query.segmentId);
    renameAllFolderListModel.payload.name = val;

    api
      .apiRequest(renameAllFolderListModel)
      .then(() => {
        isLoading(false);
        openSuccessModal(
          'แก้ไข Segment สำเร็จ',
          '',
          '',
          () => redirectPath(val),
          () => redirectPath(val),
        );
      })
      .catch((err) => {
        isLoading(false);
        openErrorModal(err.data.error.locale['th-th'].title, err.data.error.locale['th-th'].message);
      });
  };

  const redirectPath = (val: string) => {
    router.push({
      path: '/segment/data-segment/segment-detail',
      query: {
        ...router.currentRoute.value.query,
        segmentId: Number(router.currentRoute.value.query.segmentId),
        segmentName: val,
        type: Number(router.currentRoute.value.query.type),
      },
    });
    segmentSegmentName.value = val;
    isModalEditName.value = false;
  };

  const onSelectAllRow = () => {};

  const onRowSelect = () => {};

  const onOpenModalEditName = () => {
    isModalEditName.value = true;
  };

  const onCloseModalEditName = () => {
    isModalEditName.value = false;
  };

  const onClickCopy = () => {
    isDialogSegmentOpen.value = true;
  };

  const onCloseDialogSegment = () => {
    isDialogSegmentOpen.value = false;
  };

  const getInside = () => {
    openAllFolder.value = true;
  };

  const clickRealtime = () => {
    clearTimeout(interval);
    selectViewMode.selectedOption.type = 1;
    selectViewMode.selectedOption.title = props.selectedSource.table_label;
    selectViewMode.selectedOption.mode = 0;
    fetchRetrySelected.value = true;
    fetchDetailSegmentList();
  };

  const clickBacth = () => {
    selectViewMode.selectedOption.type = 1;
    selectViewMode.selectedOption.title = props.selectedSource.table_label;
    selectViewMode.selectedOption.mode = 0;
    fetchRetrySelected.value = true;
    fetchDetailSegment();
  };

  const fetchDisplayColumn = (): Promise<DynamicTable.Column[]> => {
    return new Promise((resolve, reject) => {
      fetchDisplayColumnModel.payload.TableId = root_tableid.value ? root_tableid.value : props.selectedSource.tableid;

      api
        .apiRequest(fetchDisplayColumnModel)
        .then((response) => {
          const columnList = response.data.payload.columns as DynamicTable.Column[];
          resolve(columnList);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const fetchSegmentDetail = (): Promise<Segment.Segment> => {
    return new Promise((resolve, reject) => {
      fetchSegmentDetailModel.payload.segmentId =
        Number(router.currentRoute.value.query.applyDestSegmentId) || Number(router.currentRoute.value.query.segmentId);

      api
        .apiRequest(fetchSegmentDetailModel)
        .then((response) => {
          resolve(response.data as Segment.Segment);
        })
        .catch((err) => reject(err));
    });
  };

  const onOpenEditRuleModal = async () => {
    try {
      if (router.currentRoute.value.query.type == '3') {
        editSegmentModal.isOpen = true;
        editSegmentModal.isLoading = true;
        onfetchSegmentTabForModal();
      } else {
        editRuleModal.isOpen = true;
        editRuleModal.isLoading = true;
        editRuleModal.columnList = await fetchDisplayColumn();
        editRuleModal.segment = await fetchSegmentDetail();
      }
    } catch (err) {
      editRuleModal.isOpen = false;
      editSegmentModal.isOpen = false;
      openDefaultErrorModal(err);
    } finally {
      editRuleModal.isLoading = false;
      editSegmentModal.isLoading = false;
    }
  };

  const onCloseEditSegmentModal = () => {
    editSegmentModal.isOpen = false;
  };

  const onCloseEditRuleModal = () => {
    editRuleModal.isOpen = false;
  };

  const editSegmentModal: { isOpen: boolean; isLoading: boolean } = reactive({
    isOpen: false,
    isLoading: false,
  });

  const editRuleModal: { isOpen: boolean; isLoading: boolean; columnList: DynamicTable.Column[]; segment: Segment.Segment } = reactive({
    isOpen: false,
    isLoading: false,
    columnList: [],
    segment: null!,
  });

  const onDisableColumnChange = (value: { columnsFilter: DynamicTable.Column[] }) => {
    filtedFields.value = value.columnsFilter;
  };

  const onFetchRealTime = () => {
    const CurrentDate = new Date().getTime();
    const apiDate = lastUpdated.value;
    const resultDate = new Date(apiDate).getTime() + (CurrentDate - new Date(apiDate).getTime());

    if (interval) {
      clearTimeout(interval);
    }

    interval = setInterval(() => {
      showLastUpdate.value = convertUTCToDeviceTimeZone(new Date(resultDate).toISOString());
    }, 1000);
  };

  const onFetchBatch = () => {
    const apiDate = lastUpdated.value;
    const addHours = new Date(apiDate).getTime() + 7 * 60 * 60 * 1000;
    const resultDate = new Date(addHours).toISOString();

    if (interval) {
      clearTimeout(interval);
    }

    interval = setInterval(() => {
      showLastUpdate.value = convertUTCToDeviceTimeZone(resultDate);
    }, 1000);
  };

  const countTotal = (total: number, length: number, status: boolean) => {
    if (length > 0 && status == true)
      return (
        t('segment.detail_segment.table.statusbar_total_record_button_all') +
        (total - length) +
        t('segment.detail_segment.table.statusbar_total_record_button_inbox')
      );
    if (length == 0 && status == true) return t('segment.detail_segment.table.statusbar_total_record_button_clear');
    else
      return (
        t('segment.detail_segment.table.statusbar_total_record_button_all') +
        length +
        t('segment.detail_segment.table.statusbar_total_record_button_inbox')
      );
  };

  const clickStatusBar = (length: number, status: boolean) => {
    if (length == 0 && status == true) {
      tableSelectAll.value = true;
    }
  };

  const onOptionSubmit = () => {
    isDialogOpen.value = true;
  };

  const onCloseDialog = () => {
    isDialogOpen.value = false;
  };

  const onChangeSearch = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      if (textSearch.value != '') {
        filtersObj.value = filterDisplayObj(filtedFields.value, textSearch.value);
      }
      fetchDetailSegmentList();
    }, 500);
  };

  watch(
    () => pagination.currentPage,
    () => {
      if (textSearch.value.length == 0) return fetchDetailSegmentList();
      else return onChangeSearch();
    },
  );

  watch(
    () => editSegmentModal.isOpen,
    () => {
      if (editSegmentModal.isOpen == false) {
        pagination.currentPage = 1;
        fetchDetailSegmentList();
      }
    },
  );

  watch(
    () => router.currentRoute.value.query.segmentName,
    (newName) => {
      if (newName) {
        segmentSegmentName.value = String(router.currentRoute.value.query.segmentName);
        selectViewMode.selectedOption.type = 1;
        selectViewMode.selectedOption.mode = 0;
        pagination.currentPage = 1;
        fetchDetailSegmentList();
      }
    },
  );

  watch(
    () => refreshTableDisplay.value,
    () => {
      if (refreshTableDisplay.value == true) {
        refreshTableDisplay.value = false;
        pagination.currentPage = 1;
        fetchDetailSegmentList();
      }
    },
  );

  watch(
    () => selectViewMode,
    () => {
      pagination.currentPage = 1;
      sortModal.key = '';
      sortModal.direction = '';
      fetchDetailSegmentList();
    },
    { deep: true },
  );

  watch(
    () => textSearch.value,
    () => {
      pagination.currentPage = 1;
      onChangeSearch();
    },
  );

  watch(
    () => filter.value,
    () => {
      fetchDetailSegmentList();
    },
    { deep: true },
  );

  watch(
    () => ordering.value,
    (newOrdering, oldOrdering) => {
      if (newOrdering.Direction == undefined) {
        return;
      }
      if (newOrdering.Key == undefined) {
        return;
      }
      if (newOrdering.Direction != oldOrdering.Direction) {
        sortModal.key = newOrdering.Key;
        sortModal.direction = newOrdering.Direction;
        fetchDetailSegmentList();
      }

      if (newOrdering.Direction == oldOrdering.Direction && newOrdering.status == false) {
        sortModal.key = '';
        sortModal.direction = '';
        fetchDetailSegmentList();
      }
    },
    // { deep: true },
  );

  watch(
    () => router.currentRoute.value.query.type,
    () => {
      type.value = Number(router.currentRoute.value.query.type);
    },
  );

  onMounted(() => {
    type.value = Number(router.currentRoute.value.query.type);
    if (router.currentRoute.value.query.segmentId && router.currentRoute.value.name == 'data-segment-detail-list') {
      fetchDetailSegmentList();
    }
  });

  onBeforeUnmount(() => {
    clearInterval(interval);
  });

  return {
    openAllFolder,
    fields,
    filtedFields,
    pagination,
    dataList,
    onBusy,
    rowsSelectTotal,
    textSearch,
    type,
    lastUpdated,
    totalRecord,
    editRuleModal,
    editSegmentModal,
    uniqueKey,
    showLastUpdate,
    tableSelectAll,
    segmentSegmentName,
    campaignActive,
    sqlOnlyActive,
    isSelectedAllDetail,
    optionSubmitItem,
    isDialogOpen,
    datasourceDisplay,
    refreshTableDisplay,
    filtersObj,
    sortModal,
    getInside,
    onSort,
    onSelectAllRow,
    onRowSelect,
    onChangeSearch,
    fetchDetailSegment,
    clickRealtime,
    onOpenEditRuleModal,
    onCloseEditRuleModal,
    onEditNameSegment,
    clickStatusBar,
    countTotal,
    onOptionSubmit,
    onCloseDialog,
    onCloseEditSegmentModal,
    dataSourceForModal,
    isModalEditName,
    onOpenModalEditName,
    onCloseModalEditName,
    fetchRetrySelected,
    clickBacth,
    onClickCopy,
    isDialogSegmentOpen,
    onCloseDialogSegment,
    datasourceDisplayTableId,
    handleColumnChange,
    payloadForUpdate,
    onDisableColumnChange,
    resolveTypeViewMove,
    tableIdForSegment,
  };
}
