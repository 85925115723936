import { onMounted, ref, Ref } from 'vue';
import type { EChartsOption } from 'echarts';

export default function useTestEChartsView() {
  const option: Ref<EChartsOption> = ref({});
  const categories2 = (function () {
    const res = [];
    let len = 10;
    while (len--) {
      res.push(10 - len - 1);
    }
    return res;
  })();

  option.value = {
    title: {
      text: 'Dynamic Data',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#283b56',
        },
      },
    },
    legend: {},
    toolbox: {
      show: true,
      feature: {
        dataView: { readOnly: false },
        restore: {},
        saveAsImage: {},
      },
    },
    dataZoom: {
      show: false,
      start: 0,
      end: 100,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['Jan', 'Feb', 'Mar', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nav', 'Dec']
      },
      {
        type: 'category',
        boundaryGap: true,
        data: categories2,
      },
    ],
    yAxis: [
      {
        type: 'value',
        scale: true,
        name: 'Price',
        max: 100,
        min: 0,
        boundaryGap: [0.2, 0.2],
      },
      {
        type: 'value',
        scale: true,
        name: 'Order',
        max: 1000,
        min: 0,
        boundaryGap: [0.2, 0.2],
      },
    ],
    series: [
      {
        name: 'Customer',
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: [120, 200, 150, 400, 500, 450, 130, 50, 90, 130, 190, 250],
      },
      {
        name: 'Communication',
        type: 'line',
        data: [20, 25, 20, 45, 55, 45, 35, 30, 15, 35, 90, 100],
      },
      {
        name: 'MKA',
        type: 'line',
        data: [40, 40, 40, 20, 20, 20, 40, 50, 50, 50, 50, 50],
      },
    ],
  };

  return { option };
}
