import { onMounted, ref, Ref, watch, computed, toRaw } from 'vue';
import type { EChartsOption, SeriesOption } from 'echarts';
import * as echarts from 'echarts';
import { uid, calculateWidth, calculateFontSizeFromHeight, formatterLabel } from '../utils';

interface Props {
  rawData?: Charts.ChartsProps.RawData;
  colorList: Charts.ChartsProps.ColorItem[];
  height?: number | string;
  width?: number | string;
  responsiveWidth?: boolean;
  responsiveHeight?: boolean;
  eventType?: string;
  function?: Function;
}

interface DataItem {
  segmentname: string;
  stagename: string;
  value: number;
  percentage: number;
}

export default function (props: Props) {
  const chart = ref();
  const fontOffset = 10;
  const id = ref();
  id.value = uid();
  const option: Ref<EChartsOption> = ref({});

  const resolveHeight = computed(() => {
    return props.responsiveHeight ? '100%' : typeof props.height === 'number' ? `${props.height}px` : props.height;
  });

  const resolveWidth = computed(() => {
    return props.responsiveWidth ? '100%' : typeof props.width === 'number' ? `${props.width}px` : props.width;
  });

  // Map data
  const segmentName = props.rawData?.data?.map((item: any) => item.segmentname);
  const stageName = props.rawData?.data?.map((item: any) => item.stagename)[0];
  const mappingData = props.rawData?.data?.map((item: any) => [item.percentage, item.value]);
  const maxValue = Math.max(...(props.rawData?.data as DataItem[]).map((item) => item.value));
  const color = props.colorList.map((item: any) => item.hexColor);
  const schema = [{ name: 'Name', index: 1, text: stageName }];

  const itemStyle = {
    opacity: 0.8,
    borderColor: 'white', // Set the border color to white
    borderWidth: 1,
    shadowColor: 'rgba(255, 255, 255, 0.5)',
  };
  option.value = {
    color: [props.colorList.map((item: any) => item.hexColor)[5]],
    legend: {
      top: 10,
      data: [stageName],
      textStyle: {
        fontSize: 16,
      },
    },
    grid: {
      left: '10%',
      right: '10%',
      top: '18%',
      bottom: '10%',
    },
    tooltip: {
      backgroundColor: 'rgba(255,255,255,0.7)',
      formatter: function (param: any) {
        const value = param.value;
        const color = param.color;

        const index = param.dataIndex; // param.dataIndex gives you the index of the current point
        const currentSegmentName = segmentName ? segmentName[index] : '';
        return `<div style="display: flex; align-items: center;">
                <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background-color: ${color}; margin-right: 5px;"></span>
                <span>${currentSegmentName}</span>
              </div>
              <div style="margin-top: 5px;">
                <span style="font-size: 12px; color: #666;">${schema[0].text} : </span>
                <span style="float: right; font-weight: bold;">${value[0]}%</span>
              </div>`;
      },
    },
    xAxis: {
      type: 'value',
      name: 'Percent',
      nameGap: 16,
      nameTextStyle: {
        fontSize: 16,
      },
      max: 100,
      splitLine: {
        show: false,
      },
      axisLabel: {
        formatter: (value: any) => `${value}%`, // Add percentage sign
      },
    },
    yAxis: {
      type: 'value',
      name: 'Customer',
      nameLocation: 'end',
      nameGap: 20,
      nameTextStyle: {
        fontSize: 16,
      },
      splitLine: {
        show: false,
      },
    },
    visualMap: [
      {
        show: false,
        left: 'right',
        top: '10%',
        dimension: 1,
        min: 0,
        max: maxValue,
        itemWidth: 30,
        itemHeight: 120,
        calculable: false,
        precision: 0.1,
        text: ['Value'],
        textGap: 30,
        inRange: {
          symbolSize: [32, 80],
        },
        outOfRange: {
          symbolSize: [32, 80],
          color: ['#fff'],
        },
        controller: {
          inRange: {
            color: color,
          },
          outOfRange: {
            color: ['#999'],
          },
        },
      },
      {
        show: false,
        left: 'right',
        bottom: '5%',
        dimension: 0,
        min: 0,
        max: 100,
        itemHeight: 120,
        text: ['Color'],
        textGap: 30,
        splitNumber: 10, // Split into 10 sections
        inRange: {
          color: color,
        },
        calculable: true,
      },
    ],
    series: [
      {
        name: stageName,
        type: 'scatter',
        itemStyle: itemStyle,
        data: mappingData,
      } as SeriesOption,
    ],
  };

  const calculateSymbolSize = (chartWidth: number) => {
    // ปรับขนาดสัญลักษณ์ตามขนาดของ chart width
    const minSize = Math.max(chartWidth * 0.02, 16); // กำหนดขนาดขั้นต่ำ
    const maxSize = Math.min(chartWidth * 0.1, 80); // กำหนดขนาดสูงสุด
    return [minSize, maxSize];
  };

  const init = () => {
    const dom = document.getElementById(String(id.value)) as HTMLElement;
    try {
      const chartWidth = dom.clientWidth; // ขนาดความกว้างของ container ปัจจุบัน
      const newSymbolSize = calculateSymbolSize(chartWidth);

      // ตรวจสอบว่า option.value.visualMap มีอยู่หรือไม่ หากไม่มี ให้กำหนดค่าเริ่มต้น
      if (!option.value.visualMap) {
        option.value.visualMap = [];
      }

      if (Array.isArray(option.value.visualMap)) {
        // ตรวจสอบว่ามี visualMap[0] และ inRange อยู่หรือไม่
        if (option.value.visualMap[0] && option.value.visualMap[0].inRange) {
          option.value.visualMap[0].inRange.symbolSize = newSymbolSize;
        } else if (option.value.visualMap[0]) {
          // ถ้า visualMap[0] มีอยู่ แต่ inRange ไม่มี ให้กำหนด inRange ใหม่
          option.value.visualMap[0].inRange = {
            symbolSize: newSymbolSize,
          };
        } else {
          // ถ้า visualMap[0] ยังไม่ถูกกำหนด ให้สร้างมันขึ้นมา
          option.value.visualMap[0] = {
            inRange: {
              symbolSize: newSymbolSize,
            },
          };
        }
      } else {
        // กรณี visualMap ไม่ใช่อาร์เรย์ ตรวจสอบว่ามี inRange หรือไม่
        if (option.value.visualMap && option.value.visualMap.inRange) {
          option.value.visualMap.inRange.symbolSize = newSymbolSize;
        } else if (option.value.visualMap) {
          // ถ้า visualMap มีอยู่ แต่ inRange ไม่มี ให้กำหนด inRange ใหม่
          option.value.visualMap.inRange = {
            symbolSize: newSymbolSize,
          };
        } else {
          // ถ้า visualMap ยังไม่ถูกกำหนด ให้สร้างมันขึ้นมา
          option.value.visualMap = {
            inRange: {
              symbolSize: newSymbolSize,
            },
          };
        }
      }

      chart.value = echarts.init(dom);
      chart.value.setOption(toRaw(option.value));

      let initResize = false;

      setTimeout(() => {
        initResize = true;
        chart.value.resize();
      }, 1000);

      new ResizeObserver(() => {
        const chartWidth = dom.clientWidth;
        const newSymbolSize = calculateSymbolSize(chartWidth);

        // ปรับขนาด symbolSize เมื่อ resize เกิดขึ้น
        if (Array.isArray(option.value.visualMap)) {
          if (option.value.visualMap[0] && option.value.visualMap[0].inRange) {
            option.value.visualMap[0].inRange.symbolSize = newSymbolSize;
          }
        } else if (option.value.visualMap && option.value.visualMap.inRange) {
          option.value.visualMap.inRange.symbolSize = newSymbolSize;
        }

        if (initResize) chart.value.resize();
      }).observe(dom);

      if (props.eventType) {
        chart.value.on(props.eventType, props.function);
      }
    } catch (e) {
      // จัดการข้อผิดพลาด
    }
  };

  onMounted(() => {
    init();
  });

  watch(
    () => option.value,
    () => {
      if (chart.value) chart.value.setOption(toRaw(option.value));
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch([() => props.height, () => props.width], () => {
    setTimeout(() => {
      chart.value.resize();
    }, 100);
  });

  return { id, uid, calculateFontSizeFromHeight, formatterLabel, calculateWidth, option, resolveHeight, resolveWidth };
}
